import React from "react";
import clsx from "clsx";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { makeStyles } from "@material-ui/core/styles";

// MCQ cell in exercises
function McqCell({
  index,
  didSubmit,
  suggestion,
  isMultiple,
  correctIndex,
  isReviewMode,
  selectedAnswer,
  forceBlackColor,
  ...props
}) {
  const classes = useStyles();
  const isSelected = isMultiple
    ? selectedAnswer?.includes(index) // Multiple (Array)
    : selectedAnswer == index; // Single (Number)

  const isCorrect = isMultiple
    ? correctIndex.includes(index) // Multiple (Array)
    : correctIndex == index; // Single (Number)

  return (
    <div
      className={clsx(classes.cell, {
        [classes.cellBlackText]: forceBlackColor,
        [classes.cellSelected]: !didSubmit && isSelected,
        [classes.cellSubmitSelected]: didSubmit && isSelected,
        [classes.cellCorrect]: didSubmit && isCorrect,
      })}
    >
      <FormControlLabel
        control={
          <Checkbox
            value={index}
            color="primary"
            checked={isSelected}
            disabled={isReviewMode || didSubmit}
            classes={{ root: classes.inputRoot }}
            onChange={(e) =>
              didSubmit ? () => null : props.onSelectCell(e.target.value)
            }
          />
        }
        label={suggestion}
        classes={{ label: didSubmit && classes.cellDisabled }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  cell: {
    margin: "0.25rem 0",
    padding: "0.1rem 0.75rem",
    width: "calc(100% - 1.5rem)",
    color: theme.customColors.blackWhite,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0.1rem 0.5rem",
    },
  },
  cellBlackText: {
    color: "black",
  },
  cellSelected: {
    color: "black",
    background: "#fffdaf",
  },
  cellSubmitSelected: {
    background: theme.customColors.answerWrong,
  },
  cellCorrect: {
    background: theme.customColors.answerRight,
  },
  cellDisabled: {
    color: `${theme.customColors.blackWhite}!important`,
  },
  inputRoot: {
    color: "#223A4C !important",
  },
}));

export default McqCell;
