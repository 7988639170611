// Return title and logo of the platform
const getPlatformLayout = (type) => {
  // Default
  var title = "PTE Success";
  var logo =
    "https://pineapplestudio.com.au/pte_success_v2/images/layout/pte_success_logo_v2.svg";

  return type === "title" ? title : logo;
};

export default getPlatformLayout;
