import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import CheckIcon from "@material-ui/icons/Check";
import FireIcon from "@material-ui/icons/Whatshot";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  ROUTE_SIGNUP,
  HAS_CLOSED_BANNER,
  HAS_SHOWN_OVERVIEW,
} from "../../helpers/types";
import { useInterval } from "../../helpers/customHook";
import { saveToSessionStorage } from "../../helpers/saveToLocalStorage";
import { isPaidStudent, isAuthenticated } from "../../helpers/userCheck";

import { navigateTo } from "../../actions/navigationActions";
import { getOverviewStudentData } from "../../actions/userActions";

// Snackbar with overview stats
function OverviewStudentsSnackbar(props) {
  const classes = useStyles();
  const { user, notification } = props;

  const largeDevice = useMediaQuery("(min-width:900px)");

  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState(0);
  const [timeLeft, setTimeLeft] = useState(6);
  const [onHover, setOnHover] = useState(false);
  const [timeLeftOpen, setTimeLeftOpen] = useState(5);
  const [showFreeTrial, setShowFreeTrial] = useState(true);
  const [mobilebannerIsUp, setMobilebannerIsUp] = useState(false);

  useEffect(() => {
    setMobilebannerIsUp(
      !largeDevice && !isAuthenticated(user) && !localStorage[HAS_CLOSED_BANNER]
    );
  }, [largeDevice, localStorage[HAS_CLOSED_BANNER]]);

  // Trigger async getOverviewStudentData
  useEffect(() => {
    if (
      !mobilebannerIsUp &&
      timeLeftOpen === 0 &&
      !sessionStorage[HAS_SHOWN_OVERVIEW] &&
      !isPaidStudent({ user, settings: props.settings })
    ) {
      getOverviewStudentData({ trial: !isAuthenticated(user) });
    }
  }, [timeLeftOpen, mobilebannerIsUp]);

  // Hide card on counter finish
  useEffect(() => {
    if (timeLeft <= 0) {
      setOpen(false);
    }
  }, [timeLeft]);

  // 6 seconds countdown timer (show card)
  useInterval(
    () => {
      setTimeLeftOpen(timeLeftOpen - 1);
    },
    !mobilebannerIsUp && timeLeftOpen > 0 ? 1000 : null
  );

  // 6 seconds countdown timer (show card)
  useInterval(
    () => {
      setTimeLeft(timeLeft - 1);
    },
    open && !onHover && timeLeftOpen === 0 ? 1000 : null
  );

  // Get data
  async function getOverviewStudentData({ trial }) {
    const res = await props.getOverviewStudentData({ trial });
    setShowFreeTrial(trial);
    setNumber(res);
    setTimeLeft(6);
    setOpen(true);
    saveToSessionStorage(HAS_SHOWN_OVERVIEW, "true");
  }

  // Minimum 2 students for showing
  // Close if alert snackbar or is paid in
  if (
    number <= 2 ||
    notification ||
    isPaidStudent({ user, settings: props.settings })
  ) {
    return null;
  }

  return (
    <Slide
      in={open}
      mountOnEnter
      unmountOnExit
      timeout={1000}
      direction="right"
    >
      <Grid
        className={classes.wrapper}
        onMouseOut={() => setOnHover(false)}
        onMouseOver={() => setOnHover(true)}
        onClick={() => props.navigateTo(ROUTE_SIGNUP)}
      >
        {showFreeTrial ? (
          <FireIcon className={classes.icon} />
        ) : (
          <CheckIcon className={classes.checkIcon} />
        )}
        <div>
          <Typography
            className={classes.title}
          >{`${number} students`}</Typography>
          <Typography className={classes.subTitle}>
            {showFreeTrial
              ? "started a free trial"
              : "joined PTE Success Premium"}
          </Typography>
          <Typography className={classes.time}>
            {showFreeTrial ? "in the last 24 hours" : "in the last week"}
          </Typography>
        </div>
      </Grid>
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    bottom: 0,
    zIndex: 2000,
    margin: "1em",
    display: "flex",
    minWidth: "14em",
    cursor: "pointer",
    position: "fixed",
    borderRadius: "5em",
    alignItems: "center",
    padding: "0.7em 1.4em",
    boxShadow: theme.shadow.boxLight,
    background: theme.palette.background.paper,
    border: `solid 1px ${theme.palette.grey[200]}`,
    [theme.breakpoints.down("sm")]: {
      margin: "0.5em",
    },
  },
  icon: {
    display: "flex",
    fontSize: "2em",
    padding: "0.3em",
    borderRadius: "5em",
    marginRight: "0.5em",
    color: theme.customColors.red,
    background: theme.customColors.redLight,
    [theme.breakpoints.down("sm")]: {
      marginRight: "0.5em",
    },
  },
  checkIcon: {
    color: "white",
    display: "flex",
    fontSize: "2em",
    padding: "0.3em",
    borderRadius: "5em",
    marginRight: "0.5em",
    background: theme.customColors.blue,
    [theme.breakpoints.down("sm")]: {
      marginRight: "0.5em",
    },
  },
  title: {
    fontSize: "1.1em",
    fontWeight: "bold",
    color: theme.palette.grey[800],
  },
  subTitle: {
    fontSize: "0.8em",
    color: theme.customColors.blackWhite,
  },
  time: {
    fontSize: "0.7em",
    color: theme.palette.grey[700],
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  settings: state.settings,
  notification: state.notification,
});

export default connect(mapStateToProps, { navigateTo, getOverviewStudentData })(
  OverviewStudentsSnackbar
);
