import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import { connect } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import LinkIcon from "@material-ui/icons/Link";
import UnlinkIcon from "@material-ui/icons/LinkOff";
import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";

import { isAuthenticated } from "../../helpers/userCheck";
import { setPracticeScore } from "../../actions/questionActions";

// Reading single answer exercise
function ExerciseReadingReorder({ question, didSubmit, ...props }) {
  const classes = useStyles();
  const { showAnswer } = props.practice;
  const { uid, paragraphs, correctIndexes } = question;

  const correctParagraphs = correctIndexes.map((val, i) => paragraphs[val - 1]);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [items, setItems] = useState(paragraphs);

  // Trigger save score
  useEffect(() => {
    if (didSubmit) {
      saveScore();
    }
  }, [didSubmit]);

  // Save score on submit
  function saveScore() {
    const correct = [];
    // Get correct scores
    for (var i = 0; i < items.length - 1; i++) {
      const indexOfItemInParagraph = paragraphs.findIndex(
        (paragraph) => paragraph == items[i]
      );
      const indexOfParagraphInIndexes = correctIndexes.findIndex(
        (correctIndex) => correctIndex == indexOfItemInParagraph + 1
      );
      const indexOfParagraphToCompare =
        correctIndexes[indexOfParagraphInIndexes + 1];
      const paragraphToCompare = paragraphs[indexOfParagraphToCompare - 1];
      correct.push(paragraphToCompare == items[i + 1]);
    }

    // Get number of correct pairs
    const actualScore = correct.filter((correct) => correct);

    // Final score
    const score = `${actualScore.length}/${correctIndexes.length - 1}`;

    props.setPracticeScore({
      uid,
      score,
      successful: actualScore.length > (correctIndexes.length - 1) / 2,
      isAuthenticated: isAuthenticated(props.user),
    });

    setSelectedAnswer(correct);
  }

  // Select state or real answers
  const paragraphsToDisplay = showAnswer ? correctParagraphs : items;

  return (
    <Grid container direction="row" className={classes.screen}>
      <TaskBox uid={uid} />
      <ReactSortable
        list={items}
        setList={(order) => setItems(order)}
        disabled={didSubmit}
        key={"sortable-" + (didSubmit ? "off" : "on")} // Trick to disable
      >
        {paragraphsToDisplay?.map((val, i) => {
          const isCorrect = selectedAnswer[i];
          return (
            <>
              {/* Paragraph box */}
              <div
                key={i}
                className={clsx(classes.paragraph, {
                  [classes.noMargin]: didSubmit,
                  [classes.paragraphAnswer]: showAnswer,
                  [classes.paragraphDisabled]: didSubmit,
                })}
                data-id={val}
              >
                {val}
              </div>
              {/* Link icon between paragraphs */}
              {didSubmit && (
                <div className={classes.link}>
                  {isCorrect !== undefined ? (
                    <Tooltip
                      title={`These 2 paragraphs are ${
                        isCorrect || showAnswer ? "" : "not"
                      } linked.`}
                      placement={"left"}
                    >
                      {isCorrect || showAnswer ? (
                        <LinkIcon className={classes.linkIcon} />
                      ) : (
                        <UnlinkIcon className={classes.unlinkIcon} />
                      )}
                    </Tooltip>
                  ) : null}
                </div>
              )}
            </>
          );
        })}
      </ReactSortable>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  paragraph: {
    cursor: "grab",
    display: "flex",
    padding: "0.5rem",
    minHeight: "3rem",
    margin: "0.5rem 0",
    borderRadius: "5px",
    alignItems: "center",
    lineHeight: "1.4rem",
    cursor: "-webkit-grab",
    background: theme.customColors.background,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  paragraphAnswer: {
    border: `1px solid ${theme.customColors.greenDarker}`,
  },
  paragraphDisabled: {
    cursor: "default",
  },
  noMargin: {
    margin: "0",
  },
  link: {
    margin: "0.25rem 0",
    textAlign: "center",
  },
  linkIcon: {
    padding: "0.25rem",
    borderRadius: "50%",
    color: theme.customColors.greenDarker,
    background: theme.customColors.greenLight,
  },
  unlinkIcon: {
    padding: "0.25rem",
    borderRadius: "50%",
    color: theme.customColors.red,
    background: theme.customColors.redLight,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  practice: state.practice,
});

export default connect(mapStateToProps, { setPracticeScore })(
  ExerciseReadingReorder
);
