import axios from "axios";

import { updateUser } from "./userActions";
import { handleErrors } from "./errorActions";
import { navigateTo } from "./navigationActions";
import { displaySnackbar } from "./notificationActions";
import { stopLoadingSpinner, startLoadingSpinner } from "./loadingActions";

import {
  SET_USER,
  GET_PACKAGES,
  ROUTE_PRICING,
  GET_PROMOTIONS,
  SNACK_PROMO_EXPIRED,
  SNACK_PROMO_ALREADY,
} from "../helpers/types";

// @desc  Fetch all packages
// @next  Load packages to redux
export const fetchAllPackages = () => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .get("/api/pricing/fetchAllPackages")
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch({ type: GET_PACKAGES, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Get pricing details for 1 package
// @next  Return pricing object
export const fetchPackage = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/pricing/fetchPackage", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};

// @desc  Fetch all promotions
// @next  Load promotions to redux
export const fetchAllPromotions = () => (dispatch) => {
  axios
    .get("/api/pricing/fetchAllPromotions")
    .then((res) => {
      dispatch({ type: GET_PROMOTIONS, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Add upsell item to cart
// @next  Update user
export const addUpsellToCart = (data) => (dispatch) => {
  axios
    .post("/api/pricing/addUpsellToCart", data)
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Clear upsell item from cart
// @next  Update user
export const clearUpsellToCart = (data) => (dispatch) => {
  axios
    .post("/api/pricing/clearUpsellToCart", data)
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Activate promotion (external from email)
// @next  Update user
export const activatePromotion = (data) => (dispatch) => {
  axios
    .post("/api/pricing/activatePromotion", data)
    .then((res) => {
      if (res.status === 200) {
        // Redirect to pricing (force reload of user)
        // DO NOT PASS USER OBJECT FROM BACKEND
        window.location.href = "/pricing";
      } else if (res.status === 201) {
        dispatch(navigateTo(ROUTE_PRICING));
        dispatch(displaySnackbar(SNACK_PROMO_EXPIRED));
      }
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Activate promotion from UID (external from email)
export const activatePromotionUid = (data) => (dispatch) => {
  axios
    .post("/api/pricing/activatePromotionUid", data)
    .then((res) => {
      if (res.status === 200) {
        // Reload to force fetch promotions
        dispatch(updateUser({}));
      } else if (res.status === 201) {
        dispatch(displaySnackbar(SNACK_PROMO_ALREADY));
      }
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Create Stripe payment intent
// @next  Return payment intent
export const createPaymentIntent = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(startLoadingSpinner());
    axios
      .post("/api/pricing/createPaymentIntentt", data)
      .then((res) => {
        resolve(res.data);
      })
      .finally(dispatch(stopLoadingSpinner()))
      .catch((err) => {
        reject(err);
      });
  });
};

// @desc  Payment completed + update/create user
// @next  Return response and go to Thank You apage
export const savePurchaseToUser = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/pricing/savePurchaseToUser", data)
      .then((res) => {
        resolve(res.data);
      })
      .finally(dispatch(stopLoadingSpinner()))
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// @desc  Check if payment is completed
// @next  Go to Thank you page
export const checkIfPaymentIsDone = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/pricing/checkIfPaymentIsDone", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
