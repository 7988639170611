import React from "react";
import clsx from "clsx";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";

import { QUICK_MODE } from "../../helpers/types";

// Texfield input
function TextFieldGroup({
  id,
  name,
  placeholder,
  label,
  value,
  error,
  info,
  type,
  onChange,
  disabled,
  customRoot,
  customInput,
  customClass,
  customOutline,
  customContainer,
  multiline,
  rows,
  margin,
  shrinkLabel,
  onKeyPress,
  autoComplete,
  isMockTest = false,
}) {
  const classes = useStyles();

  return (
    <FormControl
      className={clsx(classes.container, {
        [customContainer]: customContainer,
      })}
      noValidate
    >
      <TextField
        onCut={(e) =>
          isMockTest && !localStorage[QUICK_MODE] ? e.preventDefault() : null
        }
        onCopy={(e) =>
          isMockTest && !localStorage[QUICK_MODE] ? e.preventDefault() : null
        }
        onPaste={(e) =>
          isMockTest && !localStorage[QUICK_MODE] ? e.preventDefault() : null
        }
        id={id || label}
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        value={value}
        helperText={error || info}
        autoComplete={autoComplete}
        error={Boolean(error)}
        className={customClass}
        disabled={disabled}
        multiline={multiline}
        autoCorrect="false"
        spellCheck={false}
        inputProps={{ "data-gramm": false }} // Block Grammarly
        minRows={rows}
        onChange={onChange}
        fullWidth
        margin={margin || "normal"}
        variant="outlined"
        InputLabelProps={{ shrink: shrinkLabel, htmlFor: label }}
        InputProps={{
          classes: {
            root:
              (!isMockTest && customRoot) || (isMockTest && classes.inputRoot),
            notchedOutline: customOutline || classes.notchedOutline,
            input: customInput,
          },
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onKeyPress ? onKeyPress() : console.log("");
          }
        }}
      />
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  inputRoot: {
    color: "black !important",
  },
  notchedOutline: {
    border: "1px solid gray !important",
  },
}));

export default TextFieldGroup;
