import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";

import { cleanString } from "../../helpers/stringHelper";
import { isAuthenticated } from "../../helpers/userCheck";
import { setPracticeScore } from "../../actions/questionActions";

// Read/write fill in blanks exercise
function ExerciseReadingWritingFillBlanks({ question, didSubmit, ...props }) {
  const classes = useStyles();

  const { showAnswer } = props.practice;
  const { topic, uid, wordsGiven, correctAnswers } = question;

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // Save number of targets to selectedAnswer
  useEffect(() => {
    setSelectedAnswer(Array(wordsGiven?.length).fill(""));
  }, []);

  // Trigger save score
  useEffect(() => {
    if (didSubmit) {
      saveScore();
    }
  }, [didSubmit]);

  // Save score on submit
  function saveScore() {
    const correct = [];
    // Get correct scores
    for (var i in selectedAnswer) {
      correct.push(selectedAnswer[i] == correctAnswers[i]);
    }

    const actualScore = correct.filter((correct) => correct);

    // Final score
    const score = `${actualScore.length}/${correctAnswers.length}`;

    props.setPracticeScore({
      uid,
      score,
      successful: actualScore.length > correctAnswers.length / 2,
      isAuthenticated: isAuthenticated(props.user),
    });
  }

  // Render topic
  function renderTopic() {
    // Loop words in topic
    return topic.split(" ").map((word, i) => {
      if (word.includes("__")) {
        // Create drop targets
        const id = parseInt(cleanString(word)) - 1;
        return <Target id={id} />;
      } else {
        // Create normal words
        return (
          <Typography
            key={i}
            variant="body1"
            color="textPrimary"
            style={{ padding: "2px" }}
          >
            {word}
          </Typography>
        );
      }
    });
  }

  if (!selectedAnswer) return null;

  return (
    <Grid container direction="row" className={classes.screen}>
      <TaskBox uid={uid} />
      <div className={classes.column}>{renderTopic()}</div>
    </Grid>
  );

  // Target component
  function Target({ id }) {
    const isCorrect = selectedAnswer[id] == correctAnswers[id];

    return (
      <Select
        native
        disabled={didSubmit}
        variant="outlined"
        key={`target_${id}`}
        value={showAnswer ? correctAnswers[id] : selectedAnswer[id]}
        onChange={(e) => {
          const newArr = [...selectedAnswer];
          newArr[id] = e.currentTarget.value;
          setSelectedAnswer(newArr);
        }}
        className={clsx(classes.target, {
          [classes.targetCorrect]: (didSubmit && isCorrect) || showAnswer,
          [classes.targetWrong]: didSubmit && !isCorrect && !showAnswer,
        })}
        classes={{
          icon: classes.iconRoot,
          disabled: didSubmit && classes.disabled,
        }}
      >
        {[""].concat(wordsGiven[id]).map((suggestion, i) => {
          // Add blank option on top
          return (
            <option key={`option_${i}`} value={suggestion}>
              {suggestion}
            </option>
          );
        })}
      </Select>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  column: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    padding: "1rem 0",
    maxHeight: "70vh",
    overflowY: "auto",
    alignContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem 0",
    },
  },
  target: {
    color: "black",
    display: "flex",
    height: "1.4em",
    fontWeight: 500,
    minWidth: "7rem",
    margin: "0 0.5rem",
    marginTop: "0.3rem",
    background: "white",
    borderRadius: "0px",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #C2D0DF",
  },
  targetCorrect: {
    background: theme.customColors.answerRight,
    border: `solid 1px ${theme.customColors.greenDarker}`,
  },
  targetWrong: {
    background: theme.customColors.answerWrong,
    border: `solid 1px ${theme.customColors.red}`,
  },
  iconRoot: {
    top: 0,
    right: 0,
    height: "1.25rem",
    background: "#D8E5F9",
    color: "#223A4C !important",
  },
  disabled: {
    color: `${theme.customColors.blackWhite}!important`,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  practice: state.practice,
});

export default connect(mapStateToProps, { setPracticeScore })(
  ExerciseReadingWritingFillBlanks
);
