import React, { useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

import UserIconMenu from "./UserIconMenu";

// Avatar at top right screen
function UserIcon(props) {
  const { profileImage } = props.user;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  // Open/close drop down menu
  function openMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <div className={classes.avatarWrapper}>
      <Avatar
        className={classes.avatar}
        onClick={openMenu}
        alt="pte login"
        src={
          profileImage ||
          "https://pineapplestudio.com.au/pte_success_v2/images/avatars/avatar_default.svg"
        }
      />
      {/* Drop down menu */}
      <UserIconMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  avatarWrapper: {
    justifyContent: "flex-end",
    display: "flex",
  },
  avatar: {
    width: "2rem",
    height: "2rem",
    cursor: "pointer",
    marginRight: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
    },
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UserIcon);
