import axios from "axios";

import {
  SNACK_ERROR_VERIFY,
  SNACK_EMAIL_VERIFY_SENT,
  STEPS_DID_SEND_VERIFY_EMAIL,
} from "../helpers/types";
import { handleErrors } from "./errorActions";
import { updateUserArray } from "./userActions";
import { displaySnackbar } from "./notificationActions";

// @desc  Save user to SendInBlue
export const saveToMailList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/maillist/saveToMailList")
      .then((res) => {
        if (res.status === 200) {
          // Reload to force fetch promotions
          dispatch(displaySnackbar(SNACK_EMAIL_VERIFY_SENT));
          dispatch(updateUserArray({ stepsDone: STEPS_DID_SEND_VERIFY_EMAIL }));
          resolve(res.data);
        } else if (res.status === 201) {
          dispatch(displaySnackbar(SNACK_ERROR_VERIFY));
        }
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};

// @desc  Trigger abandonned cart in SendInBlue
export const didAddToCart = (userData) => (dispatch) => {
  axios
    .post("/api/maillist/didAddToCart", userData)
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Blacklist or not user from receiving emails
export const updateEmailNotification = (userData) => (dispatch) => {
  axios
    .post("/api/maillist/updateEmailNotification", userData)
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Update firstname in maillist
export const updateContactName = (firstName) => (dispatch) => {
  axios
    .post("/api/maillist/updateContactName", firstName)
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Remove verify token in SendInBlue
export const saveToVerifiedMailList = (userData) => (dispatch) => {
  axios
    .post("/api/maillist/saveToVerifiedMailList", userData)
    .catch((error) => dispatch(handleErrors(error)));
};
