import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Link from "@material-ui/core/Link";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import NightIcon from "@material-ui/icons/Brightness2";
import LightIcon from "@material-ui/icons/Brightness5";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import { makeStyles } from "@material-ui/core/styles";

import LogoIcon from "./LogoIcon";
import UserIcon from "./UserIcon";
import PremiumIcon from "./PremiumIcon";
import NotificationIcon from "./NotificationIcon";
import NavbarCollapsedMenu from "./NavbarCollapsedMenu";
import TooltipCustom from "../screenExercise/components/TooltipCustom";

import {
  isAdmin,
  doesUserHave,
  isPaidStudent,
  isReferredUser,
  isAuthenticated,
  isInPracticeMode,
} from "../../helpers/userCheck";
import {
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_ADMIN,
  ROUTE_SIGNUP,
  ROUTE_SURVEY,
  ROUTE_CHECKOUT,
  STEPS_AUTO_NIGHT,
  HAS_REGISTERED_ONCE,
  STEPS_SHOULD_SET_PASSWORD,
  ROUTE_EXERCISE_MOCK_DESKTOP,
} from "../../helpers/types";
import isEmpty from "../../helpers/isEmpty";
import getCpcPromo from "../../helpers/getCpcPromo";
import { getNavbar } from "../../helpers/getNavbar";
import { getActivePromo } from "../../helpers/pricingHelper";
import getPlatformLayout from "../../helpers/getPlatformLayout";

import { toggleNightMode } from "../../actions/userActions";
import { navigateTo } from "../../actions/navigationActions";

// HOC to hide on scroll (practice only)
function HideOnScroll({ practice, largeDevice, children }) {
  const trigger = useScrollTrigger({ threshold: 0 });

  if (!isInPracticeMode(practice) || !largeDevice) {
    return children;
  }

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

// Top navbar
function Navbar(props) {
  const classes = useStyles();
  const { user, promotions, practice } = props;
  const { isMockTest, isReviewMode } = props.practice;

  const largeDevice = useMediaQuery("(min-width:900px)");

  var currentPromo = getActivePromo(promotions, user);

  const { startPromo, target, discount } = getCpcPromo(null, user);
  const isSurvey = window.location.pathname === ROUTE_SURVEY;
  const isCheckout = window.location.pathname === ROUTE_CHECKOUT;
  const mockDeskOnly = window.location.pathname === ROUTE_EXERCISE_MOCK_DESKTOP;

  // Activate promo if came from Google Ad offer and not authenticated
  if (discount > 0) {
    const endPromo = startPromo + 86400000; // 1 day timer

    // Display only in first 24 hours
    if (endPromo > Date.now()) {
      currentPromo = {
        promoId: isReferredUser(user) ? "referral_sale" : "cpc_sale",
        startTime: startPromo,
        endTime: endPromo,
        discount: discount,
        target: target,
      };
    }
  }

  // Light/dark modex
  function toggleNightMode() {
    props.toggleNightMode({ isNightMode: !user?.isNightMode });
  }

  // Hide if needed
  if (
    isSurvey ||
    isCheckout ||
    isMockTest ||
    isReviewMode ||
    mockDeskOnly ||
    user?.stepsDone?.includes(STEPS_SHOULD_SET_PASSWORD)
  )
    return null;

  return (
    <HideOnScroll practice={practice} largeDevice={largeDevice} {...props}>
      <AppBar className={classes.appBar} elevation={largeDevice ? 4 : 0}>
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <LogoIcon />
          {(largeDevice ||
            (!isAuthenticated(user) && isEmpty(currentPromo))) && (
            <Typography
              noWrap
              variant="h6"
              className={classes.title}
              onClick={() => props.navigateTo(ROUTE_HOME)}
            >
              {getPlatformLayout("title")}
            </Typography>
          )}
          {/* Premium/promo icon */}
          {((isAuthenticated(user) &&
            !isPaidStudent({ user, settings: props.settings })) ||
            (!isAuthenticated(user) && !isEmpty(currentPromo))) && (
            <PremiumIcon
              largeDevice={largeDevice}
              currentPromo={currentPromo}
            />
          )}
          {/* Authenticated navbar */}
          {isAuthenticated(user) && (
            <div className={classes.iconWrapper}>
              {/* Admin section */}
              {isAdmin(user) && (
                <Tooltip title="Admin panel">
                  <IconButton
                    className={classes.iconAdmin}
                    onClick={() => props.navigateTo(ROUTE_ADMIN)}
                    aria-label="pte success admin panel"
                  >
                    <VerifiedUserIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* Night mode */}
              {!doesUserHave(user, STEPS_AUTO_NIGHT) && (
                <TooltipCustom
                  title={
                    user?.isNightMode
                      ? "Activate light mode"
                      : "Activate dark mode"
                  }
                  subTitle="You can setup the Automatic dark mode in your Profile page"
                  component={
                    <IconButton
                      className={classes.icon}
                      onClick={toggleNightMode}
                      aria-label="pte success dark mode"
                    >
                      {user?.isNightMode ? <NightIcon /> : <LightIcon />}
                    </IconButton>
                  }
                ></TooltipCustom>
              )}

              <NotificationIcon />
              <UserIcon />
            </div>
          )}

          {/* Public navbar */}
          {!isAuthenticated(user) && (
            <>
              <div className={classes.linkWrapper}>
                {getNavbar(largeDevice).map((item, id) => {
                  const { name, toLink } = item;
                  const isSelected = window.location.pathname === toLink;
                  const hasRegisteredOnce = localStorage[HAS_REGISTERED_ONCE];

                  return (
                    <Link
                      key={id}
                      color="textPrimary"
                      href={toLink}
                      className={clsx(classes.link, {
                        [classes.linkSelected]: isSelected,
                        [classes.linkHighlight]:
                          (toLink === ROUTE_SIGNUP && !hasRegisteredOnce) ||
                          (toLink === ROUTE_LOGIN && hasRegisteredOnce),
                      })}
                      onClick={() => props.navigateTo(toLink)}
                    >
                      {name}
                    </Link>
                  );
                })}
              </div>
              <NavbarCollapsedMenu largeDevice={largeDevice} />
            </>
          )}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 0,
    left: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "space-between",
    background: theme.customColors.navbar,
    height: theme.customVars.navbarHeight,
    [theme.breakpoints.down("sm")]: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  toolbar: {
    margin: "auto",
    paddingLeft: 0,
    width: "inherit",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
  title: {
    cursor: "pointer",
    marginLeft: "1rem",
    fontWeight: "bold",
    marginRight: "1.5rem",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginRight: "0.5rem",
    },
  },
  linkWrapper: {
    flexGrow: 0.9,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  link: {
    display: "flex",
    minWidth: "3rem",
    cursor: "pointer",
    padding: "5px 9px",
    alignItems: "center",
    whiteSpace: "nowrap",
    justifyContent: "center",
    margin: theme.spacing(0, 0.25),
    color: theme.customColors.blackWhite,
    "&:hover ": {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hover,
      borderRadius: "2rem",
    },
  },
  linkSelected: {
    color: "white",
    cursor: "default",
    borderRadius: "2rem",
    backgroundColor: theme.customColors.blue,
    "&:hover ": {
      borderRadius: "2rem",
      backgroundColor: theme.customColors.blue,
    },
  },
  linkHighlight: {
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: theme.customColors.greenDarker,
    border: `2px solid ${theme.customColors.greenDarker}`,
    "&:hover ": {
      borderRadius: "5px",
      opacity: theme.customVars.hoverOpacity,
      backgroundColor: theme.customColors.greenDarker,
    },
  },
  iconWrapper: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
    },
  },
  icon: {
    color: "#FFDA22",
    marginRight: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0.5rem",
    },
  },
  iconAdmin: {
    color: "#65AB00",
    marginRight: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0.5rem",
    },
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  settings: state.settings,
  practice: state.practice,
  promotions: state.promotions,
});

export default connect(mapStateToProps, {
  navigateTo,
  toggleNightMode,
})(withRouter(Navbar));
