import axios from "axios";

import {
  SET_PRACTICE,
  GET_MOCK_TESTS,
  ROUTE_MOCK_TEST,
  SET_REVIEW_MODE,
  SET_PRACTICE_MOCK,
  SET_MOCK_TEST_MODE,
  SET_MOCK_TEST_IS_UP,
  ROUTE_EXERCISE_MOCK,
  CLEAR_MOCK_TEST_IS_UP,
  ROUTE_MOCK_TEST_RESULT,
  SET_MOCK_TEST_MODE_ONLY,
} from "../helpers/types";

import { updateUser } from "./userActions";
import { handleErrors } from "./errorActions";
import { navigateTo } from "./navigationActions";
import { clearRecorderReducer } from "./recorderActions";
import { startLoadingSpinner, stopLoadingSpinner } from "./loadingActions";

// @desc  Fetch all mock tests
// @next  Load mock tests to redux
export const fetchAllMockTest = () => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .get("/api/mocktest/fetchAllMockTest")
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch({ type: GET_MOCK_TESTS, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Continue selected mock test
// @next  Load mock test in redux
export const loadMockTest = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/mocktest/loadMockTest", data)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch(loadMockTestQuestion(res.data.mockTest));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Start mock test first time
// @next  Load mock test in redux
export const startMockTest = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/mocktest/startMockTest", data)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch(loadMockTestQuestion(res.data.mockTest));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Delete mock test
// @next  Refresh user in redux
export const deleteMockTest = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/mocktest/deleteMockTest", data)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch(updateUser({}));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Start free mock test
export const startFreeMockTest = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/mocktest/startFreeMockTest", data)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch(loadMockTestQuestion(res.data.mockTest));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Load selected mock test
// @next  Set Mock test mode + redirect to exercise screen
export const loadMockTestQuestion = (data) => (dispatch) => {
  dispatch({ type: SET_PRACTICE_MOCK, payload: data });
  dispatch({
    type: SET_MOCK_TEST_MODE,
    payload: {
      sessionId: data.sessionId,
      mockTestIndex: data.questions.length,
    },
  });
  dispatch(navigateTo(ROUTE_EXERCISE_MOCK));
};

// @desc  Set Mock test mode only
export const setMockTestOnly = () => (dispatch) => {
  dispatch({ type: SET_MOCK_TEST_MODE_ONLY });
};

// @desc  Set Mock test timer is up
export const setMockTestIsUp = () => (dispatch) => {
  dispatch({ type: SET_MOCK_TEST_IS_UP });
};

// @desc  Clear Mock test timer is up
export const clearMockTestIsUp = () => (dispatch) => {
  dispatch({ type: CLEAR_MOCK_TEST_IS_UP });
};

// @desc  Save mock test question
export const saveMockTestQuestion = (data) => (dispatch) => {
  axios
    .post("/api/mocktest/saveMockTestQuestion", data)
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Force end of section - submit leftover section
export const saveMockTestEndSection = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/mocktest/saveMockTestEndSection", data)
      .then((res) => {
        dispatch(updateUser({}));
        resolve(res.data);
      })
      .finally(dispatch(stopLoadingSpinner()))
      .catch((error) => resolve(null));
  });
};

// @desc  Force end of mock test - submit leftover mock test
export const saveMockTestEndTest = (data) => (dispatch) => {
  axios
    .post("/api/mocktest/saveMockTestEndSection", data)
    .then((res) => {
      dispatch(
        submitMockTest({
          sessionId: data.sessionId,
          mockTestId: data.mockTestId,
        })
      );
    })

    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Submit mock test
// @next  Refresh user + redirect to mock test screen
export const submitMockTest = (data) => (dispatch) => {
  axios
    .post("/api/mocktest/submitMockTest", data)
    .then((res) => {
      dispatch(
        navigateTo(
          ROUTE_MOCK_TEST +
            `?id=${res.data.mockTest.mockTestCategory}&submit=${res.data.mockTest.sessionId}`
        )
      );
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Save mock test results
// @next  Refresh user + redirect to mock test result screen
export const saveMockTestResult = (data) => (dispatch) => {
  axios
    .post("/api/mocktest/saveMockTestResult", data)
    .then((res) => {
      dispatch(updateUser({}));

      if (!data?.preventNavigate) {
        dispatch(navigateTo(ROUTE_MOCK_TEST_RESULT + `?id=${data.sessionId}`));
      }
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Load selected mock test result
export const fetchMockTestResult = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/mocktest/fetchMockTestResult", data)
      .then((res) => resolve(res.data))
      .finally(dispatch(stopLoadingSpinner()))
      .catch((error) => resolve(null));
  });
};

// @desc  Load selected mock test result
export const fetchMockTestResultPublic = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/mocktest/fetchMockTestResultPublic", data)
      .then((res) => resolve(res.data))
      .finally(dispatch(stopLoadingSpinner()))
      .catch((error) => resolve(null));
  });
};

// @desc  Load selected mock test
// @next  Set Mock test mode + redirect to exercise screen
export const reviewMockTestQuestion = (data) => (dispatch) => {
  dispatch(clearRecorderReducer());
  dispatch({ type: SET_PRACTICE, payload: [data.question.uid] });
  dispatch({ type: SET_REVIEW_MODE, payload: data });
  dispatch(navigateTo(ROUTE_EXERCISE_MOCK));
};

// @desc  Saved did overview mock test
export const didMockTestOverview = (data) => (dispatch) => {
  axios
    .post("/api/mocktest/didMockTestOverview", data)
    .then((res) => {
      dispatch(updateUser({}));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Load selected mock test result
export const createRandomMockTest = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/mocktest/createRandomMockTest", data)
      .then((res) => resolve(res.data))
      .catch((error) => resolve(null));
  });
};
