import axios from "axios";
import setAuthToken from "../helpers/setAuthToken";
import { saveToLocalStorage } from "../helpers/saveToLocalStorage";
import sendGtmDataLayer from "../helpers/sendGtmDataLayer";

import {
  didAddToCart,
  updateContactName,
  // saveToVerifiedMailList,
} from "./maillistActions";
import { handleErrors } from "./errorActions";
import { navigateTo } from "./navigationActions";
import { displaySnackbar } from "./notificationActions";
import { stopLoadingSpinner, startLoadingSpinner } from "./loadingActions";

import {
  BRONZE,
  SILVER,
  ULTIMATE,
  SET_USER,
  JWT_TOKEN,
  SET_UPSELL,
  CLEAR_UPSELL,
  CLEAR_ERRORS,
  IS_NIGHT_MODE,
  ROUTE_CHECKOUT,
  BRONZE_DISCOUNT,
  ROUTE_DASHBOARD,
  SILVER_DISCOUNT,
  ULTIMATE_DISCOUNT,
  SNACK_REWARD_CLAIM,
  ROUTE_SET_PASSWORD,
  SNACK_MULTI_ACCOUNT,
  HAS_REGISTERED_ONCE,
  SNACK_PASSWORD_RESET,
  SNACK_EMAIL_VERIFIED,
  BRONZE_DISCOUNT_SECT,
  SET_USERS_TESTIMONIAL,
  FREE_SPEAKING_CREDITS,
  SNACK_ACCOUNT_DELETED,
  START_RETRIEVING_USER,
  FINISH_RETRIEVING_USER,
  SNACK_LEFT_TESTIMONIAL,
  SNACK_USER_TOKEN_EXPIRED,
  SNACK_EMAIL_NOT_VERIFIED,
  STEPS_SHOULD_SET_PASSWORD,
  FREE_SPEAKING_CREDITS_NUMBER,
} from "../helpers/types";
import isDevMode from "../helpers/isDevMode";
import { getDateNow } from "./settingActions";
import { ROUTE_LOGIN } from "../helpers/types";

// @desc  Register new user
// @next  Snackbar notif + redirect to login
export const registerUser = (userData) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/users/registerUser", userData)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch(getDateNow());
      dispatch(
        loginUser({ email: userData.email, password: userData.password })
      );

      if (!isDevMode()) {
        // Only in Production
        sendGtmDataLayer({ event: "userRegistered" });
      }
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Verify email token to validate account
// @next  Snackbar notif + save user to maillist + redirect to login
export const verifyTokenEmail = (token) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/users/verifyTokenEmail", token)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      if (res.status === 200) {
        dispatch(displaySnackbar(SNACK_EMAIL_VERIFIED));
        // dispatch(saveToVerifiedMailList(res.data));
      } else if (res.status === 201) {
        dispatch(displaySnackbar(SNACK_USER_TOKEN_EXPIRED));
      }

      dispatch(navigateTo(ROUTE_LOGIN));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Log in user
// @next  Save token to cookies + set user + redirect to dashboard
export const loginUser = (userData) => (dispatch) => {
  dispatch(startLoadingSpinner());

  axios
    .post("/api/users/loginStudent", userData)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      if (res.status === 200) {
        // Login allowed
        dispatch(saveUserToken(res.data.token));

        if (res?.data?.user?.stepsDone?.includes(STEPS_SHOULD_SET_PASSWORD)) {
          // Force set password alert
          dispatch({ type: SET_USER, payload: res.data.user });
          dispatch(navigateTo(ROUTE_SET_PASSWORD));
        } else {
          dispatch({ type: SET_USER, payload: res.data.user });
          dispatch(navigateTo(ROUTE_DASHBOARD));
        }
      } else if (res.status === 201) {
        // Email not verified
        dispatch(displaySnackbar(SNACK_EMAIL_NOT_VERIFIED));
        dispatch(handleErrors(res.data));
      }
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Log in user (From thank you page - only when created via Wechat because we can't know the new created password from Webhook)
// @next  Save token to cookies + set user + redirect to set new password
export const loginNewUserWechat = (userData) => (dispatch) => {
  dispatch(startLoadingSpinner());

  axios
    .post("/api/users/loginNewUserWechat", userData)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      if (res.status === 200) {
        // Login allowed
        dispatch(saveUserToken(res.data.token));

        if (res?.data?.user?.stepsDone?.includes(STEPS_SHOULD_SET_PASSWORD)) {
          // Force set password alert
          dispatch({ type: SET_USER, payload: res.data.user });
          dispatch(navigateTo(ROUTE_SET_PASSWORD));
        }
      }
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Retrieve user from token
// @next  Logout or Save token to cookies + set user + redirect to dashboard
export const retrieveUser = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  dispatch({ type: START_RETRIEVING_USER }); // To hide home page while retriving user to prevent flashing page

  axios
    .post("/api/users/retrieveUser", data)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch({ type: FINISH_RETRIEVING_USER });

      // Logout if token is expired
      if (data.shouldLogout) {
        dispatch(logoutUser({}));
      } else {
        dispatch({ type: SET_USER, payload: res.data });

        // Force set password alert
        if (res?.data?.stepsDone?.includes(STEPS_SHOULD_SET_PASSWORD)) {
          dispatch(navigateTo(ROUTE_SET_PASSWORD));
        }
      }
    })
    .catch((error) => {
      dispatch({ type: FINISH_RETRIEVING_USER });
      dispatch(handleErrors(error));
    });
};

// @desc  Save token to local storage
export const saveUserToken = (token) => () => {
  // Save to local storage
  saveToLocalStorage("jwtToken", token);
  saveToLocalStorage(HAS_REGISTERED_ONCE, "true");
  // Set token to Auth header
  setAuthToken(token);
};

// @desc  Reset password
// @next  Snackbar notif + redirect to login
export const resetPassword = (userData) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/users/resetPassword", userData)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      if (res.status === 200) {
        dispatch(displaySnackbar(SNACK_PASSWORD_RESET));
      } else if (res.status === 201) {
        dispatch(displaySnackbar(SNACK_USER_TOKEN_EXPIRED));
      }

      dispatch(navigateTo(ROUTE_LOGIN));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Verify if another user use same account
// @next  Snackbar notif + logout + redirect to login
export const verifyMultiAccount = (user) => (dispatch) => {
  axios
    .post("/api/users/verifyMultiAccount")
    .then((res) => {
      if (res.status === 200) {
        // Check if account has another session token
        if (user.sessionToken !== res.data.sessionToken) {
          dispatch(displaySnackbar(SNACK_MULTI_ACCOUNT));
          dispatch(logoutUser({}));
        }
      }
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  User logout
// @next  Remove token in cookies + redirect to login
export const logoutUser =
  ({ route = ROUTE_LOGIN }) =>
  (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
    // Remove token from localStorage
    localStorage.removeItem(JWT_TOKEN);
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
    dispatch({ type: SET_USER, payload: {} });
    dispatch(navigateTo(route));
  };

// @desc  Update user
// @next  Load user
export const updateUser = (data, updateMailList) => (dispatch) => {
  axios
    .post("/api/users/updateUser", data)
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });

      // Update firstName in maillist if needed
      if (updateMailList) {
        dispatch(
          updateContactName({
            email: res.data.email,
            firstName: res.data.firstName,
          })
        );
      }
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Update user (Promise)
// @next  Load user
export const updateUserPromise = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/users/updateUser", data)
      .then((res) => {
        dispatch({ type: SET_USER, payload: res.data });
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

// @desc  Update user (arrays)
// @next  Load user
export const updateUserArray = (data) => (dispatch) => {
  axios
    .post("/api/users/updateUserArray", data)
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Remove from user (arrays)
// @next  Load user
export const removeUserArray = (data) => (dispatch) => {
  axios
    .post("/api/users/removeUserArray", data)
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Toggle night mode
// @next  Update user + save isNightMode to local storage
export const toggleNightMode = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/users/toggleNightMode", data)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      saveToLocalStorage(IS_NIGHT_MODE, data.isNightMode);
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Check if App update is needed
// @next  Trigger app update modal
export const checkAppVersion = (version) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/users/checkAppVersion")
      .then((res) => resolve(version < res.data.APP_VERSION))
      .catch((err) => reject(err));
  });
};

// @desc  Update app version of user
// @next  Reload window
export const updateAppVersion = () => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/users/updateAppVersion")
    .then((res) => {
      dispatch(stopLoadingSpinner());
      window.location.reload();
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Claim reward PTE test paid
// @next  Snackbar + update user
export const claimReward = () => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/users/claimReward")
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch(displaySnackbar(SNACK_REWARD_CLAIM));
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Update password from profile page
// @next  Snackbar notif
export const updatePassword = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/users/updatePassword", data)
      .then((res) => {
        dispatch(stopLoadingSpinner());
        dispatch(displaySnackbar(SNACK_PASSWORD_RESET));
        resolve(res.data);
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};

// @desc  Credit referral user
export const creditReferral = (data) => (dispatch) => {
  axios
    .post("/api/users/creditReferral", data)
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Remove notif from user
// @next  Update user
export const markHasRead = (data) => (dispatch) => {
  axios
    .post("/api/users/markHasRead", data)
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Remove all notifs from user
// @next  Update user
export const markAllHasRead = (data) => (dispatch) => {
  axios
    .post("/api/users/markAllHasRead", data)
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Set new password for first time
// @next  Snackbar notif + redirect to login
export const setNewPassword = (userData) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/users/setNewPassword", userData)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch({ type: SET_USER, payload: res.data });
      dispatch(navigateTo(ROUTE_DASHBOARD));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Create and add item to checkout
// @next  Redirect to checkout
export const createCart = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/users/createCart", data)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch({ type: SET_USER, payload: res.data });
      dispatch({ type: CLEAR_UPSELL });

      // Tell maillist if package was added to cart
      if (
        [
          BRONZE,
          SILVER,
          ULTIMATE,
          BRONZE_DISCOUNT,
          SILVER_DISCOUNT,
          ULTIMATE_DISCOUNT,
          BRONZE_DISCOUNT_SECT,
        ].includes(data.packageId)
      ) {
        dispatch(didAddToCart({ email: res.data.email }));
      }

      dispatch(
        navigateTo(
          ROUTE_CHECKOUT +
            (data.fromProfilePage ? "?redirectToProfile=true" : "")
        )
        // Allow return to Profile
      );
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Save latest session settings
// @next  Update user
export const updateLatestSession = (data) => (dispatch) => {
  axios
    .post("/api/users/updateLatestSession", data)
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Add mock test credit to upsell
export const addToUserUpsell = (data) => {
  return { type: SET_UPSELL, payload: data };
};

// @desc  Clear mock test credit from upsell
export const clearUserUpsell = () => {
  return { type: CLEAR_UPSELL };
};

// @desc  Get number of free trial
// @next  Return stat numbers
export const getOverviewStudentData = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/users/getOverviewStudentData", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};

// @desc  Increment speaking credit used
// @next  Update user
export const useSpeakingCredit = (data) => (dispatch) => {
  if (data.isAuthenticated) {
    axios
      .post("/api/users/useSpeakingCredit", data)
      .then((res) => {
        dispatch({ type: SET_USER, payload: res.data });
        if (data.sessionToken !== res.data.sessionToken) {
          dispatch(displaySnackbar(SNACK_MULTI_ACCOUNT));
          dispatch(logoutUser({}));
        }
      })
      .catch((error) => dispatch(handleErrors(error)));
  } else {
    // Decrement free AI speaking credits if not logged in
    saveToLocalStorage(
      FREE_SPEAKING_CREDITS,
      parseInt(
        localStorage[FREE_SPEAKING_CREDITS] || FREE_SPEAKING_CREDITS_NUMBER
      ) - 1
    );
  }
};

// @desc  Decrement writing credit used
// @next  Update user
export const useWritingCredit = () => (dispatch) => {
  axios
    .post("/api/users/useWritingCredit")
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Get all testimonials
// @next  Return testimonials to redux
export const fetchTestimonials = () => (dispatch) => {
  axios
    .get("/api/users/fetchTestimonials")
    .then((res) => {
      dispatch({ type: SET_USERS_TESTIMONIAL, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Get 8 testimonials
// @next  Return 8 testimonials to redux
export const fetchTestimonialsEight = () => (dispatch) => {
  axios
    .get("/api/users/fetchTestimonialsEight")
    .then((res) => {
      dispatch({ type: SET_USERS_TESTIMONIAL, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Leave a rating
// @next  Update user
export const leaveRating = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/users/leaveRating", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};

// @desc  Leave a testimonial
// @next  Update user
export const leaveTestimonial = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/users/leaveTestimonial", data)
      .then((res) => {
        resolve(res.data);
        dispatch(displaySnackbar(SNACK_LEFT_TESTIMONIAL));
        dispatch(stopLoadingSpinner());
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};

// @desc  Increment postpone testimonial
// @next  Update user
export const postponeTestimonial = () => (dispatch) => {
  axios
    .get("/api/users/postponeTestimonial")
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Refuse leave testimonial
// @next  Update user
export const refuseTestimonial = () => (dispatch) => {
  axios
    .get("/api/users/refuseTestimonial")
    .then((res) => {
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Get average and number of testimonials
export const fetchAverageRating = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/users/fetchAverageRating")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};

// @desc Sync mobile app subscription
export const syncMobile = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/users/syncMobile")
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_USER, payload: res.data });
        }
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

// @desc Sync mobile app subscription
export const resetCredits = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/users/resetCredits")
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_USER, payload: res.data });
        }
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

// @desc Check mobile app subscription
export const getMobileStatus = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/users/getMobileStatus")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

// @desc  Delete user
// @next  Redirect user
export const deleteUser = () => (dispatch) => {
  axios
    .post("/api/users/deleteUser")
    .then((res) => {
      dispatch(displaySnackbar(SNACK_ACCOUNT_DELETED));
      dispatch(logoutUser({}));
    })
    .catch((error) => dispatch(handleErrors(error)));
};
