import { createTheme } from "@material-ui/core/styles";

// Inject custom variables to Material UI theme
const getTheme = (isNightMode) => {
  document.documentElement.style.setProperty(
    // Change scollbars background
    "--scrollbarBackground",
    isNightMode ? "#212121" : "#FBFBFB"
  );

  return isNightMode ? darkTheme : mainTheme;
};

// Light theme
const mainTheme = createTheme({
  // Create custom colors
  customColors: {
    answerRight: "#BFF199",
    answerWrong: "#FFD3D1",
    background: "#F2F5F8",
    blueBackground: "#ebf3ff",
    lightblueDark: "#ebf3ff",
    backgroundLightGray: "#F2F5F8",
    lightBlackWhite: "#3c3c3c",
    blackWhite: "#000000",
    blogText: "#454545",
    blue: "#3665f3",
    blueWhite: "#3665f3",
    blueLight: "#F0F8FF",
    blueDark: "#223A4C",
    blueDarkWhite: "#223A4C",
    borderLight: "#FFFFFF",
    contrastBackground: "#FFFFFF",
    favorite: "#F53493",
    footer: "#1E222D",
    grayLightDark: "#D9D9D6",
    greenDarker: "#008A20",
    greenDarkerWhite: "#008A20",
    greenLight: "#BFF199",
    navbar: "#FFFFFF",
    red: "#DA3025",
    redLight: "#FFD3D1",
    sidebar: "#3A343C",
    sidebarSelected: "#765A80",
    submit: "#EEEEEE",
    yellow: "#FFDA22",
    whiteBlack: "#FFFFFF",
    whiteDarkBlue: "#FFFFFF",
    headerTitle: "rgb(35, 45, 100)",
    blackWhiteCaption: "#848484",
    pricingPlanSubHeader: "#525252",
  },
  // Create custom vars
  customVars: {
    hoverOpacity: 0.88,
    navbarHeight: "64px",
  },
  // Override palette default
  palette: {
    primary: {
      main: "#3665f3",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DA3025",
      contrastText: "#fff",
    },
    text: {
      primary: "#0A0A0A",
      primaryInverse: "#D4D4D4",
    },
    background: {
      default: "#F2F5F8",
      paper: "#FFFFFF",
    },
    type: "light",
  },
  // Override typography default
  typography: {
    exerciseFontSize: "1.15rem",
  },
  // Override shadow default
  shadow: {
    boxLight: "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px",
  },
  // Override Mui default theme
  overrides: {
    MuiChip: { clickable: { "&:focus": { backgroundColor: "#F2F5F8" } } },
    MuiTooltip: {
      tooltip: { backgroundColor: "#000000D9", fontSize: "1em" },
    },
    MuiTablePagination: {
      toolbar: { paddingLeft: "0px" },
      actions: { marginLeft: "0px" },
    },
    MuiStepLabel: {
      label: { fontSize: "18px" },
    },
    MuiButton: {
      root: {
        // Trick to prevent color font bug
        transition: "color .01s",
      },
    },
  },
});

// Night theme
const darkTheme = createTheme({
  // Create custom colors
  customColors: {
    answerRight: "#65AB00",
    answerWrong: "#CA2020",
    background: "#121212",
    blueBackground: "#121212",
    lightblueDark: "#002b6c",
    backgroundLightGray: "white",
    lightBlackWhite: "#e0e0e0",
    blackWhite: "#FFFFFF",
    blogText: "#bdbdbd",
    blue: "#3665f3", // "#2196F3",
    blueWhite: "#FFFFFF",
    blueLight: "#098CDE",
    blueDark: "#223A4C",
    blueDarkWhite: "#FFFFFF",
    borderLight: "#3A3A3A",
    contrastBackground: "#213C4D",
    favorite: "#F53493",
    footer: "#1E222D",
    grayLightDark: "#454545",
    greenDarker: "#008000",
    greenDarkerWhite: "#FFFFFF",
    greenLight: "#BFF199",
    navbar: "#252525",
    red: "#DA3025",
    redLight: "#FFD3D1",
    sidebar: "#1B1B1B",
    sidebarSelected: "#323232",
    submit: "#616161",
    yellow: "#FFDA22",
    whiteBlack: "#000000",
    whiteDarkBlue: "#1E222D",
    headerTitle: "#fff",
    blackWhiteCaption: "#c5c5c5",
    pricingPlanSubHeader: "#9e9e9e",
  },
  // Create custom vars
  customVars: {
    hoverOpacity: 0.88,
    navbarHeight: "64px",
  },
  // Override palette default
  palette: {
    primary: {
      main: "#3665f3",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DA3025",
      contrastText: "#fff",
    },
    text: {
      primary: "#D4D4D4",
      primaryInverse: "#0A0A0A",
    },
    background: {
      default: "#1E1E1E",
      paper: "#252525",
    },
    type: "dark",
  },
  // Override typography default
  typography: {
    exerciseFontSize: "1.15rem",
  },
  // Override shadow default
  shadow: {
    boxLight: "rgba(0, 0, 0, 0.8) 0px 2px 4px -1px",
  },
  // Override Mui default theme
  overrides: {
    MuiChip: { clickable: { "&:focus": { backgroundColor: "#F2F5F8" } } },
    MuiTooltip: {
      tooltip: { backgroundColor: "#000000D9", fontSize: "1em" },
    },
    MuiTablePagination: {
      toolbar: { paddingLeft: "0px" },
      actions: { marginLeft: "0px" },
    },
    MuiStepLabel: {
      label: { fontSize: "18px" },
    },
    MuiButton: {
      root: {
        // Trick to prevent color font bug
        transition: "color .01s",
      },
    },
  },
});

export default getTheme;
