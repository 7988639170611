import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { makeStyles } from "@material-ui/core/styles";

import useAudioPlayer from "../../helpers/useAudioPlayer";
import { STEPS_AUTO_PLAYBACK_OFF } from "../../helpers/types";
import { doesUserHave, isAuthenticated } from "../../helpers/userCheck";
import { removeUserArray, updateUserArray } from "../../actions/userActions";

import AudioPlayback from "../screenExercise/components/AudioPlayback";

// Modal playback audio before submiting
function AlertAudioBeforeSubmit({ openAlert, ...props }) {
  const classes = useStyles();
  const { user } = props;
  const [open, setOpen] = useState(false);
  const [playerId, setPlayerId] = useState("");

  const { isLoaded, setStartPlaying } = useAudioPlayer(playerId);

  // Check if autoplay or not
  const autoPlaybackOff = doesUserHave(user, STEPS_AUTO_PLAYBACK_OFF);

  // Trigger open modal
  useEffect(() => {
    setOpen(openAlert);
  }, [openAlert]);

  // Set playerId
  useEffect(() => {
    if (open) {
      setPlayerId("audio__playback__submit");
    } else {
      setPlayerId("");
    }
  }, [open]);

  // Trigger start playback
  useEffect(() => {
    if (open && isLoaded && !autoPlaybackOff) {
      setStartPlaying();
    }
  }, [open, isLoaded]);

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.paper } }}
      >
        <DialogTitle id="alert-dialog-title">Your recorded speech</DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <AudioPlayback
              playerId={playerId}
              openedAlert={props.openedAlert}
            />
          </div>

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                disabled={!isAuthenticated(user)}
                checked={!autoPlaybackOff}
                onChange={() => {
                  if (!autoPlaybackOff) {
                    props.updateUserArray({
                      stepsDone: STEPS_AUTO_PLAYBACK_OFF,
                    });
                  } else {
                    props.removeUserArray({
                      stepsDone: STEPS_AUTO_PLAYBACK_OFF,
                    });
                  }
                }}
              />
            }
            label="Play recording automatically"
          />
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={() => props.closeAlert()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              props.submitAudio();
              props.closeAlert();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "30rem",
    [theme.breakpoints.down("xs")]: {
      minWidth: "calc(100% - 1rem)",
    },
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { updateUserArray, removeUserArray })(
  AlertAudioBeforeSubmit
);
