import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import McqCell from "./components/McqCell";
import AudioPlayer from "./components/AudioPlayer";

import { isAuthenticated } from "../../helpers/userCheck";
import { setPracticeScore } from "../../actions/questionActions";

// Listening single answer - Highlight summary - missing words exercises
function ExerciseListeningMCQ({ question, didSubmit, ...props }) {
  const classes = useStyles();
  const { track, uid, multichoice, correctIndex } = question;

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // Save score on submit
  useEffect(() => {
    if (didSubmit) {
      // Final score
      const score = selectedAnswer == correctIndex ? "1/1" : "0/1";

      props.setPracticeScore({
        uid,
        score,
        successful: selectedAnswer == correctIndex,
        isAuthenticated: isAuthenticated(props.user),
      });
    }
  }, [didSubmit]);

  return (
    <Grid container direction="row" className={classes.screen}>
      <TaskBox uid={uid} />
      <AudioPlayer track={track} />
      <Typography className={classes.question}>{question.question}</Typography>
      {multichoice.map((suggestion, i) => {
        return (
          <McqCell
            key={i}
            index={i}
            didSubmit={didSubmit}
            suggestion={suggestion}
            correctIndex={correctIndex}
            selectedAnswer={selectedAnswer}
            onSelectCell={(value) =>
              setSelectedAnswer(selectedAnswer == value ? null : value)
            }
          />
        );
      })}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flexGrow: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    justifyContent: "center",
    alignContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  question: {
    width: "100%",
    margin: "0.5rem 0",
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setPracticeScore })(
  ExerciseListeningMCQ
);
