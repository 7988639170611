import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import AudioPlayer from "./components/AudioPlayer";

import { cleanString } from "../../helpers/stringHelper";
import { isAuthenticated } from "../../helpers/userCheck";
import { setPracticeScore } from "../../actions/questionActions";

// Listening fill in blanks exercise
function ExerciseListeningFillBlanks({ question, didSubmit, ...props }) {
  const classes = useStyles();
  const { showAnswer } = props.practice;
  const { track, topic, uid, correctAnswers } = question;

  const [topicDisplay, setTopicDisplay] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // Save number of targets to selectedAnswer
  useEffect(() => {
    setSelectedAnswer(Array(correctAnswers.length).fill(""));
  }, []);

  // Trigger save score
  useEffect(() => {
    if (didSubmit) {
      saveScore();
    }
  }, [didSubmit]);

  // Trigger render topic
  useEffect(() => {
    if ((!topicDisplay && selectedAnswer) || showAnswer || didSubmit) {
      renderTopic();
    }
  }, [selectedAnswer, didSubmit, showAnswer]);

  // Save score on submit
  function saveScore() {
    const targets = document.getElementsByName("target");

    const correct = [];
    const enteredAnswers = [];

    // Get correct scores
    for (var i = 0; i < targets.length; i++) {
      const value = targets[i].value.trim();
      enteredAnswers.push(value);
      correct.push(value == correctAnswers[i]);
    }
    setSelectedAnswer(enteredAnswers);
    const actualScore = correct.filter((correct) => correct);
    // Final score
    const score = `${actualScore.length}/${correctAnswers.length}`;

    props.setPracticeScore({
      uid,
      score,
      successful: actualScore.length > correctAnswers.length / 2,
      isAuthenticated: isAuthenticated(props.user),
    });
  }

  // Render topic
  function renderTopic() {
    // Loop words in topic
    const words = topic.split(" ").map((word, i) => {
      if (word.includes("__")) {
        // Create drop targets
        const id = parseInt(cleanString(word)) - 1;
        return <Target id={id} />;
      } else {
        // Create normal words
        return (
          <Typography
            key={i}
            variant="body1"
            color="textPrimary"
            style={{ padding: "2px", margin: "4px 0" }}
          >
            {word}
          </Typography>
        );
      }
    });
    setTopicDisplay(words);
  }

  if (!selectedAnswer) return null;

  return (
    <Grid container direction="column" className={classes.screen}>
      <TaskBox uid={uid} />
      <div className={classes.playerWrapper}>
        <AudioPlayer track={track} />
      </div>

      <div>
        <Grid item xs={12} className={classes.column}>
          {topicDisplay}
        </Grid>
      </div>

      <div style={{ flexGrow: 1 }}></div>
    </Grid>
  );

  // Target component
  function Target({ id }) {
    const isCorrect = selectedAnswer[id] == correctAnswers[id];
    return (
      <input
        type="text"
        name="target"
        variant="outlined"
        autoComplete="off"
        key={`target_${id}`}
        disabled={didSubmit}
        value={
          didSubmit
            ? showAnswer
              ? correctAnswers[id]
              : selectedAnswer[id]
            : null
        }
        className={clsx(classes.target, {
          [classes.targetCorrect]: (didSubmit && isCorrect) || showAnswer,
          [classes.targetWrong]: didSubmit && !isCorrect && !showAnswer,
        })}
        classes={{ label: didSubmit && classes.cellDisabled }}
      />
    );
  }
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flexGrow: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  column: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  playerWrapper: {
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  target: {
    margin: "1px",
    height: "2rem",
    fontWeight: 500,
    display: "flex",
    minWidth: "6rem",
    fontSize: "1rem",
    background: "white",
    borderRadius: "0px",
    alignItems: "center",
    padding: "0 0.25rem",
    justifyContent: "center",
    border: "solid 1px black !important",
    [theme.breakpoints.down("xs")]: {
      minWidth: "4rem",
      padding: "0 0.25rem",
    },
  },
  targetCorrect: {
    background: theme.customColors.answerRight,
    border: `solid 1px ${theme.customColors.greenDarker}`,
    color: `${theme.customColors.blackWhite}!important`,
  },
  targetWrong: {
    background: theme.customColors.answerWrong,
    border: `solid 1px ${theme.customColors.red}`,
    color: `${theme.customColors.blackWhite}!important`,
  },
  cellDisabled: {
    color: `${theme.customColors.blackWhite}!important`,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  practice: state.practice,
});

export default connect(mapStateToProps, { setPracticeScore })(
  ExerciseListeningFillBlanks
);
