import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import { makeStyles } from "@material-ui/core/styles";

import { getBrowserData } from "../../helpers/getBrowserData";
import { CHROME, SAFARI, FIREFOX } from "../../helpers/types";

// Modal alert to show permission is denied
function AlertMicPermsDenied() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  // Return browser data
  function getImage() {
    const browserName = getBrowserData()?.browser?.name;
    if (browserName === CHROME) {
      return "https://pineapplestudio.com.au/pte_success_v2/images/layout/microphone_permission_setup.webp";
    } else if (browserName === FIREFOX) {
      return "https://pineapplestudio.com.au/pte_success_v2/images/layout/microphone_permission_setup_firefox.webp";
    } else if (browserName === SAFARI) {
      return "https://pineapplestudio.com.au/pte_success_v2/images/layout/microphone_permission_setup_safari.webp";
    } else {
      return "https://pineapplestudio.com.au/pte_success_v2/images/layout/microphone_permission_setup.webp";
    }
  }

  return (
    <div>
      <div className={classes.helpButton} onClick={() => setOpen(true)}>
        Help
      </div>
      <Dialog
        maxWidth={"lg"}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Microphone permission denied
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your microphone permission is not enabled. Follow the instructions
            below to activate it.
          </DialogContentText>

          <img
            width="842"
            height="339"
            alt={`PTE microphone permission`}
            style={{ maxWidth: "100%", height: "auto" }}
            src={getImage()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => window.location.reload()}
          >
            I understand
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  helpButton: {
    color: "white",
    fontSize: "1rem",
    cursor: "pointer",
    fontWeight: "bold",
    borderRadius: "5px",
    marginLeft: "0.5rem",
    padding: "0.2rem 0.5rem",
    background: theme.customColors.red,
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
  link: {
    textDecoration: "none",
    color: theme.customColors.blue,
  },
}));

export default AlertMicPermsDenied;
