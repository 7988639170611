import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { makeStyles, withStyles, withTheme } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import QuickIcon from "@material-ui/icons/FlashOn";
import GiftIcon from "@material-ui/icons/CardGiftcard";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SuperQuickIcon from "@material-ui/icons/FastForward";

import LogoIcon from "./LogoIcon";
import PulseComponent from "../common/PulseComponent";
import TooltipCustom from "../screenExercise/components/TooltipCustom";

import { navigateTo } from "../../actions/navigationActions";

import {
  QUICK_MODE,
  ROUTE_REWARD,
  SUPER_QUICK_MODE,
  STEPS_VIEW_REWARD,
} from "../../helpers/types";
import isDevMode from "../../helpers/isDevMode";
import getSideDrawer from "../../helpers/getSideDrawer";
import { saveToLocalStorage } from "../../helpers/saveToLocalStorage";

// Left side drawer
function SideDrawer(props) {
  const classes = useStyles();
  const { blue, yellow } = props.theme.customColors;

  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{ paper: classes.drawer }}
      >
        <LogoIcon /> {/* Top left logo */}
        <Divider />
        <List>
          {/* Render icon from getSideDrawer */}
          {getSideDrawer.map((item) => {
            const { name, toLink, icon } = item;
            const isSelected = window.location.pathname.includes(toLink);
            return (
              <LightTooltip key={name} title={name} placement="right">
                <ListItem
                  button
                  style={{ justifyContent: "center", padding: "0.8em 0" }}
                  onClick={() => props.navigateTo(toLink)}
                >
                  <ListItemIcon
                    style={{
                      color: isSelected ? blue : "",
                      justifyContent: "center",
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                </ListItem>
              </LightTooltip>
            );
          })}
        </List>
        <Divider />
        {/* Render reward icon at bottom left */}
        <List
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          {isDevMode() && localStorage[QUICK_MODE] && (
            <TooltipCustom
              title="SUPER QUICK Mock Test (DEV)"
              subTitle="- All audio recordings are replaced by a 5 seconds recording only."
              component={
                <ListItem
                  button
                  onClick={() => {
                    if (localStorage[SUPER_QUICK_MODE]) {
                      localStorage.removeItem(SUPER_QUICK_MODE);
                    } else {
                      saveToLocalStorage(SUPER_QUICK_MODE, true);
                    }
                    setRefresh(!refresh);
                  }}
                  style={{
                    padding: "0.8em 0",
                    alignSelf: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <ListItemIcon
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      color: localStorage[SUPER_QUICK_MODE] ? "red" : "gray",
                    }}
                  >
                    <SuperQuickIcon />
                  </ListItemIcon>
                </ListItem>
              }
            ></TooltipCustom>
          )}

          {isDevMode() && (
            <TooltipCustom
              title="QUICK Mock Test (DEV)"
              subTitle="- Waiting time in Speaking = 4 seconds"
              subTitle2="- Max time per section = 10 minutes"
              subTitle3="- Copy & Paste allowed in Writing"
              component={
                <ListItem
                  button
                  onClick={() => {
                    if (localStorage[QUICK_MODE]) {
                      localStorage.removeItem(QUICK_MODE);
                    } else {
                      saveToLocalStorage(QUICK_MODE, true);
                    }
                    setRefresh(!refresh);
                  }}
                  style={{
                    padding: "0.8em 0",
                    alignSelf: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <ListItemIcon
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      color: localStorage[QUICK_MODE] ? "red" : "gray",
                    }}
                  >
                    <QuickIcon />
                  </ListItemIcon>
                </ListItem>
              }
            ></TooltipCustom>
          )}
          <LightTooltip title={"Reward"} placement="right">
            <ListItem
              button
              onClick={() => props.navigateTo(ROUTE_REWARD)}
              style={{
                justifyContent: "center",
                padding: "0.8em 0",
                alignSelf: "flex-end",
              }}
            >
              <ListItemIcon
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <PulseComponent
                  component={GiftIcon}
                  trigger={STEPS_VIEW_REWARD}
                  style={{
                    color:
                      window.location.pathname === ROUTE_REWARD ? blue : yellow,
                  }}
                />
              </ListItemIcon>
            </ListItem>
          </LightTooltip>
        </List>
      </Drawer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: theme.spacing(8) + 1,
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

// Tooltip component
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "1em",
    background: theme.customColors.blue,
    color: "white",
    boxShadow: theme.shadows[1],
  },
}))(Tooltip);

export default connect(null, { navigateTo })(withTheme(withRouter(SideDrawer)));
