import {
  GET_ERRORS,
  CLEAR_ERRORS,
  SNACK_DEV_ERROR,
  SNACK_CRITICAL_ERROR,
} from "../helpers/types";
import isDevMode from "../helpers/isDevMode";

import { logoutUser } from "./userActions";
import { stopLoadingSpinner } from "./loadingActions";
import { displaySnackbar } from "./notificationActions";

// @desc  Handle errors (useable error vs system error)
export const handleErrors = (error) => (dispatch) => {
  dispatch(stopLoadingSpinner());
  if (error?.response?.status === 401) {
    // Unauthorized - also happens if user if blocked
    if (isDevMode()) {
      console.log("DEV ERROR Unauthorized " + error?.response?.status);
      dispatch(displaySnackbar(SNACK_DEV_ERROR));
    }
    dispatch(logoutUser({}));
  } else if (!error.response) {
    // Crash server to display on Papertrail (Heroku)
    if (isDevMode()) {
      console.log("DEV ERROR " + error);
      dispatch(displaySnackbar(SNACK_CRITICAL_ERROR));
    }
  } else {
    const { errors } = error.response.data;
    if (errors && !errors.internal) {
      // Errors that we generate (ex: Wrong email, text too short,...)
      dispatch({ type: GET_ERRORS, payload: errors });
    } else if (errors && errors.internal) {
      // Server error that we track (see serverErrorList.js)
      if (isDevMode()) {
        console.log("DEV ERROR " + error);
        dispatch(displaySnackbar(SNACK_CRITICAL_ERROR));
      }
    } else {
      // Other unknown server error to display on Papertrail (Heroku)
      if (isDevMode()) {
        console.log("DEV ERROR " + error);
        dispatch(displaySnackbar(SNACK_DEV_ERROR));
      }
    }
  }
};

// @desc  Clear errors
export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
