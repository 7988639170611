import isEmpty from "./isEmpty";
import { cleanBrackets, cleanNumbersFromString } from "./stringHelper";

// Highlight only full words without repetition
const highlightFullWords = ({ searchWords, textToHighlight }) => {
  const chunks = [];
  if (isEmpty(searchWords)) return chunks;

  const multiKeyword = searchWords.includes("MULTI_KEYWORD"); // Highlight multiple keywords (describe image/retell)
  const textLow = textToHighlight.toLowerCase();
  const singleTextWords = textLow.split(/\s+/); // Split words by blanks

  let fromIndex = 0;
  const singleTextWordsWithPos = singleTextWords.map((s) => {
    const indexInWord = textLow.indexOf(s, fromIndex);
    fromIndex = indexInWord + s.length;
    return { word: s, index: indexInWord };
  });

  // Clean search words
  let tempSearchWords = searchWords.map((w) =>
    cleanNumbersFromString(cleanBrackets(w))
  ); // Clean punctuation

  // Check similar words and remove them
  singleTextWordsWithPos.map((s) => {
    const swLow = cleanNumbersFromString(cleanBrackets(s.word)); // Clean punctuation
    if (tempSearchWords.includes(swLow)) {
      const indexToRemove = tempSearchWords.indexOf(swLow);
      if (!multiKeyword) {
        tempSearchWords.splice(indexToRemove, 1, "WORD_REMOVED");
      }

      const start = s.index;
      const end = s.index + s.word.length; // Highlight length of actual word (including punctuation)
      chunks.push({ start, end });
    }
  });

  return chunks;
};

export default highlightFullWords;
