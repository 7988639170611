import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  setToggleRecording,
  setTimeLeftWaiting,
} from "../../../actions/recorderActions";
import { useInterval } from "../../../helpers/customHook";

// Timer in Speaking before starting recording
function RecorderWaitingTimer({ waitingTime, ...props }) {
  const {
    isRecording,
    startRecorder,
    playerDidFinish,
    timeLeftWaiting,
    timerShouldStart,
    didFinishRecording,
  } = props.recorder;
  const [isRunning, setIsRunning] = useState(false);

  // Save waiting time for this question
  useEffect(() => {
    props.setTimeLeftWaiting(waitingTime);
  }, []);

  // Countdown
  useInterval(
    () => {
      props.setTimeLeftWaiting(timeLeftWaiting - 1);
    },
    isRunning ? 1000 : null
  );

  // Start/stop timer if needed
  useEffect(() => {
    setIsRunning(
      playerDidFinish && timerShouldStart && !isRecording && !didFinishRecording
    );
  }, [playerDidFinish, timerShouldStart, isRecording, didFinishRecording]);

  // Starts recording when countdown at 0
  useEffect(() => {
    if (timeLeftWaiting <= 0 && !startRecorder) {
      props.setToggleRecording(true);
    }
  }, [timeLeftWaiting]);

  return null;
}
const mapStateToProps = (state) => ({
  recorder: state.recorder,
});

export default connect(mapStateToProps, {
  setToggleRecording,
  setTimeLeftWaiting,
})(React.memo(RecorderWaitingTimer));
