import React, { useState } from "react";
import JsxParser from "react-jsx-parser";
import clsx from "clsx";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchIcon from "@material-ui/icons/Search";

import AlertSkillOfficial from "./AlertSkillOfficial";
import HighlightWord from "../screenExercise/components/HighlightWord";

import {
  ENABLING_FORM,
  ENABLING_FINAL,
  ENABLING_CONTENT,
} from "../../helpers/types";
import {
  getMaxTaskScore,
  getMaxSkillScore,
  getEnablingEnablingComment,
} from "../../helpers/getEnablingSkillData";
import { getExerciseSettings } from "../../helpers/getExerciseData";

// Modal alert with table analysis
function AlertSkillAnalysisPreviousWriting({
  uid,
  score,
  htmlJsx,
  sampleAnswer,
  speechTextArray,
  showHighlight = false,
  ...props
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("xs")); // Full screen on mobile

  // Check if no word recorded
  const emptyRecording = htmlJsx === "<p></p>";

  // Loop Listening summarize text skills
  const rows = getExerciseSettings(uid).skills.map((skill) =>
    createData(
      skill,
      `${score[skill]}/${getMaxSkillScore(skill, uid)}`,
      getEnablingEnablingComment({ score, skill, uid })
    )
  );

  // Create data rows
  function createData(skill, score, suggestion) {
    return { skill, score, suggestion };
  }

  return (
    <div className={classes.score}>
      <Typography style={{ marginRight: "0.5rem", fontWeight: "bold" }}>
        {`Score: ` + `${score[ENABLING_FINAL]}/${getMaxTaskScore(uid)}`}
      </Typography>

      {/* See details button to open modal */}
      <IconButton className={classes.playIcon} onClick={() => setOpen(true)}>
        <SearchIcon />
      </IconButton>
      <Dialog
        open={open}
        maxWidth="lg"
        fullScreen={fullScreen}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"AI Score analysis"}</DialogTitle>
        <DialogContent>
          {/* Table part */}
          <Paper elevation={0} className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.row}>
                  <TableCell style={{ minWidth: "20%" }}>
                    Enabling skill
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>Score</TableCell>
                  <TableCell style={{ width: "70%" }}>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  const isScore0 = score[row.skill] === 0;
                  const isForm0 = row.skill === ENABLING_FORM && isScore0;
                  return (
                    <TableRow className={classes.row} key={row.skill}>
                      <TableCell
                        className={clsx(classes.cellSkill, {
                          [classes.cellSkillRed]: isForm0,
                        })}
                      >
                        {row.skill}
                        {/* Modal alert for official guide */}
                        <AlertSkillOfficial
                          uid={uid}
                          index={index}
                          skill={row.skill}
                          isScore0={isScore0}
                          score={
                            getMaxTaskScore(uid) === "90" // For speaking skills
                              ? Math.round(
                                  (score[row.skill] *
                                    ((uid.includes("SRS") ||
                                      uid.includes("SRA")) && // Content in SRS/SRA = 3 points max
                                    row.skill === ENABLING_CONTENT
                                      ? 3
                                      : 5)) /
                                    90
                                )
                              : score[row.skill]
                          }
                        />
                      </TableCell>
                      <TableCell className={classes.cellScore}>
                        {row.score}
                      </TableCell>
                      <TableCell className={classes.cellSuggestion}>
                        {row.suggestion}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
          {/* Actual answer */}
          <Typography style={{ fontSize: "1.3rem" }}>Your answer:</Typography>
          <div className={classes.jsxParser}>
            {emptyRecording ? (
              <div style={{ fontStyle: "italic" }}>Nothing recorded</div>
            ) : (
              <Typography style={{ whiteSpace: "pre-line" }}>
                <JsxParser
                  components={{ highlightword: HighlightWord }}
                  jsx={htmlJsx}
                />
              </Typography>
            )}
          </div>
          {/* Sample answer */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ fontSize: "1.3rem" }}>
              Sample answer:
            </Typography>
          </div>
          <Typography
            style={{ padding: "0.75em 0" }}
            style={{ whiteSpace: "pre-line" }}
          >
            {sampleAnswer}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  score: {
    height: "3rem",
    display: "flex",
    marginRight: "1rem",
    borderRadius: "5px",
    alignItems: "center",
    padding: "0.3rem 0.5rem",
    border: "1px solid lightgray",
    [theme.breakpoints.down("xs")]: {
      margin: "1em",
      width: "100%",
    },
  },
  button: {
    color: "white",
    borderRadius: "5px",
    padding: "0.3em 1.5em",
    background: theme.customColors.blueDark,
    "&:hover ": {
      cursor: "pointer",
      transform: "scale(1.01)",
      opacity: theme.customVars.hoverOpacity,
    },
  },
  playIcon: {
    width: "auto",
    color: theme.customColors.greenDarker,
  },
  root: {
    width: "100%",
    overflowX: "auto",
    marginBottom: "1em",
    border: "1px solid lightgray",
  },
  table: {
    minWidth: 700,
    [theme.breakpoints.down("xs")]: {
      minWidth: 100,
    },
  },
  row: {
    display: "flex",
    flex: 1,
  },
  cellSkill: {
    alignItems: "center",
    display: "flex",
    minWidth: "20%",
    maxWidth: "20%",
  },
  cellSkillRed: {
    color: theme.customColors.red,
    fontWeight: "bold",
  },
  cellScore: {
    width: "10%",
    alignItems: "center",
    display: "flex",
  },
  cellSuggestion: {
    width: "70%",
  },
  pulseContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  jsxParser: {
    lineHeight: "1.5em",
    fontSize: "1.1rem",
    padding: "0.75em 0",
  },
}));

export default AlertSkillAnalysisPreviousWriting;
