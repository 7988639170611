import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ErrorIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import AudioPlayer from "./components/AudioPlayer";
import TextFieldGroup from "../common/TextFieldGroup";

import { isAuthenticated } from "../../helpers/userCheck";
import { setDidSubmit, setPracticeScore } from "../../actions/questionActions";

// Listening write from dictation exercise
function ExerciseListeningWriteDictation({ question, didSubmit, ...props }) {
  const classes = useStyles();
  const { track, transcript, uid } = question;

  const [selectedAnswer, setSelectedAnswer] = useState("");

  // Trigger save score
  useEffect(() => {
    if (didSubmit) {
      saveScore();
    }
  }, [didSubmit]);

  // Save score on submit
  function saveScore() {
    const transcriptArr = transcript?.split(" ");
    const enteredArr = selectedAnswer?.split(" ");

    const correct = [];
    // Check selected answers
    enteredArr.map((word) => {
      if (transcriptArr.includes(word)) {
        const index = transcriptArr.indexOf(word);
        transcriptArr[index] = "hasBeenUsed"; // Block scoring the same word again
        correct.push(word);
      }
    });

    // Final score
    const score = `${correct.length}/${transcript.split(" ").length}`;

    props.setPracticeScore({
      uid,
      score,
      successful: correct.length > transcript.split(" ").length / 2,
      isAuthenticated: isAuthenticated(props.user),
    });
  }

  // Check if 1st word is capitalize
  const capitalLetter = () => {
    const char = selectedAnswer?.split(" ")[0][0];

    if (char && char === char.toUpperCase()) {
      return {
        text: "Start with a capital letter",
        classe: classes.iconGreen,
        icon: <CheckIcon />,
      };
    } else {
      return {
        text: "Start with a capital letter",
        classe: classes.iconRed,
        icon: <ErrorIcon />,
      };
    }
  };

  // Check if last word has full dot
  const endWithDot = () => {
    const char = selectedAnswer?.split(" ").reverse()[0].slice(-1);

    if (char && char === ".") {
      return {
        text: "End with a full stop",
        classe: classes.iconGreen,
        icon: <CheckIcon />,
      };
    } else {
      return {
        text: "End with a full stop",
        classe: classes.iconRed,
        icon: <ErrorIcon />,
      };
    }
  };

  // Render transcript
  function renderTranscript() {
    // Loop words in transcript
    return transcript.split(" ").map((word, i) => {
      var newArr = selectedAnswer?.split(" ");
      var isCorrect = newArr.includes(word);
      if (isCorrect) newArr.filter((value) => value !== word);

      return (
        <Typography
          key={i}
          variant="body1"
          color="textPrimary"
          className={clsx(classes.target, {
            [classes.targetCorrect]: didSubmit && isCorrect, // Rightly selected
            [classes.targetWrong]: didSubmit && !isCorrect, // Wrongly selected
          })}
        >
          {word}
        </Typography>
      );
    });
  }

  return (
    <Grid container direction="row" className={classes.screen}>
      <TaskBox uid={uid} />
      <AudioPlayer track={track} />
      <TextFieldGroup
        rows={2}
        multiline={true}
        disabled={didSubmit}
        value={selectedAnswer}
        placeholder="Type here"
        customClass={classes.target}
        customContainer={classes.targetContainer}
        onChange={(e) => setSelectedAnswer(e.target.value)}
        onKeyPress={() => props.setDidSubmit(true)}
      />
      {/* Word count */}
      <Typography className={classes.wordCount}>
        Total Word Count:{" "}
        {selectedAnswer ? selectedAnswer?.trim()?.split(/\s+/).length : 0}
      </Typography>

      {/* Show answer */}
      {didSubmit && (
        <div style={{ width: "100%" }}>
          <Typography className={classes.title}>Transcript:</Typography>
          <Grid item xs={12} className={classes.answer}>
            {renderTranscript()}
          </Grid>

          {[capitalLetter(), endWithDot()].map((res, i) => {
            return (
              <ListItem
                key={i}
                disableGutters={true}
                className={classes.iconWrapper}
              >
                <ListItemIcon className={res.classe}>{res.icon}</ListItemIcon>
                <ListItemText
                  style={{ fontSize: "1.2em" }}
                  primary={res.text}
                />
              </ListItem>
            );
          })}
        </div>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flexGrow: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  title: {
    fontSize: "1.3rem",
  },
  answer: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "1rem",
  },
  targetContainer: {
    width: "100%",
    marginTop: "2rem",
  },
  target: {
    margin: 0,
  },
  targetCorrect: {
    fontWeight: 600,
    cursor: "default",
    marginRight: "4px",
    color: theme.customColors.greenDarker,
  },
  targetWrong: {
    fontWeight: 500,
    cursor: "default",
    marginRight: "4px",
    color: theme.customColors.red,
  },
  iconWrapper: {
    flex: 0.5,
    padding: 0,
    display: "flex",
  },
  iconGreen: {
    minWidth: "30px",
    color: theme.customColors.greenDarker,
  },
  iconRed: {
    minWidth: "30px",
    color: theme.customColors.red,
  },
  disabled: {
    cursor: "default",
    "&:hover ": {
      opacity: 1,
      background: "white",
    },
  },
  wordCount: {
    width: "100%",
    margin: "1rem 0",
  },
  answerDummy: {
    padding: "1rem",
    display: "flex",
    flexWrap: "wrap",
    minHeight: "5rem",
    borderRadius: "4px",
    width: "calc(100% - 2rem)",
    border: "1px solid gray !important",
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setDidSubmit, setPracticeScore })(
  ExerciseListeningWriteDictation
);
