import { useState, useEffect, useRef } from "react";
import { VOLUME } from "./types";
import { saveToLocalStorage } from "./saveToLocalStorage";

// Variables from Audio Player
function useAudioPlayer(playerId) {
  const [curTime, setCurTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [didFinish, setDidFinish] = useState(false);
  const [playbackRate, setPlaybackRate] = useState("1");
  const [clickedTime, setClickedTime] = useState(null);
  const [playerError, setPlayerError] = useState(false);
  const [playerCanPlay, setPlayerCanPlay] = useState(false);

  const audio = useRef();

  useEffect(() => {
    audio.current = document.getElementById(playerId);
    // Set listeners
    if (audio.current) {
      audio.current.addEventListener("loadeddata", setAudioData);
      audio.current.addEventListener("timeupdate", setAudioTime);
      audio.current.addEventListener("ended", setPlayerFinished);
      audio.current.addEventListener("error", setPlayerHasError);
      audio.current.addEventListener("canplaythrough", setCanPlay);

      audio.current.playbackRate = playbackRate;
      audio.current.volume = localStorage[VOLUME] || 0.5;
    }

    // Cleanup listeners
    return () => {
      if (audio.current) {
        audio.current.removeEventListener("loadeddata", setAudioData);
        audio.current.removeEventListener("timeupdate", setAudioTime);
        audio.current.removeEventListener("ended", setPlayerFinished);
        audio.current.removeEventListener("error", setPlayerHasError);
        audio.current.removeEventListener("canplaythrough", setCanPlay);
      }

      audio.current = null;
      setIsLoaded(false);
    };
  }, [playerId]);

  useEffect(() => {
    if (clickedTime !== null && clickedTime !== curTime) {
      audio.current.currentTime = clickedTime;
      setClickedTime(null);
    } else {
      setClickedTime(null);
    }
  }, [clickedTime]);

  // Set current time and duration after loading
  const setAudioData = () => {
    setPlayerError(false);
    setIsLoaded(true);
    setDuration(audio.current.duration);
    setCurTime(audio.current.currentTime);
  };

  // Update current time on playing
  const setAudioTime = () => setCurTime(audio.current.currentTime);

  const setPlayerFinished = () => {
    setDidFinish(true);
    setPausePlaying();
  };

  // Use in overview
  const setStartPlaying = () => {
    setDidFinish(false);
    tryPlay();
  };

  // Use in overview
  const setPausePlaying = () => {
    audio?.current?.pause();
    setPlaying(false);
  };

  // Prevent crash Safari
  const tryPlay = () => {
    var promise = audio.current?.play();
    if (promise !== undefined) {
      promise
        .then((_) => {
          setPlaying(true);
        })
        .catch(() => {});
    }
  };

  // Triggered when player error
  const setPlayerHasError = () => setPlayerError(true);

  // Triggered when player is loaded
  const setCanPlay = () => setPlayerCanPlay(true);

  // Set audio volume
  const setVolume = (e, volume) => {
    saveToLocalStorage(VOLUME, volume);
    audio.current.volume = volume;
  };

  return {
    curTime,
    playing,
    duration,
    isLoaded,
    didFinish,
    setVolume,
    setPlaying,
    playerError,
    playbackRate,
    playerCanPlay,
    setClickedTime,
    setPlaybackRate,
    setStartPlaying,
    setPausePlaying,
  };
}

export default useAudioPlayer;
