import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Suspense loading spinner (blue)
function SpinnerSuspense(props) {
  const classes = useStyles();

  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress
        size={70}
        thickness={5}
        disableShrink
        className={classes.loading}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    flex: 1,
    display: "flex",
    minHeight: "20rem",
    alignItems: "center",
    justifyContent: "center",
  },
  loading: {
    color:
      theme.palette.type === "light"
        ? theme.customColors.blueDark
        : theme.customColors.blue,
  },
}));

export default SpinnerSuspense;
