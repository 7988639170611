import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { withTheme, makeStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import FaceIcon from "@material-ui/icons/Face";
import ContactIcon from "@material-ui/icons/Send";
import ExitIcon from "@material-ui/icons/ExitToApp";
import GiftIcon from "@material-ui/icons/CardGiftcard";

import {
  ROUTE_USER,
  ROUTE_REWARD,
  ROUTE_EXERCISE,
  ROUTE_PRACTICE,
  ROUTE_CONTACT_US,
} from "../../helpers/types";
import getSideDrawer from "../../helpers/getSideDrawer";
import { logoutUser } from "../../actions/userActions";
import { navigateTo } from "../../actions/navigationActions";

// Drop down menu from User avatar
function UserIconMenu({ anchorEl, ...props }) {
  const classes = useStyles();

  const { red } = props.theme.customColors;

  // Navigate to link
  function goTo(link) {
    props.navigateTo(link);
    handleClose();
  }

  // Tell parent to close menu
  function handleClose() {
    props.handleClose();
  }

  return (
    <Menu
      keepMounted
      elevation={0}
      anchorEl={anchorEl}
      onClose={handleClose}
      open={Boolean(anchorEl)}
      MenuListProps={{ disablePadding: true }}
      PaperProps={{ style: { border: "1px solid #d3d4d5" } }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <ClickAwayListener onClickAway={() => handleClose}>
        <div>
          <MenuList>
            {/* Profile */}
            <MenuItem
              onClick={() => goTo(ROUTE_USER)}
              className={clsx(classes.link, {
                [classes.linkSelected]: window.location.pathname === ROUTE_USER,
              })}
            >
              <ListItemIcon
                style={{
                  minWidth: "3rem",
                  color: window.location.pathname === ROUTE_USER ? "white" : "",
                }}
              >
                <FaceIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                selected={window.location.pathname === ROUTE_USER}
                primary={
                  <Typography type="body2" style={{ fontWeight: "bold" }}>
                    My Profile
                  </Typography>
                }
              />
            </MenuItem>
            <Divider style={{ width: "96%", marginLeft: "2%" }} />
            {/* Menu items  */}
            {getSideDrawer
              .concat({
                name: "Reward",
                toLink: ROUTE_REWARD,
                icon: <GiftIcon />,
              })
              .map((item, i) => {
                const { name, toLink, icon } = item;
                const isSelected =
                  window.location.pathname.includes(toLink) ||
                  (window.location.pathname === ROUTE_EXERCISE &&
                    toLink === ROUTE_PRACTICE);

                return (
                  <MenuItem
                    key={i}
                    onClick={() => goTo(toLink)}
                    className={clsx(classes.link, {
                      [classes.linkSelected]: isSelected,
                    })}
                  >
                    <ListItemIcon
                      style={{
                        minWidth: "3rem",
                        color: isSelected ? "white" : "",
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      selected={isSelected}
                      primary={
                        <Typography type="body2" style={{ fontWeight: "bold" }}>
                          {name}
                        </Typography>
                      }
                    />
                  </MenuItem>
                );
              })}
            {/* Contact Us */}
            <MenuItem
              onClick={() => goTo(ROUTE_CONTACT_US)}
              className={clsx(classes.link, {
                [classes.linkSelected]:
                  window.location.pathname === ROUTE_CONTACT_US,
              })}
            >
              <ListItemIcon
                style={{
                  minWidth: "3rem",
                  color:
                    window.location.pathname === ROUTE_CONTACT_US
                      ? "white"
                      : "",
                }}
              >
                <ContactIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                selected={window.location.pathname === ROUTE_CONTACT_US}
                primary={
                  <Typography type="body2" style={{ fontWeight: "bold" }}>
                    Contact Us
                  </Typography>
                }
              />
            </MenuItem>
            <Divider style={{ width: "96%", marginLeft: "2%" }} />
            {/* Log Out */}
            <MenuItem
              onClick={() => props.logoutUser({})}
              className={classes.link}
            >
              <ListItemIcon style={{ minWidth: "3rem", color: red }}>
                <ExitIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    type="body2"
                    style={{ fontWeight: "bold", color: red }}
                  >
                    Log Out
                  </Typography>
                }
              />
            </MenuItem>
          </MenuList>
        </div>
      </ClickAwayListener>
    </Menu>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 1),
    margin: theme.spacing(0.5, 0.5),
    minWidth: "4em",
    "&:hover ": {
      borderRadius: "5px",
      color: theme.customColors.blackWhite,
      backgroundColor: theme.palette.action.hover,
    },
  },
  linkSelected: {
    color: "white",
    borderRadius: "5px",
    background: theme.customColors.blue,
  },
  linkHighlight: {
    borderRadius: "5px",
    border: `2px solid ${theme.customColors.blue}`,
  },
}));

export default connect(null, {
  logoutUser,
  navigateTo,
})(withTheme(UserIconMenu));
