import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import ListItem from "@material-ui/core/ListItem";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import getNotification from "../../helpers/getNotification";
import { navigateTo } from "../../actions/navigationActions";
import { markHasRead, markAllHasRead } from "../../actions/userActions";

// Drop down notification menu
function NotificationIconMenu({ anchorEl, notifications, ...props }) {
  const classes = useStyles();

  // Render notifications
  function createNotifications(notifications) {
    return notifications.slice(0, 5).map((notification, i) => {
      // Get notification data
      const { title, icon, color, redirectLink } = getNotification(
        notification.type
      );
      if (!title) return;

      return (
        <ListItem
          key={"notif_" + i}
          onClick={() => goTo(redirectLink, notification.id)}
          className={classes.notif}
        >
          <ListItemIcon style={{ color }}>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      );
    });
  }

  // Navigate to link
  function goTo(link, notificationId) {
    props.markHasRead({ notificationId });
    props.navigateTo(link);
    handleClose();
  }

  // Tell parent to close menu
  function handleClose() {
    props.handleClose();
  }

  // Remove all notifications
  function markAllHasRead() {
    props.markAllHasRead();
    props.handleClose();
  }

  return (
    <Menu
      elevation={1}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      classes={{ paper: classes.menu }}
    >
      <ClickAwayListener onClickAway={() => handleClose}>
        <div>
          <Typography variant="h6" className={classes.title}>
            Notifications
          </Typography>
          {notifications.length > 0 ? (
            <>
              <MenuList>
                {/* Render notifications */}
                {createNotifications(notifications)}
              </MenuList>
              <Typography
                className={classes.allRead}
                onClick={() => markAllHasRead()}
              >
                Mark all as read
              </Typography>
            </>
          ) : (
            <div className={classes.logoWrapper} onClick={() => handleClose()}>
              {/* Logo if no notifications */}
              <CardMedia
                className={classes.logo}
                image="https://pineapplestudio.com.au/pte_success_v2/images/layout/notif_empty.png"
                alt="pte success notification"
              />
              <Typography className={classes.category}>All good!</Typography>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </Menu>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const useStyles = makeStyles((theme) => ({
  menu: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    background: theme.customColors.background,
    minHeight: "20em",
    minWidth: "20em",
    maxWidth: "25em",
    borderRadius: "5px",
  },
  notif: {
    minHeight: "5em",
    cursor: "pointer",
    border: `solid 1px ${theme.palette.grey[300]}`,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.boxLight,
    borderRadius: "5px",
    width: "96%",
    marginLeft: "2%",
  },
  title: {
    marginLeft: "1em",
    fontWeight: "bold",
  },
  allRead: {
    float: "right",
    marginRight: "1em",
    marginBottom: "0.5em",
    fontSize: "0.9rem",
    color: theme.customColors.red,
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
      cursor: "pointer",
    },
  },
  logo: {
    height: "5em",
    width: "5em",
  },
  logoWrapper: {
    height: "15em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

export default connect(mapStateToProps, {
  markHasRead,
  markAllHasRead,
  navigateTo,
})(NotificationIconMenu);
