import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { isAdmin } from "../../helpers/userCheck";
import { ROUTE_LOGIN } from "../../helpers/types";

// Protected route for admins only
function PrivateAdminRoute({ component: Component, user, ...props }) {
  return (
    <Route
      {...props}
      render={(props) =>
        isAdmin(user) ? <Component {...props} /> : <Redirect to={ROUTE_LOGIN} />
      }
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PrivateAdminRoute);
