import axios from "axios";
import { GET_ADMIN_SETTINGS } from "../helpers/types";
import { handleErrors } from "./errorActions";
import { startLoadingSpinner, stopLoadingSpinner } from "./loadingActions";

// @desc  Fetch all admin settings
// @next  Load admin settings to redux
export const fetchAllAdminSettings = () => (dispatch) => {
  axios
    .get("/api/admins/fetchAllAdminSettings")
    .then((res) => {
      dispatch({ type: GET_ADMIN_SETTINGS, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Fetch email list (Promise)
// @next  Return emails
export const fetchEmailList = (data) => () => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/admins/fetchEmailList", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

// @desc  Update field of all users
export const updateFields = () => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/admins/updateFields", {})
    .then((res) => {
      console.log(res);
      dispatch(stopLoadingSpinner());
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Fetch student blocked status (Promise)
// @next  Return blocked status
export const fetchStudentBlockStatus = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/admins/fetchStudentBlockStatus", data)
      .then((res) => {
        resolve(res.data);
        dispatch(stopLoadingSpinner());
      })
      .catch((err) => {
        dispatch(stopLoadingSpinner());
        reject(err);
      });
  });
};

// @desc  Block student
// @next  Return blocked status
export const blockStudent = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/admins/blockStudent", data)
      .then((res) => {
        resolve(res.data);
        dispatch(stopLoadingSpinner());
      })
      .catch((err) => {
        dispatch(stopLoadingSpinner());
        reject(err);
      });
  });
};

// @desc  Unblock student
// @next  Return blocked status
export const unblockStudent = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/admins/unblockStudent", data)
      .then((res) => {
        resolve(res.data);
        dispatch(stopLoadingSpinner());
      })
      .catch((err) => {
        dispatch(stopLoadingSpinner());
        reject(err);
      });
  });
};

// @desc  Delete list of students
export const deleteOldAccount = () => (dispatch) => {
  axios
    .post("/api/admins/deleteOldAccount")
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Fetch all surveys
// @next  Return surveys
export const fetchAllSurveys = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/admins/fetchAllSurveys")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// @desc  Fetch results for survey
// @next  Return results
export const fetchSurveyResults = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/admins/fetchSurveyResults", data)
      .then((res) => {
        resolve(res.data);
        dispatch(stopLoadingSpinner());
      })
      .catch((err) => {
        dispatch(stopLoadingSpinner());
        reject(err);
      });
  });
};
