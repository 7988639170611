import { GET_ADMIN_SETTINGS } from "../helpers/types";

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_SETTINGS:
      return action.payload;

    default:
      return state;
  }
}
