import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  ROUTE_PRACTICE,
  ROUTE_EXERCISE,
  ROUTE_MOCK_TEST,
  ROUTE_EXERCISE_MOCK,
} from "../../helpers/types";
import isEmpty from "../../helpers/isEmpty";
import { verifyMultiAccount } from "../../actions/userActions";
import { clearNavigate } from "../../actions/navigationActions";

const triggerUrls = [
  ROUTE_PRACTICE,
  ROUTE_EXERCISE,
  ROUTE_MOCK_TEST,
  ROUTE_EXERCISE_MOCK,
];
// Allow navigation to any route from anywhere (check for multi sessions)
function NavigationManager(props) {
  const { navigation, user } = props;

  // Push route to history
  useEffect(() => {
    if (navigation) {
      props.history.push(navigation);
      props.clearNavigate();
    }
  }, [navigation]);

  // Check for multi account in Practice route (limit calls)
  useEffect(() => {
    if (!isEmpty(user) && triggerUrls.includes(window.location.pathname)) {
      props.verifyMultiAccount(user);
    }
  }, [window.location.pathname]);

  return null;
}

const mapStateToProps = (state) => ({
  user: state.user,
  navigation: state.navigation,
});

export default connect(mapStateToProps, { clearNavigate, verifyMultiAccount })(
  withRouter(NavigationManager)
);
