import {
  ENABLING_VOC,
  ENABLING_DSC,
  ENABLING_GLR,
  ENABLING_FORM,
  ENABLING_FLUENCY,
  ENABLING_CONTENT,
  ENABLING_GRAMMAR,
  ENABLING_SPELLING,
  ENABLING_EMAIL_CONV,
  ENABLING_ORGANIZATION,
  ENABLING_PRONUNCIATION,
  ENABLING_NUM_SPELL_MISTAKE,
  ENABLING_NUM_GRAMMAR_MISTAKE,
} from "./types";

// Return official PTE guide and score values for skill and exercise
const getEnablingOfficialGuide = (skill, uid) => {
  // Content skill
  if (skill === ENABLING_CONTENT) {
    if (uid.includes("LST")) {
      return {
        question:
          "Does your response summarize the main points in the lecture?",
        definition:
          "Content is scored by determining if all the key points of the topic have been addressed without misrepresenting the purpose or topic. If your summary misinterprets the topic or the purpose of the lecture, you will not receive any score points for your summary on any of the five factors. Your summary will be scored zero. The best responses clearly summarize the main points and condense essential supporting points. They focus on the topic, including only key information and essential supporting points.",
        marks: [
          "0 - Omits or misrepresents the main aspects",
          "1 - Provides a fair summary of the text, but one or two aspects are missing",
          "2 - Provides a good summary of the text. All relevant aspects are mentioned",
        ],
      };
    } else if (uid.includes("WWS")) {
      return {
        question:
          "Does your response summarize the main points in the passage?",
        definition:
          "Content is scored by determining if all key points of the passage have been addressed without misrepresenting the purpose or topic. If your summary misinterprets the topic or the purpose of the passage, you will not receive any score points for your summary on any of the four factors. Your summary will be scored zero. The best responses clearly summarize the main idea and condense essential supporting points. They focus on the topic, including only key information and essential supporting points.",
        marks: [
          "0 - Omits or misrepresents the main aspects",
          "1 - Provides a fair summary of the text, but one or two aspects are missing",
          "2 - Provides a good summary of the text. All relevant aspects are mentioned",
        ],
      };
    } else if (uid.includes("WWE")) {
      return {
        question: "Does your response address the topic?",
        definition:
          "Content is scored by determining if all aspects of the topic have been addressed in your response. The appropriateness of the details, examples and explanations used to support your point of view is also scored. If your essay does not address the topic, you will not receive any score points for your essay on any of the seven factors. Your essay will be scored zero. The best responses are on topic, contain logical and specific information and answer any questions asked. They also support any arguments with details, examples and/or explanations.",
        marks: [
          "0 - Does not deal properly with the prompt",
          "1 - Deals with the prompt but omits a major aspect or more than one minor aspect",
          "2 - Deals with the prompt but does not deal with one minor aspect",
          "3 - Adequately deals with the prompt",
        ],
      };
    } else if (uid.includes("WWM")) {
      return {
        question: "Does your response address the prompt?",
        definition:
          "Content is scored by determining if all aspects of the prompt have been addressed in your response. If your email does not address the prompt, you will not receive any score points for your email on any of the seven factors. Your response will be scored zero. The best responses address the requirements of the tasks sufficiently and appropriately.",
        marks: [
          "0 - Does not properly deal with the task; the task and/or topics may have been largely misunderstood. May not deal properly with the prompt due to significant amounts of pre-prepared/memorized material",
          "1 - Attempts to address the task but is not successful; the task and/ or topics may have been misunderstood",
          "2 - Addresses the task with some success, and demonstrates some understanding of the task",
          "3 - Addresses the requirements of the task sufficiently and appropriately",
        ],
      };
    } else if (uid.includes("SSQ")) {
      return {
        question: "Does your response answer the question?",
        definition:
          "Your response for Answer Short Question is judged on our ability to understand a question presented in a recording and provide a brief and accurate response. Your response is scored as either correct or incorrect based on the appropriacy of the words in your response. No credit is given for no response or an incorrect response.",
        marks: [
          "0 - Inappropriate word choice in response",
          "1 - Appropriate word choice in response",
        ],
      };
    } else if (uid.includes("SRS")) {
      return {
        question:
          "Does your response include all the words in the sentence, and only these words?",
        definition:
          "Content is scored by counting the number of correct word sequences in your response. Having almost nothing from the prompt in your response will negatively affect your score.",
        marks: [
          "0 - Almost nothing from the prompt in the response",
          "1 - Less than 50% of words in the response from the prompt in the correct sequence",
          "2 - At least 50% of words in the response from the prompt in the correct sequence",
          "3 - All words in the response from the prompt in the correct sequence",
        ],
      };
    } else if (uid.includes("SRA")) {
      return {
        question:
          "Does your response include all the words in the reading text and only these words?",
        definition:
          "Content is scored by counting the number of correct words in your response. Replacements insertions and omissions of words will negatively affect your score.",
        marks: [
          "0 - Almost nothing from the prompt in the response",
          "1 - Less than 50% of words in the response from the prompt in the correct sequence",
          "2 - At least 50% of words in the response from the prompt in the correct sequence",
          "3 - All words in the response from the prompt in the correct sequence",
        ],
      };
    }
    // Form skill
  } else if (skill === ENABLING_FORM) {
    if (uid.includes("LST")) {
      // PTE CORE
      // Word limits have changed: Summarize Spoken Text: 20-30 words; Summarize Written Text: 25-50 words; and, E-Mail: up to 100 words.
      return {
        question:
          "Does your response meet the length requirement of between 50 and 70 words?",
        definition:
          "Form is scored by counting the number of words in your response. You will receive full credit if your response is between 50 and 70 words. Writing less than 50 words or more than 70 words will decrease your score. If your summary contains less than 40 words or more than 100 words, you will not receive any score points for your summary on any of the five factors. Your summary will be scored zero.",
        marks: [
          "0 - Contains less than 40 words or more than 100 words. Summary is written in capital letters, contains no punctuation or consists only of bullet points or very short sentences",
          "1 - Contains 40-49 words or 71-100 words",
          "2 - Contains 50-70 words",
        ],
      };
    } else if (uid.includes("WWS")) {
      // PTE CORE
      // Word limits have changed: Summarize Spoken Text: 20-30 words; Summarize Written Text: 25-50 words; and, E-Mail: up to 100 words.
      return {
        question:
          "Does your response meet the requirements of a one-sentence summary?",
        definition:
          "If your summary contains fewer than five words or more than 75 words, you will not receive any score points for your summary on any of the four factors. Your summary will be scored zero.",
        marks: [
          "0 - Not written in one, single, complete sentence or contains fewer than 5 or more than 75 words. Summary is written in capital letters",
          "1 - Is written in one, single, complete sentence",
        ],
      };
    } else if (uid.includes("WWE")) {
      return {
        question:
          "Does your response meet the length requirement of between 200 and 300 words?",
        definition:
          "Form is scored by counting the number of words in your response. You will receive full credit if your essay is between 200 and 300 words. Writing less than 200 words or more than 300 words will decrease your score. If your essay contains less than 120 words or more than 380 words, you will not receive any score points for your essay on any of the seven factors. Your essay will be scored zero.",
        marks: [
          "0 - Length is less than 120 or more than 380 words. Essay is written in capital letters, contains no punctuation or only consists of bullet points or very short sentences",
          "1 - Length is between 120 and 199 or between 301 and 380 words",
          "2 - Length is between 200 and 300 words",
        ],
      };
    } else if (uid.includes("WWM")) {
      return {
        question:
          "Does your response meet the length requirement of between 50 and 120 words?",
        definition:
          "Form is scored by counting the number of words in your response. You will receive full credit if your email is between 50 and 120 words. Writing less than 50 words or more than 120 words will decrease your score. If your email contains less than 30 words or more than 140 words, you will not receive any score points for your answer on any of the seven factors. Your answer will be scored zero.",
        marks: [
          "0 - Length is less than 30 or more than 140 words. Email is written in capital letters, contains no punctuation or only consists of bullet points or very short sentences",
          "1 - Length is between 30 and 49 or between 121 and 140 words",
          "2 - Length is between 50 and 120 words",
        ],
      };
    }
    // Grammar skill
  } else if (skill === ENABLING_GRAMMAR) {
    if (uid.includes("LST")) {
      return {
        question: "Does your response demonstrate correct grammatical usage?",
        definition:
          "Grammar is scored by determining if the basic structure of the sentences is correct. The best responses use concise sentences that clearly communicate the intended meaning.",
        marks: [
          "0 - Defective grammatical structures which could hinder communication",
          "1 - Contains grammatical errors with no hindrance to communication",
          "2 - Correct grammatical structures",
        ],
      };
    } else if (uid.includes("WWS")) {
      return {
        question: "Does your response demonstrate correct grammatical usage?",
        definition:
          "Grammar is scored by determining if the basic structure of the sentence is correct. The best responses usually consist of a main clause and subordinate clause.",
        marks: [
          "0 - Has defective grammatical structure which could hinder communication",
          "1 - Contains grammatical errors but with no hindrance to communication",
          "2 - Has correct grammatical structure",
        ],
      };
    } else if (uid.includes("WWE")) {
      return {
        question:
          "Does your response demonstrate correct grammatical usage and consistent control of standard written English?",
        definition:
          "Grammar usage and mechanics are scored by examining sentence structure, punctuation and capitalization. The best responses contain high proportions of grammatically correct complex sentences that clearly communicate the intended meaning.",
        marks: [
          "0 - Contains mainly simple structures and/or several basic mistakes",
          "1 - Shows a relatively high degree of grammatical control. No mistakes which would lead to misunderstandings",
          "2 - Shows consistent grammatical control of complex language. Errors are rare and difficult to spot",
        ],
      };
    } else if (uid.includes("WWM")) {
      return {
        question:
          "Does your response demonstrate correct grammatical usage and consistent control of standard written English?",
        definition:
          "Grammar usage and mechanics are scored by examining sentence structure, punctuation and capitalization. The best responses contain high proportions of grammatically correct complex sentences that clearly communicate the intended meaning.",
        marks: [
          "0 - Contains mainly simple structures and/or several basic mistakes",
          "1 - Fair degree of grammatical control, errors may be evident but do not cause the reader undue effort",
          "2 - Generally consistent grammatical control with only occasional errors",
        ],
      };
    }
    // Vocabulary skill
  } else if (skill === ENABLING_VOC) {
    if (uid.includes("WWE")) {
      return {
        question:
          "Does your response demonstrate command of a broad vocabulary range?",
        definition:
          "Vocabulary range is scored according to the variety of words in your response and their appropriateness in an academic environment. Synonyms, idiomatic expressions and academic terms are also assessed if appropriate. The best responses use precise academic terms and avoid repetition by using synonyms and idioms where appropriate.",
        marks: [
          "0 - Contains mainly basic vocabulary insufficient to deal with the topic at the required level",
          "1 - Shows a good range of vocabulary for matters connected to general academic topics. Lexical shortcomings lead to circumlocution or some imprecision",
          "2 - Good command of a broad lexical repertoire, idiomatic expressions and colloquialisms",
        ],
      };
    } else {
      return {
        question:
          "Does your response demonstrate correct and appropriate word choice and usage?",
        definition:
          "Vocabulary is scored according to its relevance to the topic and its appropriateness in an academic environment. The appropriate use of synonyms is also scored. The best responses use words from the topic appropriately, demonstrate an understanding of the context and use synonyms effectively to show variety in language use.",
        marks: [
          "0 - Defective word choice which could hinder communication",
          "1 - Some lexical errors but with no hindrance to communication",
          "2 - Appropriate choice of words",
        ],
      };
    }
    // Spelling skill
  } else if (skill === ENABLING_SPELLING) {
    return {
      question:
        "Does your response demonstrate correct and consistent use of a single spelling convention?",
      definition:
        "PTE recognizes English spelling conventions from the United States, the United Kingdom, Australia and Canada. However, one spelling convention should be used consistently in a given response.",
      marks: [
        "0 - More than one spelling error",
        "1 - One spelling error",
        "2 - Correct spelling. No error",
      ],
    };
    // DSC skill
  } else if (skill === ENABLING_DSC) {
    return {
      question:
        "Does your response demonstrate good development of ideas and a logical structure?",
      definition:
        "Development, structure and coherence are scored according to the organization of your response. A well-developed response uses a logical organizational pattern, connects ideas and explains these connections. The best responses contain multiple paragraphs and have a clear introduction, body and conclusion that relate to the topic of the essay. Within paragraphs, ideas are clearly presented and supported with details, examples and/or explanations. Transitions between paragraphs are smooth.",
      marks: [
        "0 - Lacks coherence and mainly consists of lists or loose elements",
        "1 - Is incidentally less well structured, and some elements or paragraphs are poorly linked",
        "2 - Shows good development and logical structure",
      ],
    };
    // GLR skill
  } else if (skill === ENABLING_GLR) {
    return {
      question:
        "Does your response use language that precisely conveys your ideas?",
      definition:
        "General linguistic range is scored by determining if the language in your response accurately communicates your ideas. This includes your ability to provide clear descriptions, change emphasis, eliminate ambiguity, express subtleties in meaning, and use stylistic features to communicate meaning. You are more likely to receive full credit if you use complex sentence structures and vocabulary correctly, rather than consistently using simple sentence structures and vocabulary. This is because complex ideas are often expressed with complex sentence structures and vocabulary. The best responses use language that highlights key ideas, creatively expresses opinions and ensures clear communication.",
      marks: [
        "0 - Contains mainly basic language and lacks precision",
        "1 - Sufficient range of language to provide clear descriptions, express viewpoints and develop arguments",
        "2 - Exhibits smooth mastery of a wide range of language to formulate thoughts precisely, give emphasis, differentiate and eliminate ambiguity. No sign that the test taker is restricted in what they want to communicate",
      ],
    };
  } else if (skill === ENABLING_FLUENCY) {
    return {
      question:
        "Does your response demonstrate a smooth, effortless and natural rate of Speech?",
      definition:
        "Oral fluency is scored by determining if your rhythm, phrasing and stress are smooth. The best responses are spoken at a constant and natural rate of speech with appropriate phrasing. Hesitations, repetitions and false starts will negatively affect your score.",
      marks: [
        "0 - Speech is slow and labored with little discernable phrase grouping, multiple hesitations, pauses, false starts, and/or major phonological simplifications. Most words are isolated, and there may be more than one long pause",
        "1 - Speech has irregular phrasing or sentence rhythm. Poor phrasing, staccato or syllabic timing, and/or multiple hesitations, repetitions, and/or false starts make spoken performance notably uneven or discontinuous. Long utterances may have one or two long pauses and inappropriate sentencelevel word emphasis",
        "2 - Speech may be uneven or staccato. Speech (if >= 6 words) has at least one smooth three-word run, and no more than two or three hesitations, repetitions or false starts. There may be one long pause, but not two or more",
        "3 - Speech is at an acceptable speed but may be uneven. There may be more than one hesitation, but most words are spoken in continuous phrases. There are few repetitions or false starts. There are no long pauses and speech does not sound staccato",
        "4 - Speech has an acceptable rhythm with appropriate phrasing and word emphasis. There is no more than one hesitation, one repetition or a false start. There are no significant non-native phonological simplifications",
        "5 - Speech shows smooth rhythm and phrasing. There are no hesitations, repetitions, false starts or non-native phonological simplifications",
      ],
    };
  } else if (skill === ENABLING_PRONUNCIATION) {
    return {
      question:
        "Does your response demonstrate your ability to produce speech sounds in a similar way to most regular speakers of the language?",
      definition:
        "Pronunciation is scored by determining if your speech is easily understandable to most regular speakers of the language. The best responses contain vowels and consonants pronounced in a native-like way, as well as words and phrases stressed correctly. Responses should also be immediately understandable to a regular speaker of the language.",
      marks: [
        "0 - Pronunciation seems completely characteristic of another language. Many consonants and vowels are mispronounced, misordered or omitted. Listeners may find more than 1/2 of the speech unintelligible. Stressed and unstressed syllables are realized in a non-English manner. Several words may have the wrong number of syllables",
        "1 - Many consonants and vowels are mispronounced, resulting in a strong intrusive foreign accent. Listeners may have difficulty understanding about 1/3 of the words. Many consonants may be distorted or omitted. Consonant sequences may be non-English. Stress is placed in a non-English manner; unstressed words may be reduced or omitted and a few syllables added or missed",
        "2 - Some consonants and vowels are consistently mispronounced in a nonnative like manner. At least 2/3 of speech is intelligible, but listeners might need to adjust to the accent. Some consonants are regularly omitted, and consonant sequences may be simplified. Stress may be placed incorrectly on some words or be unclear",
        "3 - Most vowels and consonants are pronounced correctly. Some consistent errors might make a few words unclear. A few consonants in certain contexts may be regularly distorted, omitted or mispronounced. Stressdependent vowel reduction may occur on a few words",
        "4 - Vowels and consonants are pronounced clearly and unambiguously. A few minor consonant, vowel or stress distortions do not affect intelligibility. All words are easily understandable. A few consonants or consonant sequences may be distorted. Stress is placed correctly on all common words, and sentence level stress is reasonable",
        "5 - All vowels and consonants are produced in a manner that is easily understood by regular speakers of the language. The speaker uses assimilation and deletions appropriate to continuous speech. Stress is placed correctly in all words and sentence-level stress is fully appropriate",
      ],
    };
  } else if (skill === ENABLING_ORGANIZATION) {
    return {
      question:
        "Does your response demonstrate good development of ideas and a logical structure?",
      definition:
        "Organizational structure refers to the clarity and coherence of the arrangement of ideas within a written document or communication. A well-structured piece presents information in a logical sequence, facilitating easy comprehension for the reader. Effective use of transitions aids in the smooth flow of ideas from one point to another, enhancing the overall coherence of the text. In the context of emails, an organized structure ensures that the message is delivered efficiently and effectively, guiding the reader through the content in a clear and comprehensible manner.",
      marks: [
        "0 - Organizational structure is missing or not appropriate. Some ideas may connect to each other but some or all connections are not made or are unclear. Transitions may be present but may not be helpful to the reader",
        "1 - Organizational structure is generally acceptable and somewhat clear. Themes and their related ideas are organized together but their relationship to the rest of the email may be unclear at points. Transitions are adequate but are mostly basic or simple",
        "2 - Organizational structure is clear and easy to follow. Ideas are presented logically and clearly organized. Transitions are used effectively to guide the reader through the email",
      ],
    };
  } else if (skill === ENABLING_EMAIL_CONV) {
    return {
      question:
        "Does your answer demonstrate appropriate use of email conventions?",
      definition:
        "Email conventions encompass the adherence to established norms and guidelines governing the composition and presentation of email correspondence. A well-executed application of email conventions involves clear and appropriate usage of standard email elements such as salutations, subject lines, and signatures, tailored to suit the purpose and context of the communication. Additionally, proper formatting, including paragraph breaks and bullet points when necessary, enhances readability and comprehension.",
      marks: [
        "0 - Email conventions are limited or missing",
        "1 - Email conventions are used inconsistently with elements missing and/or used ineffectively",
        "2 - Email conventions are obvious, appropriate, and used correctly in keeping with the format of the task",
      ],
    };
  } else {
    return null;
  }
};

// Return comment/suggestion for enablig skills based on score
const getEnablingEnablingComment = ({ score, skill, uid }) => {
  // Content skill
  if (skill === ENABLING_CONTENT) {
    if (uid.includes("WWE")) {
      if (score[skill] === 0) {
        return "Your answer is not related to the topic.";
      } else if (score[skill] === 1) {
        return "You should use more keywords from the topic.";
      } else if (score[skill] === 2) {
        return "Try to use more keywords from the topic.";
      } else if (score[skill] === 3) {
        return "Great! Your answer successfully addresses the topic.";
      }
    } else if (uid.includes("WWM")) {
      if (score[skill] === 0) {
        return "Your answer does not properly deal with the task.";
      } else if (score[skill] === 1) {
        return "The task and/ or topics may have been misunderstood.";
      } else if (score[skill] === 2) {
        return "Your answer addresses the task with some success.";
      } else if (score[skill] === 3) {
        return "Great! Your answer successfully addresses the topic.";
      }
    } else if (uid.includes("SSQ")) {
      if (score[skill] === 0) {
        return "Your answer is not correct.";
      } else if (score[skill] === 1) {
        return "Your answer is correct.";
      }
    } else if (uid.includes("SRS") || uid.includes("SRA")) {
      if (score[skill] < 50) {
        return "Your answer is not similar to the sentence.";
      } else if (score[skill] < 75) {
        return "Your response has at least 50% of the words from the sentence.";
      } else if (score[skill] >= 75) {
        return "Great! Your response is similar to the sentence.";
      }
    } else if (
      uid.includes("SRL") ||
      uid.includes("SDI") ||
      uid.includes("SRL")
    ) {
      if (score[skill] < 50) {
        return "Your answer does not have enough keywords.";
      } else if (score[skill] < 75) {
        return "You should use more keywords.";
      } else if (score[skill] >= 75) {
        return "Great! Your answer successfully addresses the topic.";
      }
    } else {
      if (score[skill] === 0) {
        return "Your answer does not have enough keywords.";
      } else if (score[skill] === 1) {
        return "You should use more keywords.";
      } else if (score[skill] === 2) {
        return "Great! Your answer successfully addresses the topic.";
      }
    }
    // Form skill
  } else if (skill === ENABLING_FORM) {
    if (uid.includes("LST")) {
      if (score[skill] === 0) {
        return "Word count requirement: 50 to 70. Click the info button for the official requirements";
      } else if (score[skill] === 1) {
        return "Word count requirement: 50 to 70. Click the info button for the official requirements";
      } else if (score[skill] === 2) {
        return "Excellent word count!";
      }
    } else if (uid.includes("WWS")) {
      if (score[skill] === 0) {
        return "1 sentence only. Word count requirement: 5 to 75. Click the info button for the official requirements";
      } else if (score[skill] === 1) {
        return "Excellent word count!";
      }
    } else if (uid.includes("WWE")) {
      if (score[skill] === 0) {
        return "Word count requirement: 200 to 300. Click the info button for the official requirements";
      } else if (score[skill] === 1) {
        return "Word count requirement: 200 to 300. Click the info button for the official requirements";
      } else if (score[skill] === 2) {
        return "Excellent word count!";
      }
    } else if (uid.includes("WWM")) {
      if (score[skill] === 0) {
        return "Word count requirement: 50 to 120. Click the info button for the official requirements";
      } else if (score[skill] === 1) {
        return "Word count requirement: 50 to 120. Click the info button for the official requirements";
      } else if (score[skill] === 2) {
        return "Excellent word count!";
      }
    }
    // Grammar skill
  } else if (skill === ENABLING_GRAMMAR) {
    if (uid.includes("WWM")) {
      if (score[skill] === 0) {
        return `Too many mistakes. You made ${score[ENABLING_NUM_GRAMMAR_MISTAKE]} errors. Spend more time to check your writing.`;
      } else if (score[skill] === 1) {
        return `Some grammar mistakes. You made ${score[ENABLING_NUM_GRAMMAR_MISTAKE]} errors. Click the red words in your answer for more details.`;
      } else if (score[skill] === 2) {
        return "Generally consistent grammatical control with only occasional errors. Excellent!";
      }
    } else {
      if (score[skill] === 0) {
        return `Too many mistakes. You made ${score[ENABLING_NUM_GRAMMAR_MISTAKE]} errors. Spend more time to check your writing.`;
      } else if (score[skill] === 1) {
        return `Some grammar mistakes. You made ${score[ENABLING_NUM_GRAMMAR_MISTAKE]} errors. Click the red words in your answer for more details.`;
      } else if (score[skill] === 2) {
        return "No grammar mistakes. Excellent!";
      }
    }
    // Vocabulary skill
  } else if (skill === ENABLING_VOC) {
    if (score[skill] === 0) {
      return "Poor range of vocabulary. Try to get more relevant words.";
    } else if (score[skill] === 1) {
      return "A good range of vocabulary is used.";
    } else if (score[skill] === 2) {
      return "Great vocabulary!";
    }
    // Spelling skill
  } else if (skill === ENABLING_SPELLING) {
    if (uid.includes("WWM")) {
      if (score[skill] === 0) {
        return "Contains numerous spelling/typing errors that may cause undue effort on the part of the reader. Spend more time to check your writing.";
      } else if (score[skill] === 1) {
        return "Contains three or four spelling/typing errors. Click the orange words in your answer for more details.";
      } else if (score[skill] === 2) {
        return "Contains a maximum of two spelling/typing errors.";
      }
    } else {
      if (score[skill] === 0) {
        return `You made ${score[ENABLING_NUM_SPELL_MISTAKE]} spelling mistakes. Spend more time to check your writing.`;
      } else if (score[skill] === 1) {
        return "1 spelling mistake. You lost 1 mark. Click the orange words in your answer for more details.";
      } else if (score[skill] === 2) {
        return "No spelling mistakes. Excellent!";
      }
    }
    // GLR skill
  } else if (skill === ENABLING_GLR) {
    if (score[skill] === 0) {
      return "Use complex sentence structures and vocabulary to express your opinions.";
    } else if (score[skill] === 1) {
      return "Use complex sentence structures and vocabulary to express your opinions.";
    } else if (score[skill] === 2) {
      return "Excellent range of language. Great!";
    }
    // DSC skill
  } else if (skill === ENABLING_DSC) {
    if (score[skill] === 0) {
      return "Use paragraphs and transition words to develop your ideas.";
    } else if (score[skill] === 1) {
      return "Use transition words to link your paragraphs.";
    } else if (score[skill] === 2) {
      return "Clear and logical structure. Well done!";
    }
    // Pronunciation skill
  } else if (skill === ENABLING_PRONUNCIATION) {
    if (score[skill] < 50) {
      return "Low pronunciation score can occur if you speak too fast or too slow, take too many pauses or speak with a heavy accent.";
    } else if (score[skill] < 75) {
      return "Good pronunciation score but few words are unclear. A few consonants in certain contexts may be regularly distorted, omitted or mispronounced. Stress-dependent vowel reduction may occur on a few words.";
    } else if (score[skill] >= 75) {
      return "Great pronunciation!";
    }
    // Fluency skill
  } else if (skill === ENABLING_FLUENCY) {
    if (score[skill] < 50) {
      return "Low fluency score can occur if your speech is too slow and labored with little discernable phrase grouping, multiple hesitations, pauses, false starts, and/or major phonological simplifications.";
    } else if (score[skill] < 75) {
      return "Good fluency score but there are some hesitations, repetitions or false starts.";
    } else if (score[skill] >= 75) {
      return "Great fluency!";
    }
    // Email conventions skill
  } else if (skill === ENABLING_EMAIL_CONV) {
    if (score[skill] === 0) {
      return "Email conventions are limited or missing.";
    } else if (score[skill] === 1) {
      return "Email conventions are used inconsistently with elements missing and/or used ineffectively.";
    } else if (score[skill] === 2) {
      return "Email conventions are obvious, appropriate, and used correctly in keeping with the format of the task.";
    }
    // Organization skill
  } else if (skill === ENABLING_ORGANIZATION) {
    if (score[skill] === 0) {
      return "Organizational structure is missing or not appropriate.";
    } else if (score[skill] === 1) {
      return "Organizational structure is generally acceptable and somewhat clear.";
    } else if (score[skill] === 2) {
      return "Organizational structure is clear and easy to follow.";
    }
  } else {
    return "";
  }
};

// Return max score for skill
const getMaxSkillScore = (skill, uid) => {
  // Content skill
  if (skill === ENABLING_CONTENT) {
    if (uid.includes("WWE") || uid.includes("WWM")) {
      return "3";
    } else if (uid.includes("SSQ")) {
      return "1";
    } else if (
      uid.includes("SRS") ||
      uid.includes("SRA") ||
      uid.includes("SRL") ||
      uid.includes("SDI")
    ) {
      return "90";
    } else {
      return "2";
    }
  } else if (skill === ENABLING_FORM) {
    // Form skill
    if (uid.includes("LST") || uid.includes("WWE") || uid.includes("WWM")) {
      return "2";
    } else {
      return "1";
    }
  } else if (skill === ENABLING_PRONUNCIATION || skill === ENABLING_FLUENCY) {
    // Pronunciation & Fluency skill
    return "90";
  } else {
    // Default
    return "2";
  }
};

// Return max score for task
const getMaxTaskScore = (uid) => {
  if (uid.includes("WWS")) {
    return "7"; // Writing summarize is 7 point max
  } else if (uid.includes("WWE") || uid.includes("WWM")) {
    return "15"; // Writing essay is 15 point max
  } else if (uid.includes("SSQ")) {
    return "1"; // Short question is 1 point max
  } else if (
    // Speking exercises scored out of 90
    uid.includes("SRS") ||
    uid.includes("SRA") ||
    uid.includes("SRL") ||
    uid.includes("SDI")
  ) {
    return "90";
  } else {
    return "10";
  }
};

export {
  getMaxTaskScore,
  getMaxSkillScore,
  getEnablingOfficialGuide,
  getEnablingEnablingComment,
};
