import React, { useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import ReloadIcon from "@material-ui/icons/SettingsBackupRestore";
import { makeStyles } from "@material-ui/core/styles";

import AudioPlayerBar from "./AudioPlayerBar";
import { isSafari } from "../../../helpers/getBrowserData";
import useAudioPlayer from "../../../helpers/useAudioPlayer";

// Audio playback for speech recording
function AudioPlayback({ playerId, ...props }) {
  const classes = useStyles();
  const { blob, isRecording, timerShouldStart, didFinishRecording } =
    props.recorder;
  const { isMockTest, isReviewMode } = props.practice;

  const isDisabled =
    isMockTest ||
    isRecording ||
    isReviewMode ||
    !timerShouldStart ||
    !didFinishRecording ||
    props.openedAlert;

  const blobUrl = blob ? URL.createObjectURL(blob) : null;

  const {
    curTime,
    playing,
    duration,
    playerError,
    setClickedTime,
    setStartPlaying,
    setPausePlaying,
  } = useAudioPlayer(playerId);

  useEffect(() => {
    if (isDisabled) {
      setPausePlaying();
    }
  }, [isDisabled]);

  return (
    <Paper className={classes.player}>
      {!isSafari() ? (
        playing ? (
          <PauseIcon
            className={clsx(classes.icon, {
              [classes.iconDisabled]: isDisabled,
            })}
            onClick={() => setPausePlaying()}
          />
        ) : (
          <PlayIcon
            className={clsx(classes.icon, {
              [classes.iconDisabled]: isDisabled,
            })}
            onClick={isDisabled ? null : () => setStartPlaying()}
          />
        )
      ) : null}

      <audio
        loop={false}
        id={playerId}
        controls={isSafari()}
        style={{ width: "auto" }}
      >
        <source src={isDisabled ? "" : blobUrl} />
        {playerError && "Your browser does not support the audio player."}
      </audio>

      <AudioPlayerBar
        curTime={curTime}
        duration={duration}
        disabled={isDisabled}
        progressBarId={`bar__progress__${playerId}`}
        onTimeUpdate={(time) => setClickedTime(time)}
      />
      <Tooltip
        title={isDisabled ? "" : "Restart audio"}
        placement="bottom"
        enterDelay={200}
      >
        <ReloadIcon
          className={clsx(classes.icon, {
            [classes.iconDisabled]: isDisabled,
          })}
          onClick={() => {
            setClickedTime(0);
            setStartPlaying();
          }}
        />
      </Tooltip>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  player: {
    height: "3em",
    margin: "1em 0",
    marginTop: "0.5rem",
    padding: "0 1em",
    display: "flex",
    width: "100%",
    maxWidth: "300px",
    borderRadius: "2rem",
    alignItems: "center",
    border: "1px solid lightgray",
    justifyContent: "space-between",
    boxShadow: theme.shadow.boxLight,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "15rem",
    },
  },
  icon: {
    color: theme.customColors.blueDarkWhite,
    fontSize: "2rem",
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
      transform: "scale(1.05)",
      cursor: "pointer",
    },
  },
  iconDisabled: {
    color: theme.palette.grey[300],
    "&:hover ": {
      opacity: 1,
      transform: "scale(1)",
      cursor: "no-drop",
    },
  },
}));

const mapStateToProps = (state) => ({
  recorder: state.recorder,
  practice: state.practice,
});

export default connect(mapStateToProps)(AudioPlayback);
