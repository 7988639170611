import { SET_DATE_NOW } from "../helpers/types";

const initialState = {
  dateNow: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DATE_NOW:
      return {
        ...state,
        dateNow: action.payload,
      };

    default:
      return state;
  }
}
