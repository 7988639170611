import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import McqCell from "./components/McqCell";
import AudioPlayer from "./components/AudioPlayer";

import {
  setPracticeScore,
  setPracticeScoreData,
} from "../../actions/questionActions";
import { isAuthenticated } from "../../helpers/userCheck";

// Listening multiple answer exercise
function ExerciseListeningMultiple({ question, didSubmit, ...props }) {
  const classes = useStyles();
  const { track, uid, multichoice, correctIndexes } = question;

  const [selectedAnswer, setSelectedAnswer] = useState([]);

  // Trigger save score
  useEffect(() => {
    if (didSubmit) {
      saveScore();
    }
  }, [didSubmit]);

  // Save score on submit
  function saveScore() {
    const correct = [];
    // Get correct scores
    for (var i in selectedAnswer) {
      if (correctIndexes.includes(selectedAnswer[i])) {
        correct.push(selectedAnswer[i]);
      }
    }

    // Get negative scores
    const negativeMarking = selectedAnswer.length - correct.length;

    // Deduct wrong answers
    const actualScore = correct.length - negativeMarking;

    // Final score
    const score = `${Math.max(actualScore, 0)}/${correctIndexes.length}`;

    props.setPracticeScore({
      uid,
      score,
      successful: actualScore > correctIndexes.length / 2,
      isAuthenticated: isAuthenticated(props.user),
    });

    if (negativeMarking) {
      props.setPracticeScoreData({
        positive: correct.length,
        negative: negativeMarking,
      });
    }
  }

  // Add/remove from selected answers
  function onSelectCell(value) {
    if (selectedAnswer.includes(value)) {
      setSelectedAnswer(selectedAnswer.filter((answer) => answer !== value));
    } else {
      setSelectedAnswer([...selectedAnswer, value]);
    }
  }

  return (
    <Grid container direction="row" className={classes.screen}>
      <TaskBox uid={uid} />
      <AudioPlayer track={track} />
      <Typography className={classes.question}>{question.question}</Typography>
      {multichoice.map((suggestion, i) => {
        return (
          <McqCell
            key={i}
            index={i}
            isMultiple={true}
            didSubmit={didSubmit}
            suggestion={suggestion}
            correctIndex={correctIndexes}
            selectedAnswer={selectedAnswer}
            onSelectCell={(value) => onSelectCell(parseInt(value))}
          />
        );
      })}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flexGrow: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  question: {
    width: "100%",
    margin: "0.5rem 0",
  },
  highlight: {
    color: theme.customColors.blackWhite,
    background: theme.customColors.answerRight,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  setPracticeScore,
  setPracticeScoreData,
})(ExerciseListeningMultiple);
