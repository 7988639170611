import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";

import StopIcon from "@material-ui/icons/Stop";
import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import ReloadIcon from "@material-ui/icons/SettingsBackupRestore";
import { makeStyles } from "@material-ui/core/styles";

import AudioPlayerBar from "./AudioPlayerBar";
import { DESKTOP } from "../../../helpers/types";
import { getAudioFile } from "../../../actions/awsActions";
import useAudioPlayer from "../../../helpers/useAudioPlayer";
import { playerDidFinish } from "../../../actions/recorderActions";
import { isSafari, getBrowserData } from "../../../helpers/getBrowserData";

// Audio player in exercises
function AudioPlayer({
  track,
  shortMargin,
  forceDisabled,
  playerId = "audio__player",
  ...props
}) {
  const classes = useStyles();
  const { didSubmit } = props.practice;
  const { isRecording } = props.recorder;

  const [audioUrl, setAudioUrl] = useState(null);
  const [backupAudioUrl, setBackupAudioUrl] = useState(null);
  const [backupAudioUrl2, setBackupAudioUrl2] = useState(null);

  const isDesktop = getBrowserData()?.platform?.type === DESKTOP;

  const {
    curTime,
    playing,
    duration,
    didFinish,
    playerError,
    playbackRate,
    playerCanPlay,
    setClickedTime,
    setStartPlaying,
    setPausePlaying,
    setPlaybackRate,
  } = useAudioPlayer(playerId);
  const isDisabled =
    isRecording ||
    forceDisabled ||
    (!playerCanPlay && isDesktop) ||
    props.openedAlert;

  // Get signedUrl audio file from AWS s3
  useEffect(() => {
    const getAudioFile = async () => {
      const signedUrl = await props.getAudioFile(track);
      setAudioUrl(signedUrl);
      setBackupAudioUrl(
        `https://pte-success-2019.s3-ap-southeast-2.amazonaws.com/audio-questions/${track}.mp3`
      );
      setBackupAudioUrl2(
        `https://pineapplestudio.com.au/wp-content/uploads/pte-platform/${track}.mp3`
      );
    };
    getAudioFile();
  }, []);

  useEffect(() => {
    if (didFinish) props.playerDidFinish();
  }, [didFinish]);

  useEffect(() => {
    if (isDisabled || didSubmit) {
      setPausePlaying(false);
    }
  }, [isDisabled, didSubmit]);

  return (
    <Paper
      elevation={0}
      className={clsx(classes.player, { [classes.shortMargin]: shortMargin })}
    >
      <StopIcon
        className={clsx(classes.stopIcon, {
          [classes.iconDisabled]: isDisabled,
        })}
        onClick={() => {
          setPausePlaying(false);
          setClickedTime(0);
        }}
      />
      {!isSafari() ? (
        playing ? (
          <PauseIcon
            className={clsx(classes.icon, {
              [classes.iconDisabled]: isDisabled,
            })}
            onClick={
              playing
                ? () => setPausePlaying(false)
                : isDisabled
                ? null
                : () => setStartPlaying(true)
            }
          />
        ) : (
          <PlayIcon
            className={clsx(classes.icon, {
              [classes.iconDisabled]: isDisabled,
            })}
            onClick={
              playing
                ? () => setPausePlaying(false)
                : isDisabled
                ? null
                : () => setStartPlaying(true)
            }
          />
        )
      ) : null}
      <audio
        loop={false}
        id={playerId}
        controls={isSafari()}
        style={{
          width: "auto",
          opacity: isDisabled ? 0.3 : 1,
          pointerEvents: isDisabled ? "none" : "",
        }}
      >
        {/* Main AWS audio url */}
        {audioUrl && <source src={audioUrl} />}
        {/* Fallback audio url */}
        {backupAudioUrl && <source src={backupAudioUrl} />}
        {backupAudioUrl2 && <source src={backupAudioUrl2} />}
        {playerError && "Your browser does not support the audio player."}
      </audio>
      <AudioPlayerBar
        curTime={curTime}
        duration={duration}
        disabled={isDisabled}
        progressBarId={`bar__progress__${playerId}`}
        onTimeUpdate={(time) => setClickedTime(time)}
      />
      <Tooltip title="Restart audio" placement="bottom" enterDelay={200}>
        <ReloadIcon
          className={clsx(classes.stopIcon, {
            [classes.iconDisabled]: isDisabled,
          })}
          onClick={
            isDisabled
              ? null
              : () => {
                  setClickedTime(0);
                  setStartPlaying();
                }
          }
        />
      </Tooltip>
      <Select
        native
        variant="outlined"
        value={playbackRate}
        disabled={isDisabled}
        onChange={(e) => setPlaybackRate(e.target.value)}
        className={classes.playback}
      >
        {["0.8", "0.9", "1", "1.1", "1.2"].map((val, i) => {
          return <option key={i} value={val}>{`x${val}`}</option>;
        })}
      </Select>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  player: {
    flexGrow: 1,
    height: "3rem",
    display: "flex",
    margin: "1rem 0",
    padding: "0 1rem",
    maxWidth: "600px",
    alignItems: "center",
    border: "1px solid lightgray",
    justifyContent: "space-between",
    boxShadow: theme.palette.type === "dark" ? null : theme.shadow.boxLight,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      maxWidth: "100%",
    },
  },
  shortMargin: {
    marginTop: 0,
  },
  icon: {
    color: theme.customColors.blue,
    fontSize: "2.2rem",
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
      transform: "scale(1.05)",
      cursor: "pointer",
    },
  },
  stopIcon: {
    color: theme.customColors.blueDarkWhite,
    fontSize: "2.2rem",
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
      transform: "scale(1.05)",
      cursor: "pointer",
    },
  },
  iconDisabled: {
    color: theme.palette.grey[300],
    "&:hover ": {
      opacity: 1,
      transform: "scale(1)",
      cursor: "no-drop",
    },
  },
  playback: {
    height: "1.7rem",
    marginLeft: "0.5rem",
    display: "flex",
    fontSize: "0.8rem",
    minWidth: "3.5rem",
    background: theme.customColors.whiteBlack,
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSelect-select": {
      padding: "6px",
      minWidth: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 0.5rem",
    },
  },
}));

const mapStateToProps = (state) => ({
  recorder: state.recorder,
  practice: state.practice,
});

export default connect(mapStateToProps, { getAudioFile, playerDidFinish })(
  AudioPlayer
);
