import {
  START_RETRIEVING_USER,
  FINISH_RETRIEVING_USER,
} from "../helpers/types";

const initialState = false;

export default function (state = initialState, action) {
  switch (action.type) {
    case START_RETRIEVING_USER:
      return true;

    case FINISH_RETRIEVING_USER:
      return false;

    default:
      return state;
  }
}
