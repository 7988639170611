import axios from "axios";

import { SET_USER } from "../helpers/types";

import { updateUser } from "./userActions";
import { handleErrors } from "./errorActions";
import { startLoadingSpinner, stopLoadingSpinner } from "./loadingActions";

// @desc  Get audio file from AWS S3
// @next  Return url storage link
export const getAudioFile = (track) => () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/aws/getAudioFile/${track}`)
      .then((res) => {
        if (res.status === 208) {
          resolve(res.data);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => reject(err));
  });
};

// @desc  Get Pdf file from AWS S3
// @next  Return url storage link
export const getPdfFile = (file) => () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/aws/getPdfFile/${file}`)
      .then((res) => {
        if (res.status === 208) {
          resolve(res.data);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => reject(err));
  });
};

// @desc  Get Image file from AWS S3
// @next  Return url storage link
export const getImageFile = (file) => () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/aws/getImageFile/${file}`)
      .then((res) => {
        if (res.status === 208) {
          resolve(res.data);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => reject(err));
  });
};

// @desc  Upload scorecard to AWS S3
// @next  Return url storage link
export const uploadScorecardToS3 = (file) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/aws/uploadScorecardToS3", file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => resolve(res.data))
      .finally(dispatch(stopLoadingSpinner()))
      .catch((err) => reject(err));
  });
};

// @desc  Upload profile picture to AWS S3
// @next  Load user
export const uploadProfileImage = (file) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/aws/uploadProfileImage", file, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch({ type: SET_USER, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Generate screenshot and upload it to AWS S3
// @next  Return url storage link
export const takePteScorecardScreenshot = (data) => (dispatch) => {
  axios
    .post("/api/aws/takePteScorecardScreenshot", data)
    .then((res) => {
      dispatch(updateUser({}));
    })
    .catch((error) => dispatch(handleErrors(error)));
};
