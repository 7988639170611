import React, { useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { HAS_CLOSED_WARNING_2 } from "../../../helpers/types";
import { saveToLocalStorage } from "../../../helpers/saveToLocalStorage";

function WarningMobile() {
  const classes = useStyles();

  const [close, setClose] = useState(localStorage[HAS_CLOSED_WARNING_2]);

  // Close banner and remember choice
  function closeMobileAppBanner() {
    saveToLocalStorage(HAS_CLOSED_WARNING_2, "true");
    setClose(true);
  }

  if (close) return null;

  return (
    <div className={classes.box}>
      <CloseIcon
        className={classes.closeIcon}
        onClick={() => closeMobileAppBanner()}
      />
      <Typography style={{ fontWeight: "bold" }}>
        Note from PTE Success
      </Typography>
      <Typography>
        • Consider using the{" "}
        <span style={{ fontWeight: "bold", color: "#3665f3" }}>
          PTE Success mobile app
        </span>{" "}
        for a better experience.
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    width: "100%",
    margin: "0 1rem",
    padding: "0.5rem",
    borderRadius: "5px",
    position: "relative",
    background: "#fff1f1",
    border: "1px solid #DA3025",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  closeIcon: {
    top: "0.2rem",
    right: "0.5rem",
    padding: "0.2rem",
    position: "absolute",
    color: theme.palette.grey[800],
    "&:hover ": {
      cursor: "pointer",
      transform: "scale(1.01)",
      opacity: theme.customVars.hoverOpacity,
    },
  },
}));

export default WarningMobile;
