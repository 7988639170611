import React, { useState } from "react";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import { isNotChrome } from "../../../helpers/getBrowserData";
import { getExerciseSettings } from "../../../helpers/getExerciseData";
import { saveToSessionStorage } from "../../../helpers/saveToLocalStorage";
import { CHROME_WARNING, LISTENING, SPEAKING } from "../../../helpers/types";

function TaskBox({ uid }) {
  const classes = useStyles();

  const [showWarning, setShowWarning] = useState(
    !sessionStorage[CHROME_WARNING]
  );

  const { task, section, noMargin } = getExerciseSettings(uid);

  const isSpeaking = section === SPEAKING;
  const isListening = section === LISTENING;

  return (
    <>
      {/* Yellow notes */}
      {isNotChrome() && showWarning && (isSpeaking || isListening) && (
        <div className={classes.note}>
          <Typography style={{ fontWeight: "bold" }}>
            Note from PTE Success
          </Typography>
          {isSpeaking && (
            <Typography>
              We recommend using{" "}
              <span style={{ fontWeight: "bold" }}>Chrome</span> browser for the
              best compatibility with the microphone and audio.
            </Typography>
          )}
          {isListening && (
            <Typography>
              We recommend using{" "}
              <span style={{ fontWeight: "bold" }}>Chrome</span> browser for the
              best compatibility with the audio.
            </Typography>
          )}
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              saveToSessionStorage(CHROME_WARNING, "true");
              setShowWarning(false);
            }}
          />
        </div>
      )}
      <Typography
        className={clsx(classes.task, {
          [classes.noMargin]: noMargin,
        })}
      >
        {task}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  task: {
    width: "100%",
    fontWeight: "600",
    maxWidth: "1080px",
    fontStyle: "italic",
    whiteSpace: "pre-line",
    [theme.breakpoints.down("xs")]: {
      marginTop: "3rem",
    },
  },
  noMargin: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  note: {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "5px",
    position: "relative",
    marginBottom: "2rem",
    background: "#fffbe6",
    border: "1px solid #ffe58f",
  },
  closeIcon: {
    top: "0.2rem",
    right: "0.5rem",
    padding: "0.2rem",
    position: "absolute",
    color: theme.palette.grey[800],
    "&:hover ": {
      cursor: "pointer",
      transform: "scale(1.01)",
      opacity: theme.customVars.hoverOpacity,
    },
  },
}));

export default TaskBox;
