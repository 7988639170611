import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Rating from "@material-ui/lab/Rating";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import {
  ROUTE_HELP,
  ROUTE_REWARD,
  ROUTE_SURVEY,
  ROUTE_DASHBOARD,
  ROUTE_MOBILE_APP,
  ROUTE_CONTACT_US,
  HAS_CLOSED_BANNER,
  ROUTE_MOBILE_APP_IOS,
  ROUTE_MOBILE_APP_ANDROID,
} from "../../helpers/types";
import getPlatformLayout from "../../helpers/getPlatformLayout";
import { saveToLocalStorage } from "../../helpers/saveToLocalStorage";
import getMobileOperatingSystem from "../../helpers/getMobileOperatingSystem";

// List of URL to hide banner => Opposite of trigger TawkTo
const triggerUrl = [
  ROUTE_HELP,
  ROUTE_REWARD,
  ROUTE_SURVEY,
  ROUTE_DASHBOARD,
  ROUTE_CONTACT_US,
  ROUTE_MOBILE_APP,
  ROUTE_MOBILE_APP_IOS,
  ROUTE_MOBILE_APP_ANDROID,
];

// Custom mobile app banner
function MobileAppBanner() {
  const classes = useStyles();
  const [open, setOpen] = useState(!localStorage[HAS_CLOSED_BANNER]);

  // Hide on these urls
  useEffect(() => {
    if (triggerUrl.includes(window.location.pathname)) {
      setOpen(false);
    } else if (!localStorage[HAS_CLOSED_BANNER]) {
      setOpen(true);
    }
  }, [window.location.pathname]);

  // Close banner and remember choice
  function closeMobileAppBanner() {
    saveToLocalStorage(HAS_CLOSED_BANNER, "true");
    setOpen(false);
  }

  return (
    <Slide in={open} mountOnEnter unmountOnExit timeout={1000} direction="up">
      <Grid className={classes.wrapper}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => closeMobileAppBanner()}
        />
        <img
          className={classes.logo}
          src={getPlatformLayout("logo")}
          alt="pte success - mobile app banner"
        />

        <div style={{ flexGrow: 1 }}>
          <Typography className={classes.title}>
            {`PTE Success for ${getMobileOperatingSystem()}`}
          </Typography>
          <div className={classes.rating}>
            <Rating
              readOnly
              value={4.7}
              precision={0.1}
              name="read-only"
              style={{ fontSize: "1.5em" }}
            />
          </div>
        </div>
        <div className={classes.installWrapper}>
          <a
            rel="noopener"
            target="_blank"
            style={{ textDecoration: "none" }}
            href="http://onelink.to/pte_success"
          >
            <Typography className={classes.install}>Install</Typography>
          </a>
        </div>
      </Grid>
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    left: 0,
    bottom: 0,
    zIndex: 2000,
    width: "100%",
    display: "flex",
    position: "fixed",
    padding: "0.5rem 0",
    alignItems: "center",
    boxShadow: theme.shadow.boxLight,
    background: theme.palette.background.paper,
    borderTop: `solid 1px ${theme.palette.grey[200]}`,
  },
  closeIcon: {
    padding: "0.2em",
    color: theme.palette.grey[600],
    "&:hover ": {
      cursor: "pointer",
      transform: "scale(1.01)",
      opacity: theme.customVars.hoverOpacity,
    },
  },
  logo: {
    width: "2.5rem",
    height: "2.5rem",
    marginRight: "8px",
  },
  icon: {
    display: "flex",
    fontSize: "2em",
    padding: "0.3em",
    borderRadius: "5em",
    marginRight: "0.5em",
    color: theme.customColors.red,
    background: theme.customColors.redLight,
    [theme.breakpoints.down("sm")]: {
      marginRight: "0.5em",
    },
  },
  title: {
    fontSize: "1rem",
    fontWeight: "bold",
    color:
      theme.palette.type === "dark"
        ? theme.palette.grey[200]
        : theme.palette.grey[600],
  },
  rating: {
    display: "flex",
    alignItems: "center",
  },
  installWrapper: {
    display: "flex",
    placeContent: "center",
  },
  install: {
    width: "auto",
    color: "white",
    fontSize: "1.2rem",
    cursor: "pointer",
    fontWeight: "bold",
    borderRadius: "5px",
    marginRight: "0.5rem",
    padding: "0.2rem 1rem",
    background: theme.customColors.blue,
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
}));

export default MobileAppBanner;
