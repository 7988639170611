import axios from "axios";
import { handleErrors } from "./errorActions";
import { SET_DATE_NOW } from "../helpers/types";

// @desc  Fetch date now from server
// @next  Load date now to redux
export const getDateNow = () => (dispatch) => {
  axios
    .get("/api/settings/getDateNow")
    .then((res) => {
      dispatch({ type: SET_DATE_NOW, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};
