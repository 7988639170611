import axios from "axios";

import { stopLoadingSpinner, startLoadingSpinner } from "./loadingActions";
import { handleErrors, clearErrors } from "./errorActions";
import { saveMockTestQuestion } from "./mockTestActions";
import { clearRecorderReducer } from "./recorderActions";
import { clearAnalysisScore } from "./analysisActions";
import { navigateTo } from "./navigationActions";
import { updateUser } from "./userActions";

import {
  SET_PRACTICE,
  GET_QUESTIONS,
  ROUTE_EXERCISE,
  CLEAR_PRACTICE,
  SET_DID_SUBMIT,
  SET_SHOW_ANSWER,
  SET_PRACTICE_SCORE,
  CLEAR_FOR_NEXT_QUESTION,
  SET_PRACTICE_SCORE_DATA,
  GET_PREDICTED_QUESTIONS,
} from "../helpers/types";

// @desc  Get questions for a section
// @next  Load questions to redux
export const fetchQuestions = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/questions/fetchQuestions", data)
    .then((res) => {
      dispatch(stopLoadingSpinner());
      dispatch({ type: GET_QUESTIONS, payload: res.data });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Get questions to practice
// @next  Load practice questions to redux
export const setPracticeQuestions = (data) => (dispatch) => {
  dispatch({ type: SET_PRACTICE, payload: data });
  dispatch(navigateTo(ROUTE_EXERCISE));
};

// @desc  Get question for an uid
// @next  Return question to practice
export const fetchQuestion = (uid) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/questions/fetchQuestion", uid)
      .then((res) => {
        dispatch(clearRecorderReducer());
        dispatch(clearForNextQuestion());
        dispatch(clearAnalysisScore());
        dispatch(clearErrors());
        resolve(res.data);
      })
      .finally(dispatch(stopLoadingSpinner()))
      .catch((err) => reject(err));
  });
};

// @desc  Get predicted questions (flagged as tested within 30 days)
// @next  Load questions to redux
export const fetchPredictedQuestions = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/questions/fetchPredictedQuestions", data)
    .then((res) => {
      const uids = Array.from(
        new Set(res.data.map((question) => question.uid))
      );
      dispatch(stopLoadingSpinner());
      dispatch({ type: GET_PREDICTED_QUESTIONS, payload: uids });
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Save question score to Score database
// @next  Refresh user
export const saveQuestionDone = (data) => (dispatch) => {
  axios
    .post("/api/questions/saveQuestionDone", data)
    .then((res) => {
      dispatch(updateUser({}));
    })
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Trigger didSubmit in exercise
export const setDidSubmit = (data) => (dispatch) => {
  dispatch({ type: SET_DID_SUBMIT, payload: data });
};

// @desc  Save practice score
export const setPracticeScore = (data) => (dispatch) => {
  dispatch({ type: SET_PRACTICE_SCORE, payload: data.score });

  if (!data.isReviewMode) {
    if (data.isMockTest) {
      dispatch(clearAnalysisScore());
      dispatch(clearRecorderReducer());
      dispatch(clearForNextQuestion());
      dispatch(saveMockTestQuestion(data));
    } else if (data.isAuthenticated) {
      dispatch(saveQuestionDone(data));
    }
  }
};

// @desc  Save practice score data (explain negative)
export const setPracticeScoreData = (data) => (dispatch) => {
  dispatch({ type: SET_PRACTICE_SCORE_DATA, payload: data });
};

// @desc  Show answer button
export const setShowAnswer = (data) => (dispatch) => {
  dispatch({ type: SET_SHOW_ANSWER, payload: data });
};

// @desc  Clear part reducer for next question
export const clearForNextQuestion = () => (dispatch) => {
  dispatch({ type: CLEAR_FOR_NEXT_QUESTION });
};

// @desc  Clear practice reducer
export const clearPractice = () => (dispatch) => {
  dispatch({ type: CLEAR_PRACTICE });
};

// @desc  Mark question as tested
export const markQuestionAsTested = (data) => (dispatch) => {
  axios
    .post("/api/questions/markQuestionAsTested", data)
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Report/flag question
export const reportQuestion = (data) => (dispatch) => {
  axios
    .post("/api/questions/reportQuestion", data)
    .catch((error) => dispatch(handleErrors(error)));
};

// @desc  Clear scores for exercise
// @next  Refresh user
export const clearExerciseScore = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  axios
    .post("/api/questions/clearExerciseScore", data)
    .then((res) => {
      dispatch(updateUser({}));
      dispatch(stopLoadingSpinner());
    })
    .catch((error) => dispatch(handleErrors(error)));
};
