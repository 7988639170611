import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import {
  ROUTE_HOME,
  ROUTE_BLOG,
  ROUTE_HELP,
  ROUTE_LOGIN,
  ROUTE_TERMS,
  ROUTE_SIGNUP,
  ROUTE_PRIVACY,
  ROUTE_PRICING,
  ROUTE_PRACTICE,
  ROUTE_MOCK_TEST,
  ROUTE_MOBILE_APP,
  ROUTE_TESTIMONIALS,
  ROUTE_MOBILE_APP_IOS,
  ROUTE_FREE_READ_ALOUD,
  ROUTE_FREE_SHORT_ANSWER,
  ROUTE_MOBILE_APP_ANDROID,
  ROUTE_MOCK_TEST_SCORECARD,
  ROUTE_FREE_WRITE_DICTATION,
  ROUTE_FREE_REPEAT_SENTENCE,
} from "../../helpers/types";

import { getBlogContent } from "../../actions/blogActions";
import { getLandingContent } from "../../actions/landingActions";

//Display dynamic Metadata for a page
function MetadataManager(props) {
  const { pathname } = props.location;
  const [metadata, setMetadata] = useState(null);

  // Fetch metadata if blog or routes
  useEffect(() => {
    if (pathname.includes("/blog/")) {
      getBlogContent();
    } else if (pathname.includes("/academic/")) {
      getLandingContent();
    } else if (pathname.includes(ROUTE_MOCK_TEST_SCORECARD)) {
      getScorecardContent();
    } else {
      displayMetadata();
    }
  }, [pathname]);

  // Get blog metadata
  async function getBlogContent() {
    // Get slug from pathname
    const slug = pathname.replace("/blog/", "");
    const data = await props.getBlogContent({ slug });
    if (data && data.metadata) {
      setMetadata({
        url: data.url,
        image: data.image,
        title: data.metadata.title,
        description: data.metadata.description,
      });
    }
  }

  // Get blog metadata
  async function getLandingContent() {
    // Get slug from pathname
    const slug = pathname.replace("/academic/", "");
    const data = await props.getLandingContent(slug);
    if (data && data.metadata) {
      setMetadata({
        url: data.metadata.url,
        image: data.metadata.image,
        title: data.metadata.seoTitle,
        description: data.metadata.description,
      });
    }
  }

  // Get scorecard metadata
  function getScorecardContent() {
    // Get slug from pathname
    const slug = pathname.replace("/mock-test/scorecard/", "");
    setMetadata({
      title: "Get Your Mock Test Scorecard With PTE Success",
      description:
        "Take a FREE scored mock test with PTE Success when you register an account. Crack the PTE Exam on your first attempt!",
      image: `https://pte-success-2019.s3.ap-southeast-2.amazonaws.com/scorecards/${
        slug || ""
      }.png`,
      url: `https://www.ptesuccess.com.au${ROUTE_MOCK_TEST_SCORECARD}/${
        slug || ""
      }`,
    });
  }

  // Get route metadata
  function displayMetadata() {
    const data = getMetadata();
    setMetadata(data);
  }

  //Return route metadata based on pathname
  function getMetadata() {
    switch (pathname) {
      case ROUTE_HOME:
        return {
          title:
            "The Best PTE Study Tool For A Real PTE Preparation ❤️ | PTE Success",
          description:
            "PTE Success is a fantastic tool that boosts your score to 90! Start using our PTE software right now! No account needed - Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_HOME}`,
        };
      case ROUTE_SIGNUP:
        return {
          title: "Create An Account | PTE Success",
          description:
            "Get direct access to 100+ Free PTE practice questions, PTE study tips and more. Sign up here.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_SIGNUP}`,
        };
      case ROUTE_LOGIN:
        return {
          title: "Log In To Your Account | PTE Success",
          description:
            "PTE Login. Get The Latest PTE Exam questions! Improve Your Score Now With 1000+ Repeated Questions. Login here.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_LOGIN}`,
        };
      case ROUTE_BLOG:
        return {
          title: "Blog | PTE Success",
          description: "Find and read over 40 articles to improve your score.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_BLOG}`,
        };
      case ROUTE_TESTIMONIALS:
        return {
          title: "Review What 550+ Users Have To Say 😍",
          description:
            "User review. What do our users love about us? You can read over 550 reviews to discover what is good about PTE Success.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_TESTIMONIALS}`,
        };
      case ROUTE_HELP:
        return {
          title:
            "Need Help With PTE Exam? 🤔 30+ Questions Answered | PTE Success",
          description:
            "Don't invest a cent in PTE before at least knowing this! We've answered all the most commonly asked questions to get you on the right path.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_HELP}`,
        };
      case ROUTE_PRIVACY:
        return {
          title: "Privacy Policy | PTE Success",
          description:
            "PTE Success is a secured and safe Pearson Test of English academic platform. Check our privacy policy for more information on how your data is handled.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_PRIVACY}`,
        };
      case ROUTE_TERMS:
        return {
          title: "Terms and Conditions | PTE Success",
          description:
            "PTE Success is a secured and safe PTE platform. Check our privacy policy for more information regarding the treatment of your data.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_TERMS}`,
        };
      case ROUTE_FREE_SHORT_ANSWER:
        return {
          title: "Tips PTE Short Answer Question | Free Questions + Answer",
          description:
            "Everything you need to know to pass PTE Short Answer. Practice our free questions and get access to the answers.| Sign Up to get a free reading mock test!",
          image:
            "https://pineapplestudio.com.au/wp-content/uploads/pte-platform/images/short-answer-question-practice.jpg",
          url: `https://www.ptesuccess.com.au${ROUTE_FREE_SHORT_ANSWER}`,
        };
      case ROUTE_FREE_WRITE_DICTATION:
        return {
          title: "PTE Write From Dictation Tips | Free Questions + Answers",
          description:
            "Practice our free write from dictation questions and get access to the answers.| Sign Up to get a free reading mock test!",
          image:
            "https://pineapplestudio.com.au/wp-content/uploads/pte-platform/images/write-from-dictation-question-practice.jpg",
          url: `https://www.ptesuccess.com.au${ROUTE_FREE_WRITE_DICTATION}`,
        };
      case ROUTE_FREE_READ_ALOUD:
        return {
          title: "PTE Read Aloud Tips | Free Sample Questions + Answers",
          description:
            "PTE Reading - Practice our Free PTE Read Aloud Questions and get access to answers. Sign Up today and get a FREE reading mock test!",
          image:
            "https://pineapplestudio.com.au/wp-content/uploads/pte-platform/images/read-aloud-exercise.jpg",
          url: `https://www.ptesuccess.com.au${ROUTE_FREE_READ_ALOUD}`,
        };
      case ROUTE_FREE_REPEAT_SENTENCE:
        return {
          title: "PTE Repeat Sentence | Free Questions + Answers",
          description:
            "Practice our Free PTE Repeat Sentence questions and get access to the answers.| Sign Up to get a FREE reading mock test!",
          image:
            "https://pineapplestudio.com.au/wp-content/uploads/pte-platform/images/repeat-sentence-question-practice.jpg",
          url: `https://www.ptesuccess.com.au${ROUTE_FREE_REPEAT_SENTENCE}`,
        };
      case ROUTE_PRACTICE:
        return {
          title: "Free Practice | PTE Success",
          description:
            "A direct access to your free practice. PTE Success contains more than 2600 exam questions and 32 scored mock tests.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/layout/blogImages/pteListening/pte-highlight-incorrect-word-exercise.png",
          url: `https://www.ptesuccess.com.au${ROUTE_PRACTICE}`,
        };

      case ROUTE_MOCK_TEST:
        return {
          title: "Scored Mock Tests | PTE Success",
          description:
            "Get instant results and feedback with one of our 32 scored mock tests. Low price, starts from only AUD $15. Try one now.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/layout/blogImages/pteListening/pte-highlight-incorrect-word-exercise.png",
          url: `https://www.ptesuccess.com.au${ROUTE_PRACTICE}`,
        };

      case ROUTE_PRICING:
        return {
          title:
            "⭐ Buy PTE Mock Test and PTE Preparation Kit to Boost Your Practice | PTE Success",
          description:
            "Choose from our Ultimate, Silver or Bronze packages to familiarise you with the format of PTE Academic. All our plans include PTE AI Scoring, Prediction Questions, Speech and Essay Analysis and other PTE tools.",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_PRICING}`,
        };

      case ROUTE_MOBILE_APP:
        return {
          title: "Download The Best PTE Preparation App | PTE Success",
          description:
            "Get the PTE Success mobile app to practice prediction questions with Ai scoring. This is a fantastic tool that boosts your score to 90! Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_MOBILE_APP}`,
        };

      case ROUTE_MOBILE_APP_IOS:
        return {
          title: "Download The Best PTE Preparation App on iOS | PTE Success",
          description:
            "Get the PTE Success iOS mobile app to practice prediction questions with Ai scoring. This is a fantastic tool that boosts your score to 90! Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_MOBILE_APP_IOS}`,
        };

      case ROUTE_MOBILE_APP_ANDROID:
        return {
          title:
            "Download The Best PTE Preparation App on Android | PTE Success",
          description:
            "Get the PTE Success Android mobile app to practice prediction questions with Ai scoring. This is a fantastic tool that boosts your score to 90! Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: `https://www.ptesuccess.com.au${ROUTE_MOBILE_APP_ANDROID}`,
        };

      default:
        return {
          title:
            "The Best PTE Study Tool For A Real PTE Preparation ❤️ | PTE Success",
          description:
            "PTE Success is a fantastic tool that boosts your score to 90! Start using our PTE software right now! No account needed - Get 100+ FREE repeated questions",
          image:
            "https://pineapplestudio.com.au/pte_success_v2/images/affiliate/pte_success_fb_banner_2.png",
          url: "https://www.ptesuccess.com.au",
        };
    }
  }

  if (!metadata) return null;
  const { title, description, image, url } = metadata;

  // Main return function
  return (
    <Helmet>
      {/* General */}
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Facebook */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="PTE Success" />
      <meta property="og:description" content={description} />

      {/* Twitter */}
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default connect(null, {
  getBlogContent,
  getLandingContent,
})(withRouter(MetadataManager));
