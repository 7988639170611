import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import SpinnerLoading from "./SpinnerLoading";
import SpinnerSuspense from "./SpinnerSuspense";

import { ROUTE_LOGIN } from "../../helpers/types";
import { useInterval } from "../../helpers/customHook";
import { isAuthenticated } from "../../helpers/userCheck";

// Protected route for authenticated user only
function PrivateRoute({ component: Component, user, ...props }) {
  const [timeLeft, setTimeLeft] = useState(3);

  // Try to wait 3 seconds for authentification. If authentification comes, reset timer
  useInterval(
    () => {
      setTimeLeft(timeLeft - 1);
    },
    isAuthenticated(user) ? null : 1000
  );

  // Try to wait 3 seconds for authentification to load the Private route
  if (!isAuthenticated(user) && timeLeft > 0)
    return props.loading ? <SpinnerLoading /> : <SpinnerSuspense />; // Prevent showing double loading with Suspense

  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated(user) ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTE_LOGIN} />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.loading,
});

export default connect(mapStateToProps)(PrivateRoute);
