import {
  BRONZE,
  SILVER,
  ULTIMATE,
  HAS_CPC_OFFER,
  ULTIMATE_DISCOUNT,
} from "./types";
import { isReferredUser } from "./userCheck";

// Return promo details for cpc retarget sales
const getCpcPromo = (cpc = null, user = {}) => {
  if (cpc || localStorage[HAS_CPC_OFFER]) {
    return {
      packs: [BRONZE, SILVER, ULTIMATE_DISCOUNT],
      startPromo: parseInt(localStorage[HAS_CPC_OFFER]) || 0,
      target: [ULTIMATE_DISCOUNT],
      discount: 30,
    };
  } else if (isReferredUser(user)) {
    return {
      packs: [BRONZE, SILVER, ULTIMATE_DISCOUNT],
      startPromo: parseInt(localStorage[HAS_CPC_OFFER]) || 0,
      target: [ULTIMATE_DISCOUNT],
      discount: 30,
    };
  } else {
    return {
      packs: [BRONZE, SILVER, ULTIMATE],
      startPromo: 0,
      target: [],
      discount: 0,
    };
  }
};

export default getCpcPromo;
