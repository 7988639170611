import React from "react";
import { connect } from "react-redux";
import { updateUserArray } from "../../actions/userActions";

import "../../css/pulse.css";
import { doesUserHave, isAuthenticated } from "../../helpers/userCheck";

// Add pulse animation to component
function PulseComponent({
  component: Component,
  preventPulse = false,
  updateUserArray,
  trigger,
  user,
  ...props
}) {
  return doesUserHave(user, trigger) || preventPulse ? (
    <Component {...props} />
  ) : (
    <>
      <Component {...props} />
      <div
        className="pulse__icon__container"
        onClick={
          isAuthenticated(user)
            ? () => updateUserArray({ stepsDone: trigger })
            : () => null
        }
      >
        <span className="pulse__icon" />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { updateUserArray })(PulseComponent);
