import React from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

import { makeStyles } from "@material-ui/core/styles";

// Progress bar inside audio player
function AudioPlayerBar({
  duration,
  curTime,
  onTimeUpdate,
  disabled,
  progressBarId = "bar__progress",
}) {
  const classes = useStyles();

  const curPercentage = (curTime / duration) * 100;

  // Readable time
  function formatDuration(duration) {
    return moment
      .duration(duration, "seconds")
      .format("mm:ss", { trim: false });
  }

  // Calculate time on click audio bar
  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = document.getElementById(progressBarId);
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  // Get time on click audio bar
  function handleTimeDrag(e) {
    if (disabled) return;

    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener("mousemove", updateTimeOnMove);

    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", updateTimeOnMove);
    });
  }

  return (
    <div className={classes.bar}>
      <span className={classes.time}>{formatDuration(curTime)}</span>
      <div
        id={progressBarId}
        className={classes.progress}
        style={{
          cursor: "pointer",
          background: `linear-gradient(to right, #556080 ${curPercentage}%, #eeeeee 0)`,
        }}
        onMouseDown={(e) => handleTimeDrag(e)}
      >
        <span
          className={classes.knob}
          style={{ left: `${curPercentage - 2}%` }}
        />
      </div>

      <span className={classes.time}>{formatDuration(duration)}</span>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  bar: {
    userSelect: "none",
    padding: "0 1rem",
    width: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem",
    },
  },
  progress: {
    flex: 1,
    borderRadius: "5px",
    margin: "0 20px",
    height: "10px",
    display: "flex",
    alignItems: "center",
    background: theme.customColors.background,
    [theme.breakpoints.down("xs")]: {
      margin: "0 0.5rem",
    },
  },
  knob: {
    position: "relative",
    height: "16px",
    width: "16px",
    border: "1.5px solid white",
    borderRadius: "50%",
    backgroundColor: theme.customColors.blueDark,
  },
  time: {
    fontSize: "0.8em",
    color: theme.palette.grey[500],
  },
}));

export default AudioPlayerBar;
