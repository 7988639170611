import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  ROUTE_BLOG,
  ROUTE_USER,
  ROUTE_HELP,
  ROUTE_PRACTICE,
  ROUTE_DASHBOARD,
  ROUTE_MOCK_TEST,
  ROUTE_CONTACT_US,
  ROUTE_MOCK_TEST_RESULT,
  ROUTE_MOCK_TEST_SCORECARD,
} from "../../helpers/types";
import isEmpty from "../../helpers/isEmpty";

// List of URL to trigger TawkTo
const triggerUrl = [ROUTE_HELP, ROUTE_USER, ROUTE_CONTACT_US];

const hideUrl = [
  ROUTE_BLOG,
  ROUTE_PRACTICE,
  ROUTE_MOCK_TEST,
  ROUTE_DASHBOARD,
  ROUTE_MOCK_TEST_RESULT,
  ROUTE_MOCK_TEST_SCORECARD,
];

function TawkToManager() {
  useEffect(() => {
    const hideBranding = () => {
      // Find widget
      const containerDiv = document.querySelector(".widget-visible");
      if (!containerDiv) return;

      // Multples iframe in widget
      const iframes = containerDiv.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const brandingElement = iframeDocument.querySelector(".tawk-branding");
        // Find branding
        if (
          brandingElement &&
          !iframeDocument.querySelector("style[data-tawk-branding]") // Prevent saving multiple times
        ) {
          // Hide branding by injecting CSS
          const customCSS = `.tawk-branding {display: none !important;}`;
          const styleElement = iframeDocument.createElement("style");
          styleElement.setAttribute("data-tawk-branding", "");
          styleElement.textContent = customCSS;
          iframeDocument.head.appendChild(styleElement);
        }
      });
    };

    const timeoutId = setTimeout(() => {
      hideBranding(); // Hide branding when chat is immediately open
    }, 5000); // 5 second delay on initial loading

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (isEmpty(window?.Tawk_API)) return;

    if (triggerUrl.includes(window.location.pathname)) {
      window?.Tawk_API?.showWidget(); // Display chat icon on some URLs
    }
    if (hideUrl.includes(window.location.pathname)) {
      window?.Tawk_API?.hideWidget(); // Hide chat icon on some URLs
    }

    hideBranding();
  }, [window.location.pathname]);

  // Hide branding (1 sec delay)
  function hideBranding() {
    setTimeout(() => {
      // Find widget
      const containerDiv = document.querySelector(".widget-visible");
      if (!containerDiv) return;

      // Multples iframe in widget
      const iframes = containerDiv.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const brandingElement = iframeDocument.querySelector(".tawk-branding");
        // Find branding
        if (
          brandingElement &&
          !iframeDocument.querySelector("style[data-tawk-branding]") // Prevent saving multiple times
        ) {
          // Hide branding by injecting CSS
          const customCSS = `.tawk-branding {display: none !important;}`;
          const styleElement = iframeDocument.createElement("style");
          styleElement.setAttribute("data-tawk-branding", "");
          styleElement.textContent = customCSS;
          iframeDocument.head.appendChild(styleElement);
        }
      });
    }, 1000);
  }

  return (
    <div>
      {/* hidden link to trigger from Contact Us page */}
      <a
        id="tawk_to_chat_maximize"
        href="javascript:void(Tawk_API?.maximize())"
        onClick={hideBranding}
      />
    </div>
  );
}

export default connect(null)(withRouter(TawkToManager));
