import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import TextFieldGroup from "../common/TextFieldGroup";
import AlertSkillAnalysisWriting from "../alerts/AlertSkillAnalysisWriting";
import AlertSkillAnalysisPreviousWriting from "../alerts/AlertSkillAnalysisPreviousWriting";

import {
  isPaidStudent,
  isAuthenticated,
  canSubmitWriting,
} from "../../helpers/userCheck";
import {
  setAnalysis,
  getUserWritings,
  saveUserWriting,
  analyseWritingText,
} from "../../actions/analysisActions";
import { clearErrors } from "../../actions/errorActions";
import { setPracticeScore } from "../../actions/questionActions";

import isEmpty from "../../helpers/isEmpty";
import { stableSort, getSorting } from "../../helpers/tableListHelper";
import { getScoreForWritingEssay } from "../../helpers/getScoreForAnalysis";

// Writing essay exercise
function ExerciseWritingEssay({ question, didSubmit, ...props }) {
  const classes = useStyles();

  const { user, errors } = props;
  const { topic, answer, uid } = question;
  const { analysis, score } = props.analysis;

  const [selectedAnswer, setSelectedAnswer] = useState(
    ""
    // 'An important point to remember here is that these opinions were voiced when computers were in their infancy, and now, with the passage of time, we should have a clearer picture. It is not simply a case of either/ or.\n\nThese days most of us find computers indispensable, whether at work or at home, and they do speed up certain daily routines - for example, doing your shopping on-line.\n\nOn the other hand, a lot of time is wasted on such things as playing games and unnecessary personal communications - this time could be better spent. On a larger scale, in terms of big business and industry, computerized manufacturing and automated assembly lines have greatly increased production.\n\nIt is claimed that playing computer games improves certain skills: this may be so, but there is no doubt that spending excessive amounts of time alone at the computer can damage your health and relationships, as well as take time away from other beneficial activities such as reading, socializing, playing sports, and so on.\n\nHowever, there is no denying that using computers does make at least some people happy. While I find it hard to give a definite opinion on whether or not computers make us more productive, smarter and happier, I agree entirely with the opinion that information is not knowledge. Given that it is so easy to turn to the computer when we want to find something out, we no longer feel the need to learn things. This does seem to me a loss, both at an individual level and for society as a whole.'
  );
  const [htmlJsx, setHtmlJsx] = useState("");
  const [previousWritings, setPreviousWritings] = useState([]);

  // Check if user can submit writing
  const isWritingDisabled = !canSubmitWriting(user, uid);

  // Get previous submitted writings
  useEffect(() => {
    getPreviousWritings();
  }, []);

  // Clear previous errors when typing
  useEffect(() => {
    props.clearErrors();
  }, [selectedAnswer]);

  // Analyse text
  useEffect(() => {
    if (didSubmit) {
      props.analyseWritingText({ uid, text: selectedAnswer });
    }
  }, [didSubmit]);

  // Trigger display analysis
  useEffect(() => {
    if (analysis) {
      displayAnalysis();
      getScoreForWritingEssay(selectedAnswer, analysis, topic);
    }
  }, [analysis]);

  // Save score
  useEffect(() => {
    if (score) {
      saveScore();
    }
  }, [score]);

  // Save score on submit
  function saveScore() {
    const finalScore = `${score.Final}/15`;
    props.setPracticeScore({
      uid,
      score: finalScore,
      successful: score.Final > 7,
      isAuthenticated: isAuthenticated(user),
    });

    // Save user writing
    props.saveUserWriting({
      uid,
      selectedAnswer,
      score: finalScore,
      scoreAnalysis: score,
      analysis: {
        Tags: analysis.Tags.filter((tag) => tag.report === "grammar"),
      },
    });
  }

  // Get spelling/grammatical errors
  function displayAnalysis() {
    // Get list of mistakes
    const errors = analysis.Tags.filter((tag) => tag.report === "grammar");
    // Reverse order
    stableSort(errors, getSorting("desc", "endPos")).map((error) => {
      return highlightErrors(error);
    });

    // Build Html even if no errors
    if (isEmpty(errors)) {
      const inputText = document.getElementById("inputText");
      setHtmlJsx(inputText.innerHTML);
    }
  }

  // Highlight mistakes in text answer
  function highlightErrors(error) {
    const { hint, endPos, startPos, category, subcategory, suggestions } =
      error;

    const inputText = document.getElementById("inputText");
    var innerHTML = inputText.innerHTML;
    // Inject highlightword component in dummy input html
    innerHTML =
      innerHTML.substring(0, startPos) +
      `<highlightword word="` +
      subcategory +
      `"` +
      `hint="` +
      hint +
      `"` +
      `category="` +
      category +
      `"` +
      `suggestions="` +
      [suggestions] +
      `"></highlightword>` +
      innerHTML.substring(endPos + 1);
    inputText.innerHTML = innerHTML;

    setHtmlJsx(innerHTML);
  }

  // Get spelling/grammatical errors (previous writings)
  function getPreviousWritingAnalysis({ analysis, answer }) {
    // Get list of mistakes
    const errors = analysis.Tags.filter((tag) => tag.report === "grammar");

    const inputText = document.getElementById("inputPreviousText");
    inputText.innerHTML = answer;

    // Build Html even if no errors
    if (isEmpty(errors)) return `<p>${answer}</p>`;

    return stableSort(errors, getSorting("desc", "endPos")).map((error) => {
      return highlightPreviousWritingErrors(error);
    })[errors.length - 1];
  }

  // Highlight mistakes in text answer (previous writings)
  function highlightPreviousWritingErrors(error) {
    const { hint, endPos, startPos, category, subcategory, suggestions } =
      error;

    const inputText = document.getElementById("inputPreviousText");
    var innerHTML = inputText.innerHTML;
    // Inject highlightword component in dummy input html
    innerHTML =
      innerHTML.substring(0, startPos) +
      `<highlightword word="` +
      subcategory +
      `"` +
      `hint="` +
      hint +
      `"` +
      `category="` +
      category +
      `"` +
      `suggestions="` +
      [suggestions] +
      `"></highlightword>` +
      innerHTML.substring(endPos + 1);
    inputText.innerHTML = innerHTML;

    return innerHTML;
  }

  // Get previous writings
  async function getPreviousWritings() {
    const res = await props.getUserWritings({ uid });
    setPreviousWritings(res?.userWriting);
  }

  return (
    <Grid container direction="row" className={classes.screen}>
      <TaskBox uid={uid} />
      <Typography className={classes.topic}>{topic}</Typography>
      {/* Textfield input */}
      <TextFieldGroup
        rows={15}
        multiline={true}
        disabled={didSubmit || isWritingDisabled}
        value={selectedAnswer}
        error={errors.text}
        placeholder="Type your answer here"
        customClass={classes.target}
        customContainer={classes.targetContainer}
        onChange={(e) => setSelectedAnswer(e.target.value)}
      />

      {isWritingDisabled ? (
        <Typography className={classes.limitedAi}>
          {/* Limited Ai */}
          {isPaidStudent({ user, settings: props.settings })
            ? "Your Ai writing analysis has been limited. Please contact the team."
            : "You have reached your free Ai writing credit limit. Check our packages for more."}
        </Typography>
      ) : (
        <Typography className={classes.wordCount}>
          {/* Word count */}
          Total Word Count:{" "}
          {selectedAnswer ? selectedAnswer?.trim()?.split(/\s+/).length : 0}
        </Typography>
      )}

      {/* Score */}
      {didSubmit && score && (
        <AlertSkillAnalysisWriting
          score={score}
          htmlJsx={htmlJsx}
          uid={uid}
          sampleAnswer={answer}
        />
      )}

      {/* Previous writings */}
      {!isEmpty(previousWritings) && (
        <div>
          <Typography className={classes.previousSubmission}>
            Previous submissions:
          </Typography>

          <div style={{ display: "flex" }}>
            {previousWritings.map((previousWriting) => {
              if (!previousWriting) return null;

              return (
                <AlertSkillAnalysisPreviousWriting
                  uid={uid}
                  sampleAnswer={answer}
                  score={previousWriting?.scoreAnalysis}
                  htmlJsx={getPreviousWritingAnalysis({
                    analysis: previousWriting?.analysis,
                    answer: previousWriting?.selectedAnswer,
                  })}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* Dummy div for inner html building */}
      <div id="inputText" style={{ display: "none" }}>
        {selectedAnswer}
      </div>
      {/* Dummy div for inner html building (previous writings) */}
      <div id="inputPreviousText" style={{ display: "none" }}></div>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flexGrow: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  topic: {
    width: "100%",
    margin: "1rem 0",
    whiteSpace: "pre-line",
  },
  answer: {
    display: "flex",
    flexWrap: "wrap",
  },
  targetContainer: {
    width: "100%",
  },
  target: {
    margin: 0,
  },
  wordCount: {
    width: "100%",
    margin: "1rem 0",
  },
  limitedAi: {
    color: "red",
    width: "100%",
    margin: "1rem 0",
  },
  previousSubmission: {
    width: "100%",
    marginTop: "1rem",
    fontSize: "1.3rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
  analysis: state.analysis,
  settings: state.settings,
});

export default connect(mapStateToProps, {
  setAnalysis,
  clearErrors,
  getUserWritings,
  saveUserWriting,
  setPracticeScore,
  analyseWritingText,
})(ExerciseWritingEssay);
