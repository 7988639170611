import {
  VISA,
  COMBO,
  SILVER,
  PAYPAL,
  WECHAT,
  ALIPAY,
  BRONZE,
  ULTIMATE,
  APPLEPAY,
  GOOGLEPAY,
  EXTENSION,
  BANCONTACT,
  BRONZE_DISCOUNT,
  SILVER_DISCOUNT,
  ULTIMATE_DISCOUNT,
  FULL_MOCK_PACKAGE,
  SECT_MOCK_PACKAGE,
  BRONZE_DISCOUNT_SECT,
  SILVER_UPGRADE_FROM_BRONZE,
  ULTIMATE_UPGRADE_FROM_BRONZE,
  ULTIMATE_UPGRADE_FROM_SILVER,
} from "./types";
import { isPaidStudent, isAuthenticated } from "./userCheck";

const euroCountries = [
  "Austria",
  "Belgium",
  "Cyprus",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Portugal",
  "Slovakia",
  "Slovenia",
  "Spain",
];

// Exchange rates
// Updated 26/09/2022
const X_INR = 50;
const X_CNY = 4.5;
const X_USD = 0.7;
const X_GBP = 0.6;
const X_CAD = 0.95;
const X_HKD = 5;
const X_PHP = 35;
const X_NPR = 85;
const X_BDT = 60;
const X_SGD = 1;
const X_NZD = 1;
const X_AED = 2.5;
const X_ZAR = 11;
const X_MYR = 3;
const X_THB = 24;
// const X_RUB = 90;
const X_EUR = 0.7;
const X_NGN = 280;
const X_SAR = 2.6;

// Currency exchange and reduced promo price for the top popular country
const getLocalPrice = (
  amount,
  country = "Australia",
  promo,
  upsellCost = 0,
  quantity = 0
) => {
  if (country === "Belgium") {
    var convertedAmount = Math.round(amount * X_EUR);
    var upsellAmount = Math.round(upsellCost * X_EUR) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_EUR * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "EUR",
      payments: [VISA, PAYPAL, BANCONTACT],
    };
  } else if (euroCountries.includes(country)) {
    var convertedAmount = Math.round(amount * X_EUR);
    var upsellAmount = Math.round(upsellCost * X_EUR) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_EUR * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "EUR",
      payments: [VISA, PAYPAL],
    };
  } else if (country === "India") {
    var convertedAmount = Math.round((amount * X_INR) / 10) * 10;
    var upsellAmount = Math.round((upsellCost * X_INR) / 10) * 10 * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_INR * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "INR",
      payments: [VISA],
      showPaypalUS: true,
    };
  } else if (country === "China") {
    var convertedAmount = Math.round(amount * X_CNY);
    var upsellAmount = Math.round(upsellCost * X_CNY) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_CNY * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "CNY",
      payments: [VISA, ALIPAY, WECHAT],
    };
  } else if (country === "United States") {
    var convertedAmount = Math.round(amount * X_USD);
    var upsellAmount = Math.round(upsellCost * X_USD) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_USD * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "USD",
      payments: [VISA, PAYPAL],
    };
  } else if (country === "United Kingdom") {
    var convertedAmount = Math.round(amount * X_GBP);
    var upsellAmount = Math.round(upsellCost * X_GBP) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_GBP * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "GBP",
      payments: [VISA, PAYPAL],
    };
  } else if (country === "Canada") {
    var convertedAmount = Math.round(amount * X_CAD);
    var upsellAmount = Math.round(upsellCost * X_CAD) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_CAD * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "CAD",
      payments: [VISA, PAYPAL],
    };
  } else if (country === "Hong Kong") {
    var convertedAmount = Math.round(amount * X_HKD);
    var upsellAmount = Math.round(upsellCost * X_HKD) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_HKD * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "HKD",
      payments: [VISA, PAYPAL],
    };
  } else if (country === "Philippines") {
    var convertedAmount = Math.round((amount * X_PHP) / 10) * 10;
    var upsellAmount = Math.round((upsellCost * X_PHP) / 10) * 10 * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_PHP * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "PHP",
      payments: [VISA, PAYPAL],
    };
  } else if (country === "Malaysia") {
    var convertedAmount = Math.round((amount * X_MYR) / 10) * 10;
    var upsellAmount = Math.round((upsellCost * X_MYR) / 10) * 10 * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_MYR * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "MYR",
      payments: [VISA],
      showPaypalUS: true,
    };
  } else if (country === "Thailand") {
    var convertedAmount = Math.round((amount * X_THB) / 10) * 10;
    var upsellAmount = Math.round((upsellCost * X_THB) / 10) * 10 * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_THB * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "THB",
      payments: [VISA, PAYPAL],
    };
  }
  // else if (country === "Russia") {
  //   var convertedAmount = Math.round((amount * X_RUB) / 10) * 10;
  //   return {
  //     convertedAmount: convertedAmount,
  //     reducedAmount: promo
  //       ? Math.round(amount * X_RUB * ((100 - promo.discount) / 100))
  //       : convertedAmount,
  //     currency: "RUB",
  //     payments: [VISA, PAYPAL],
  //   };
  // }
  else if (country === "Nepal") {
    var convertedAmount = Math.round((amount * X_NPR) / 10) * 10;
    var upsellAmount = Math.round((upsellCost * X_NPR) / 10) * 10 * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_NPR * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "NPR",
      payments: [VISA],
      showPaypalUS: true,
    };
  } else if (country === "Bangladesh") {
    var convertedAmount = Math.round((amount * X_BDT) / 10) * 10;
    var upsellAmount = Math.round((upsellCost * X_BDT) / 10) * 10 * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_BDT * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "BDT",
      payments: [VISA],
    };
  } else if (country === "Singapore") {
    var convertedAmount = Math.round(amount * X_SGD);
    var upsellAmount = Math.round(upsellCost * X_SGD) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_SGD * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "SGD",
      payments: [VISA, PAYPAL],
    };
  } else if (country === "New Zealand") {
    var convertedAmount = Math.round(amount * X_NZD);
    var upsellAmount = Math.round(upsellCost * X_NZD) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_NZD * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "NZD",
      payments: [VISA, PAYPAL],
    };
  } else if (country === "United Arab Emirates") {
    var convertedAmount = Math.round(amount * X_AED);
    var upsellAmount = Math.round(upsellCost * X_AED) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_AED * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "AED",
      payments: [VISA],
      showPaypalUS: true,
    };
  } else if (country === "South Africa") {
    var convertedAmount = Math.round((amount * X_ZAR) / 10) * 10;
    var upsellAmount = Math.round((upsellCost * X_ZAR) / 10) * 10 * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_ZAR * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "ZAR",
      payments: [VISA],
      showPaypalUS: true,
    };
  } else if (country === "Australia") {
    var upsellAmount = upsellCost * quantity;

    return {
      convertedAmount: amount + upsellAmount,
      reducedAmount: promo
        ? Math.round(amount * ((100 - promo.discount) / 100) + upsellAmount)
        : amount + upsellAmount,
      currency: "AUD",
      payments: [VISA, PAYPAL, ALIPAY, WECHAT],
    };
  }
  if (country === "Nigeria") {
    var convertedAmount = Math.round((amount * X_NGN) / 10) * 10;
    var upsellAmount = Math.round((upsellCost * X_NGN) / 10) * 10 * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_NGN * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "NGN",
      payments: [VISA],
      showPaypalUS: true,
    };
  } else if (country === "Saudi Arabia") {
    var convertedAmount = Math.round(amount * X_SAR);
    var upsellAmount = Math.round(upsellCost * X_SAR) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_SAR * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "SAR",
      payments: [VISA],
      showPaypalUS: true,
    };
  } else {
    var convertedAmount = Math.round(amount * X_USD);
    var upsellAmount = Math.round(upsellCost * X_USD) * quantity;

    return {
      convertedAmount: convertedAmount + upsellAmount,
      reducedAmount: promo
        ? Math.round(
            amount * X_USD * ((100 - promo.discount) / 100) + upsellAmount
          )
        : convertedAmount + upsellAmount,
      currency: "USD",
      payments: [VISA, PAYPAL],
    };
  }
};

// Check if active promo for this particular plan
const getCurrentPromo = (promotions, packageId, user) => {
  // General promo
  var allPromo = promotions;

  // Merge general promo and user promo if authenticated
  if (isAuthenticated(user)) {
    allPromo = promotions.concat(user.userPromo);
  }

  // Sort by discount then take first active promo
  return allPromo
    .sort((a, b) => {
      if (a.discount < b.discount) return 1;
      if (a.discount > b.discount) return -1;
      return 0;
    })
    .filter((p) => {
      return (
        Date.now() < p.endTime &&
        p.startTime < Date.now() &&
        p.target.includes(packageId)
      );
    })[0];
};

// Check if active promo in general
const getActivePromo = (promotions, user) => {
  // General promo
  var allPromo = promotions;

  // Merge general promo and user promo if authenticated
  if (isAuthenticated(user)) {
    allPromo = promotions.concat(user.userPromo);
  }

  return allPromo
    .sort((a, b) => {
      if (a.discount < b.discount) return 1;
      if (a.discount > b.discount) return -1;
      return 0;
    })
    .filter((p) => {
      return Date.now() < p.endTime && p.startTime < Date.now();
    })[0];
};

// Get button text in pricing card
const getButtonText = ({ packageId, user, settings }) => {
  if (isAuthenticated(user) && !isPaidStudent({ user, settings })) {
    return "Upgrade";
  } else if (
    (packageId === ULTIMATE_DISCOUNT &&
      user.currentPlan === ULTIMATE_DISCOUNT) ||
    (packageId === ULTIMATE && user.currentPlan === ULTIMATE) ||
    (packageId === BRONZE && user.currentPlan === BRONZE) ||
    (packageId === SILVER && user.currentPlan === SILVER)
  ) {
    return "Current";
  } else {
    return "ADD TO CART";
  }
};

// Get title text for pricing card
const getPricingCardTitle = (packageId) => {
  if (packageId === SILVER || packageId === SILVER_DISCOUNT) {
    return "Mock test included";
  } else if (packageId === ULTIMATE || packageId === ULTIMATE_DISCOUNT) {
    return "All in one";
  } else if (packageId === COMBO) {
    return "Special: 2 + 2";
  } else if (packageId === FULL_MOCK_PACKAGE) {
    return "Full mock test";
  } else {
    return "";
  }
};

// Return payment method logo
const getPaymentMethod = (methodID) => {
  if (methodID === VISA) {
    return {
      logo: "https://pineapplestudio.com.au/pte_success_v2/images/layout/cards_svg_logo.svg",
      instruction: "",
    };
  } else if (methodID === PAYPAL) {
    return {
      name: "",
      logo: "https://pineapplestudio.com.au/pte_success_v2/images/layout/paypal_svg_logo_2.svg",
      instruction: "The safer, easier way to pay",
    };
  } else if (methodID === ALIPAY) {
    return {
      name: "Alipay",
      logo: "https://pineapplestudio.com.au/pte_success_v2/images/layout/alipay_svg_logo.svg",
      instruction: "Complete your transaction with your Alipay account.",
    };
  } else if (methodID === WECHAT) {
    return {
      name: "Wechat",
      logo: "https://pineapplestudio.com.au/pte_success_v2/images/layout/wechat_svg_logo.svg",
      instruction: "Complete your transaction with your Wechat account.",
    };
  } else if (methodID === BANCONTACT) {
    return {
      name: "Bancontact",
      logo: "https://pineapplestudio.com.au/pte_success_v2/images/layout/bancontact_svg_logo.svg",
      instruction: "Complete your transaction with your Bancontact account.",
    };
  } else if (methodID === APPLEPAY) {
    return {
      name: "Apple Pay",
      logo: "https://pineapplestudio.com.au/pte_success_v2/images/layout/apple_pay_svg_logo.svg",
      instruction: "Complete your transaction with your Apple Pay account.",
    };
  } else if (methodID === GOOGLEPAY) {
    return {
      name: "Google Pay",
      logo: "https://pineapplestudio.com.au/pte_success_v2/images/layout/google_pay_svg_logo.svg",
      instruction: "Complete your transaction with your Google Pay account.",
    };
  }
};

// List of countries with their emoji flag
const countryList = [
  { name: "Australia", emoji: "🇦🇺" },
  { name: "China", emoji: "🇨🇳" },
  { name: "Hong Kong", emoji: "🇭🇰" },
  { name: "India", emoji: "🇮🇳" },
  { name: "Nepal", emoji: "🇳🇵" },
  { name: "New Zealand", emoji: "🇳🇿" },
  { name: "Nigeria", emoji: "🇳🇬" },
  { name: "Philippines", emoji: "🇵🇭" },
  { name: "Russia", emoji: "🇷🇺" },
  { name: "Saudi Arabia", emoji: "🇸🇦" },
  { name: "Singapore", emoji: "🇸🇬" },
  { name: "United Arab Emirates", emoji: "🇦🇪" },
  { name: "United Kingdom", emoji: "🇬🇧" },
  { name: "United States", emoji: "🇺🇸" },
  { name: "Vietnam", emoji: "🇻🇳" },
  { name: "Other", emoji: "🏳" },
  { name: "--------", emoji: "" },
  { name: "Afghanistan", emoji: "🇦🇫" },
  { name: "Albania", emoji: "🇦🇱" },
  { name: "Algeria", emoji: "🇩🇿" },
  { name: "American Samoa", emoji: "🇦🇸" },
  { name: "Andorra", emoji: "🇦🇩" },
  { name: "Angola", emoji: "🇦🇴" },
  { name: "Anguilla", emoji: "🇦🇮" },
  { name: "Argentina", emoji: "🇦🇷" },
  { name: "Armenia", emoji: "🇦🇲" },
  { name: "Australia", emoji: "🇦🇺" },
  { name: "Austria", emoji: "🇦🇹" },
  { name: "Azerbaijan", emoji: "🇦🇿" },
  { name: "Bahamas", emoji: "🇧🇸" },
  { name: "Bahrain", emoji: "🇧🇭" },
  { name: "Bangladesh", emoji: "🇧🇩" },
  { name: "Barbados", emoji: "🇧🇧" },
  { name: "Belarus", emoji: "🇧🇾" },
  { name: "Belgium", emoji: "🇧🇪" },
  { name: "Belize", emoji: "🇧🇿" },
  { name: "Benin", emoji: "🇧🇯" },
  { name: "Bermuda", emoji: "🇧🇲" },
  { name: "Bhutan", emoji: "🇧🇹" },
  { name: "Bolivia", emoji: "🇧🇴" },
  { name: "Bosnia and Herzegovina", emoji: "🇧🇦" },
  { name: "Botswana", emoji: "🇧🇼" },
  { name: "Brazil", emoji: "🇧🇷" },
  { name: "Brunei", emoji: "🇧🇳" },
  { name: "Bulgaria", emoji: "🇧🇬" },
  { name: "Burkina Faso", emoji: "🇧🇫" },
  { name: "Burundi", emoji: "🇧🇮" },
  { name: "Cambodia", emoji: "🇰🇭" },
  { name: "Cameroon", emoji: "🇨🇲" },
  { name: "Canada", emoji: "🇨🇦" },
  { name: "Cape Verde", emoji: "🇨🇻" },
  { name: "Central African Republic", emoji: "🇨🇫" },
  { name: "Chad", emoji: "🇹🇩" },
  { name: "Chile", emoji: "🇨🇱" },
  { name: "China", emoji: "🇨🇳" },
  { name: "Colombia", emoji: "🇨🇴" },
  { name: "Cook Islands", emoji: "🇨🇰" },
  { name: "Costa Rica", emoji: "🇨🇷" },
  { name: "Croatia", emoji: "🇭🇷" },
  { name: "Cuba", emoji: "🇨🇺" },
  { name: "Cyprus", emoji: "🇨🇾" },
  { name: "Czech Republic", emoji: "🇨🇿" },
  { name: "Democratic Republic of the Congo", emoji: "🇨🇩" },
  { name: "Denmark", emoji: "🇩🇰" },
  { name: "Djibouti", emoji: "🇩🇯" },
  { name: "Dominica", emoji: "🇩🇲" },
  { name: "Dominican Republic", emoji: "🇩🇴" },
  { name: "East Timor", emoji: "🇹🇱" },
  { name: "Ecuador", emoji: "🇪🇨" },
  { name: "Egypt", emoji: "🇪🇬" },
  { name: "El Salvador", emoji: "🇸🇻" },
  { name: "Equatorial Guinea", emoji: "🇬🇶" },
  { name: "Eritrea", emoji: "🇪🇷" },
  { name: "Estonia", emoji: "🇪🇪" },
  { name: "Ethiopia", emoji: "🇪🇹" },
  { name: "Faroe Islands", emoji: "🇫🇴" },
  { name: "Fiji", emoji: "🇫🇯" },
  { name: "Finland", emoji: "🇫🇮" },
  { name: "France", emoji: "🇫🇷" },
  { name: "French Guiana", emoji: "🇬🇫" },
  { name: "Gabon", emoji: "🇬🇦" },
  { name: "Gambia", emoji: "🇬🇲" },
  { name: "Georgia", emoji: "🇬🇪" },
  { name: "Germany", emoji: "🇩🇪" },
  { name: "Ghana", emoji: "🇬🇭" },
  { name: "Gibraltar", emoji: "🇬🇮" },
  { name: "Greece", emoji: "🇬🇷" },
  { name: "Grenada", emoji: "🇬🇩" },
  { name: "Guadeloupe", emoji: "🇬🇵" },
  { name: "Guatemala", emoji: "🇬🇹" },
  { name: "Guinea", emoji: "🇬🇳" },
  { name: "Guinea-Bissau", emoji: "🇬🇼" },
  { name: "Guyana", emoji: "🇬🇾" },
  { name: "Haiti", emoji: "🇭🇹" },
  { name: "Honduras", emoji: "🇭🇳" },
  { name: "Hong Kong", emoji: "🇭🇰" },
  { name: "Hungary", emoji: "🇭🇺" },
  { name: "Iceland", emoji: "🇮🇸" },
  { name: "India", emoji: "🇮🇳" },
  { name: "Indonesia", emoji: "🇮🇩" },
  { name: "Iran", emoji: "🇮🇷" },
  { name: "Iraq", emoji: "🇮🇶" },
  { name: "Ireland", emoji: "🇮🇪" },
  { name: "Israel", emoji: "🇮🇱" },
  { name: "Italy", emoji: "🇮🇹" },
  { name: "Ivory Coast", emoji: "🇨🇮" },
  { name: "Jamaica", emoji: "🇯🇲" },
  { name: "Japan", emoji: "🇯🇵" },
  { name: "Jordan", emoji: "🇯🇴" },
  { name: "Kazakhstan", emoji: "🇰🇿" },
  { name: "Kenya", emoji: "🇰🇪" },
  { name: "Kosovo", emoji: "🇽🇰" },
  { name: "Kuwait", emoji: "🇰🇼" },
  { name: "Kyrgyzstan", emoji: "🇰🇬" },
  { name: "Laos", emoji: "🇱🇦" },
  { name: "Latvia", emoji: "🇱🇻" },
  { name: "Lebanon", emoji: "🇱🇧" },
  { name: "Liberia", emoji: "🇱🇷" },
  { name: "Libya", emoji: "🇱🇾" },
  { name: "Lithuania", emoji: "🇱🇹" },
  { name: "Luxembourg", emoji: "🇱🇺" },
  { name: "Macao", emoji: "🇲🇴" },
  { name: "Madagascar", emoji: "🇲🇬" },
  { name: "Malawi", emoji: "🇲🇼" },
  { name: "Malaysia", emoji: "🇲🇾" },
  { name: "Maldives", emoji: "🇲🇻" },
  { name: "Mali", emoji: "🇲🇱" },
  { name: "Malta", emoji: "🇲🇹" },
  { name: "Mauritania", emoji: "🇲🇷" },
  { name: "Mauritius", emoji: "🇲🇺" },
  { name: "Mexico", emoji: "🇲🇽" },
  { name: "Micronesia", emoji: "🇫🇲" },
  { name: "Moldova", emoji: "🇲🇩" },
  { name: "Monaco", emoji: "🇲🇨" },
  { name: "Mongolia", emoji: "🇲🇳" },
  { name: "Montenegro", emoji: "🇲🇪" },
  { name: "Morocco", emoji: "🇲🇦" },
  { name: "Mozambique", emoji: "🇲🇿" },
  { name: "Myanmar [Burma]", emoji: "🇲🇲" },
  { name: "Namibia", emoji: "🇳🇦" },
  { name: "Nauru", emoji: "🇳🇷" },
  { name: "Nepal", emoji: "🇳🇵" },
  { name: "Netherlands", emoji: "🇳🇱" },
  { name: "New Zealand", emoji: "🇳🇿" },
  { name: "Nicaragua", emoji: "🇳🇮" },
  { name: "Niger", emoji: "🇳🇪" },
  { name: "Nigeria", emoji: "🇳🇬" },
  { name: "Niue", emoji: "🇳🇺" },
  { name: "Norfolk Island", emoji: "🇳🇫" },
  { name: "North Macedonia", emoji: "🇲🇰" },
  { name: "Norway", emoji: "🇳🇴" },
  { name: "Oman", emoji: "🇴🇲" },
  { name: "Pakistan", emoji: "🇵🇰" },
  { name: "Palau", emoji: "🇵🇼" },
  { name: "Palestine", emoji: "🇵🇸" },
  { name: "Panama", emoji: "🇵🇦" },
  { name: "Papua New Guinea", emoji: "🇵🇬" },
  { name: "Paraguay", emoji: "🇵🇾" },
  { name: "Peru", emoji: "🇵🇪" },
  { name: "Philippines", emoji: "🇵🇭" },
  { name: "Poland", emoji: "🇵🇱" },
  { name: "Portugal", emoji: "🇵🇹" },
  { name: "Puerto Rico", emoji: "🇵🇷" },
  { name: "Qatar", emoji: "🇶🇦" },
  { name: "Republic of the Congo", emoji: "🇨🇬" },
  { name: "Romania", emoji: "🇷🇴" },
  { name: "Russia", emoji: "🇷🇺" },
  { name: "Rwanda", emoji: "🇷🇼" },
  { name: "Saint Barthélemy", emoji: "🇧🇱" },
  { name: "Saint Helena", emoji: "🇸🇭" },
  { name: "Saint Martin", emoji: "🇲🇫" },
  { name: "Samoa", emoji: "🇼🇸" },
  { name: "Saudi Arabia", emoji: "🇸🇦" },
  { name: "Senegal", emoji: "🇸🇳" },
  { name: "Serbia", emoji: "🇷🇸" },
  { name: "Seychelles", emoji: "🇸🇨" },
  { name: "Sierra Leone", emoji: "🇸🇱" },
  { name: "Singapore", emoji: "🇸🇬" },
  { name: "Slovakia", emoji: "🇸🇰" },
  { name: "Slovenia", emoji: "🇸🇮" },
  { name: "Solomon Islands", emoji: "🇸🇧" },
  { name: "Somalia", emoji: "🇸🇴" },
  { name: "South Africa", emoji: "🇿🇦" },
  { name: "South Korea", emoji: "🇰🇷" },
  { name: "South Sudan", emoji: "🇸🇸" },
  { name: "Spain", emoji: "🇪🇸" },
  { name: "Sri Lanka", emoji: "🇱🇰" },
  { name: "Sudan", emoji: "🇸🇩" },
  { name: "Suriname", emoji: "🇸🇷" },
  { name: "Swaziland", emoji: "🇸🇿" },
  { name: "Sweden", emoji: "🇸🇪" },
  { name: "Switzerland", emoji: "🇨🇭" },
  { name: "Syria", emoji: "🇸🇾" },
  { name: "Taiwan", emoji: "🇹🇼" },
  { name: "Tajikistan", emoji: "🇹🇯" },
  { name: "Tanzania", emoji: "🇹🇿" },
  { name: "Thailand", emoji: "🇹🇭" },
  { name: "Togo", emoji: "🇹🇬" },
  { name: "Tokelau", emoji: "🇹🇰" },
  { name: "Tonga", emoji: "🇹🇴" },
  { name: "Trinidad and Tobago", emoji: "🇹🇹" },
  { name: "Tunisia", emoji: "🇹🇳" },
  { name: "Turkey", emoji: "🇹🇷" },
  { name: "Turkmenistan", emoji: "🇹🇲" },
  { name: "Tuvalu", emoji: "🇹🇻" },
  { name: "Uganda", emoji: "🇺🇬" },
  { name: "Ukraine", emoji: "🇺🇦" },
  { name: "United Arab Emirates", emoji: "🇦🇪" },
  { name: "United Kingdom", emoji: "🇬🇧" },
  { name: "United States", emoji: "🇺🇸" },
  { name: "Uruguay", emoji: "🇺🇾" },
  { name: "Uzbekistan", emoji: "🇺🇿" },
  { name: "Vanuatu", emoji: "🇻🇺" },
  { name: "Vatican City", emoji: "🇻🇦" },
  { name: "Venezuela", emoji: "🇻🇪" },
  { name: "Vietnam", emoji: "🇻🇳" },
  { name: "Yemen", emoji: "🇾🇪" },
  { name: "Zambia", emoji: "🇿🇲" },
  { name: "Zimbabwe", emoji: "🇿🇼" },
];

// Check if package if a full package
const isFullPackage = (pack = {}) => {
  return [
    BRONZE,
    BRONZE_DISCOUNT,
    BRONZE_DISCOUNT_SECT,
    SILVER,
    SILVER_DISCOUNT,
    SILVER_UPGRADE_FROM_BRONZE,
    ULTIMATE,
    ULTIMATE_DISCOUNT,
    ULTIMATE_UPGRADE_FROM_BRONZE,
    ULTIMATE_UPGRADE_FROM_SILVER,
    EXTENSION,
  ].includes(pack?.packageId);
};

// Check if package if a mock test package (no combo)
const isMockPackage = (pack = {}) => {
  return [FULL_MOCK_PACKAGE, SECT_MOCK_PACKAGE].includes(pack?.packageId);
};

// Check if package if a upgrade package
const isUpgradePackage = (pack = {}) => {
  return [
    SILVER_UPGRADE_FROM_BRONZE,
    ULTIMATE_UPGRADE_FROM_BRONZE,
    ULTIMATE_UPGRADE_FROM_SILVER,
  ].includes(pack?.packageId);
};

export {
  countryList,
  getButtonText,
  getLocalPrice,
  isFullPackage,
  isMockPackage,
  getActivePromo,
  getCurrentPromo,
  getPaymentMethod,
  isUpgradePackage,
  getPricingCardTitle,
};
