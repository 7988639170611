import axios from "axios";
import { SNACK_EMAIL_SENT } from "../helpers/types";
import { displaySnackbar } from "./notificationActions";
import { handleErrors, clearErrors } from "./errorActions";
import { startLoadingSpinner, stopLoadingSpinner } from "./loadingActions";

// @desc  Forgot password email
// @next  Snackbar notif + redirect to login
export const forgotPassword = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/email/forgotPassword", data)
      .then((res) => {
        dispatch(stopLoadingSpinner());
        dispatch(displaySnackbar(SNACK_EMAIL_SENT));
        resolve(res.data);
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};

// @desc  Contact form email
// @next  Snackbar notif
export const sendContactEmail = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/email/sendContactEmail", data)
      .then((res) => {
        dispatch(stopLoadingSpinner());
        dispatch(displaySnackbar(SNACK_EMAIL_SENT));
        resolve(res.data);
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};

// @desc  Send referral email invitation
// @next  Snackbar notif
export const sendInvite = (data) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/email/sendInvite", data)
      .then((res) => {
        dispatch(stopLoadingSpinner());
        dispatch(clearErrors());
        dispatch(displaySnackbar(SNACK_EMAIL_SENT));
        resolve(res.data);
      })
      .catch((error) => dispatch(handleErrors(error)));
  });
};
