import React from "react";

import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

// Message box in onboarding
function OnBoardingMessageBox2({ cutout, ...props }) {
  const classes = useStyles();
  const { description, icon } = cutout;

  return (
    <Slide direction="up" in={true} timeout={1000} mountOnEnter unmountOnExit>
      <div className={classes.Wrapper}>
        {/* Title */}
        <Typography variant="h4" className={classes.title}>
          How it works?
        </Typography>
        {/* Icon and description */}
        <div className={classes.topPart}>
          <div className={classes.icon}>{icon}</div>
          <Typography variant="h6" className={classes.description}>
            {description}
          </Typography>
        </div>
        {/* Previous and next buttons */}
        <div className={classes.buttons}>
          <div className={classes.buttonPrevious} onClick={props.onPrevious}>
            Previous
          </div>
          <div className={classes.buttonNext} onClick={props.onNext}>
            Next
          </div>
        </div>
      </div>
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    alignSelf: "center",
    width: "75%",
    maxWidth: "30em",
    minHeight: "15em",
    background: theme.customColors.background,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "65px",
    },
  },
  title: {
    textAlign: "center",
    paddingTop: "1em",
    color: theme.customColors.blackWhite,
  },
  topPart: {
    margin: "1em",
    display: "flex",
    flexGrow: "1",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1em",
    padding: "1em",
    width: "2em",
    height: "2em",
    borderRadius: "50%",
    boxShadow: theme.shadows[2],
    color: theme.customColors.blue,
    background: "white",
  },
  description: {
    width: "70%",
    color: theme.customColors.blackWhite,
  },
  buttons: {
    width: "100%",
    height: "5em",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonPrevious: {
    display: "flex",
    alignSelf: "flex-end",
    background: theme.palette.grey[500],
    color: "white",
    padding: "0.5em 1em",
    margin: "1em",
    borderRadius: "3px",
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
      cursor: "pointer",
    },
  },
  buttonNext: {
    display: "flex",
    alignSelf: "flex-end",
    background: theme.customColors.greenDarker,
    color: "white",
    padding: "0.5em 1em",
    margin: "1em",
    borderRadius: "3px",
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
      cursor: "pointer",
    },
  },
}));

export default OnBoardingMessageBox2;
