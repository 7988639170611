import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Filter from "bad-words";
import Highlighter from "react-highlight-words";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import AudioPlayer from "./components/AudioPlayer";
import WarningMobile from "./components/WarningMobile";
import RecorderManager from "./components/RecorderManager";
import AlertSkillAnalysis from "../alerts/AlertSkillAnalysis";

import isEmpty from "../../helpers/isEmpty";
import { isDesktop } from "../../helpers/getBrowserData";
import { isAuthenticated } from "../../helpers/userCheck";
import { setPracticeScore } from "../../actions/questionActions";
import highlightFullWords from "../../helpers/highlightFullWords";
import { cleanNumbersFromArray } from "../../helpers/stringHelper";

const filter = new Filter();

// Speaking Repeat Sentences
function ExerciseSpeakingRepeatSentences({ question, didSubmit, ...props }) {
  const classes = useStyles();

  const { score } = props.analysis;
  const { transcript, track, uid } = question;
  const { speechText, forceShowAnswer } = props.recorder;

  const [openedAlert, setOpenedAlert] = useState(false);

  const speechTextArray = !isEmpty(speechText[0])
    ? cleanNumbersFromArray(filter.clean(speechText[0]).split(" "))
    : [];

  // Save score
  useEffect(() => {
    if (score && !isEmpty(speechText)) {
      saveScore();
    }
  }, [score, speechText]);

  // Save score on submit
  function saveScore() {
    const finalScore = `${score.Final}/90`;
    props.setPracticeScore({
      uid,
      score: finalScore,
      successful: score.Final > 5,
      isAuthenticated: isAuthenticated(props.user),
    });
  }

  return (
    <Grid container direction="row" className={classes.screen}>
      {!isDesktop() && <WarningMobile />}
      <div className={classes.taskMargin}>
        <TaskBox uid={uid} />
      </div>
      <AudioPlayer
        track={track}
        playerId={"audio__player"}
        openedAlert={openedAlert}
      />
      <Grid item xs={12} className={classes.column}>
        <Highlighter
          autoEscape={true}
          highlightClassName={classes.highlight}
          unhighlightClassName={
            didSubmit && score ? classes.unhighlight : classes.text
          }
          className={clsx(classes.text, {
            [classes.hideBlur]: forceShowAnswer,
            [classes.textIncorect]: didSubmit,
            [classes.blur]: !didSubmit,
          })}
          searchWords={didSubmit && score ? speechTextArray : []}
          textToHighlight={transcript}
          findChunks={highlightFullWords}
        />
      </Grid>
      {didSubmit && score && (
        <Grid item xs={12}>
          <AlertSkillAnalysis
            htmlJsx={`<p>${speechText.join(" ")}</p>`}
            sampleAnswer={transcript}
            score={score}
            uid={uid}
            track={track}
            showHighlightAsSample={true}
            speechTextArray={speechTextArray}
            setOpenedAlert={setOpenedAlert}
          />
        </Grid>
      )}
      <RecorderManager question={question} openedAlert={openedAlert} />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    width: "100%",
    display: "flex",
    margin: "1rem 0",
    maxWidth: "900px",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
      margin: "0.5rem 0",
    },
  },
  column: {
    margin: "1rem 0",
    display: "flex",
    padding: "0 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  taskMargin: {
    padding: "0 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  text: {
    fontSize: "1.2rem",
    lineHeight: "1.7rem",
    whiteSpace: "pre-line",
    color: theme.customColors.blackWhite,
  },
  textIncorect: {
    color: "red",
  },
  blur: {
    filter: "blur(10px)",
  },
  hideBlur: {
    filter: "blur(0px)",
  },
  highlight: {
    fontSize: "1.2rem",
    lineHeight: "1.7rem",
    whiteSpace: "pre-line",
    color: theme.customColors.greenDarker,
    background: theme.palette.background.paper,
  },
  unhighlight: {
    fontSize: "1.2rem",
    lineHeight: "1.7rem",
    whiteSpace: "pre-line",
    color: theme.customColors.red,
    background: theme.palette.background.paper,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
  analysis: state.analysis,
  recorder: state.recorder,
});

export default connect(mapStateToProps, { setPracticeScore })(
  ExerciseSpeakingRepeatSentences
);
