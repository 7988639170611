import React, { useState, useEffect, lazy, Suspense } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import "../../../css/recorder.css";

import Grid from "@material-ui/core/Grid";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import TooltipCustom from "./TooltipCustom";
import AudioPlayback from "./AudioPlayback";
import RecorderWaitingTimer from "./RecorderWaitingTimer";
import RecorderAnsweringTimer from "./RecorderAnsweringTimer";
import AlertMicPermsDenied from "../../alerts/AlertMicPermsDenied";
import AlertSilenceDetected from "../../alerts/AlertSilenceDetected";
import AlertAudioBeforeSubmit from "../../alerts/AlertAudioBeforeSubmit";

import { getExerciseSettings } from "../../../helpers/getExerciseData";

import {
  setClickedNext,
  setToggleRecording,
  clearRecorderReducer,
} from "../../../actions/recorderActions";
import {
  ROUTE_PRICING,
  SPEAKING_CREDITS,
  FREE_SPEAKING_CREDITS,
  FREE_SPEAKING_CREDITS_NUMBER,
} from "../../../helpers/types";
import {
  isPaidStudent,
  isAuthenticated,
  isUltimateStudent,
} from "../../../helpers/userCheck";
import { createCart } from "../../../actions/userActions";
import { navigateTo } from "../../../actions/navigationActions";
import { setDidSubmit } from "../../../actions/questionActions";

const NewRecorder = lazy(() => import("./NewRecorder"));

function RecorderManager({ question, ...props }) {
  const classes = useStyles();
  const {
    isRecording,
    accessDenied,
    startRecorder,
    silenceDetected,
    timeLeftWaiting,
    timerShouldStart,
    timeLeftAnswering,
    didFinishRecording,
  } = props.recorder;

  const { speakingCredits, speakingCreditsUsed } = props.user;

  // Get settings about this exercise type
  const { waitingTime, answeringTime } = getExerciseSettings(question.uid);

  const [openAlert, setOpenAlert] = useState(false);
  const [submitAudio, setSubmitAudio] = useState(false);
  const [errorWhisper, setErrorWhisper] = useState(false);
  const [preventFlickeringTimer, setPreventFlickeringTimer] = useState(false);

  // Allow stop recorder after 2 seconds
  const allowStop = answeringTime - timeLeftAnswering >= 2;

  // Free credits if not login
  var freeCredits = FREE_SPEAKING_CREDITS_NUMBER;
  if (!isAuthenticated(props.user)) {
    freeCredits = parseInt(
      localStorage[FREE_SPEAKING_CREDITS] || FREE_SPEAKING_CREDITS_NUMBER
    );
  }
  const hasNoCreditLeft = isAuthenticated(props.user)
    ? speakingCreditsUsed >= speakingCredits
    : freeCredits <= 0;

  // Reset submit state when start recording
  useEffect(() => {
    if (isRecording) {
      props.setDidSubmit(false);
    }
  }, [isRecording]);

  // Reset submit state when start recording
  useEffect(() => {
    props.clearRecorderReducer();
  }, [question]);

  return (
    <Grid container direction="row" className={classes.screen}>
      <TooltipCustom
        title={
          isUltimateStudent({ user: props.user, settings: props.settings })
            ? "You have unlimited Ai speaking!"
            : !isAuthenticated(props.user)
            ? freeCredits > 0
              ? `You have ${freeCredits} free Ai speaking credit left.`
              : `You have no free Ai credit left. Create an account to get more credits.`
            : hasNoCreditLeft
            ? "You have reached your Ai speaking credit limit. Contact the team if needed"
            : `You have ${Math.max(
                speakingCredits - speakingCreditsUsed,
                0
              )} Ai speaking credit left.`
        }
        subTitle="- 1 Ai credit is used per answer"
        subTitle2={
          isPaidStudent({ user: props.user, settings: props.settings })
            ? "- You can get additional credits from the Profile page"
            : "- You can get additional credits from the Pricing page"
        }
        subTitle3="- Students with an Ultimate package have unlimited Ai speaking credits"
        component={<div className={classes.aiCreditsText}>Ai</div>}
      ></TooltipCustom>

      {/* Microphone icon */}
      <Grid xs={12} sm={6} className={classes.column}>
        <button
          onMouseUp={() => {
            if (hasNoCreditLeft || (isRecording && !allowStop)) return null;

            setErrorWhisper(false);
            setPreventFlickeringTimer(true);
            props.setClickedNext(false);
            props.setToggleRecording(!startRecorder);
          }}
          className={classnames("speechRecorder", {
            active: isRecording,
            disabled: accessDenied || hasNoCreditLeft,
            "button--disabled": !timerShouldStart || hasNoCreditLeft,
          })}
        >
          {accessDenied || hasNoCreditLeft ? (
            <MicOffIcon style={{ fontSize: "3rem" }} />
          ) : (
            <MicIcon style={{ fontSize: "3rem" }} />
          )}
          {/* Alert browser */}
        </button>
        <div
          style={{
            display: "flex",
            flexDirection: hasNoCreditLeft ? "column" : "row",
          }}
        >
          <Typography className={classes.redText}>
            {errorWhisper
              ? "Error 2: Contact team"
              : hasNoCreditLeft
              ? "No Ai credit left"
              : accessDenied // Info red text
              ? "Microphone permission denied"
              : silenceDetected
              ? "Silence detected"
              : timerShouldStart &&
                !isRecording &&
                timeLeftWaiting > 0 &&
                !didFinishRecording &&
                !preventFlickeringTimer
              ? `Starts in ${timeLeftWaiting}`
              : ""}
          </Typography>
          {accessDenied && !hasNoCreditLeft && <AlertMicPermsDenied />}
          {silenceDetected && !hasNoCreditLeft && <AlertSilenceDetected />}
          {hasNoCreditLeft && (
            <Typography
              className={classes.getMoreCredits}
              onClick={
                isPaidStudent({ user: props.user, settings: props.settings })
                  ? () =>
                      props.createCart({
                        fromProfilePage: true,
                        packageId: SPEAKING_CREDITS,
                      })
                  : () => props.navigateTo(ROUTE_PRICING)
              }
            >
              Get more credits
            </Typography>
          )}
        </div>
      </Grid>
      {/* Playback blob */}
      <Grid item xs={12} sm={6} className={classes.columnBlob}>
        <div style={{ fontSize: "0.9rem" }}>Listen to your speech:</div>
        <AudioPlayback
          playerId="audio__playback"
          openedAlert={props.openedAlert}
        />
      </Grid>
      <Suspense fallback={<div />}>
        <NewRecorder
          question={question}
          submitAudio={submitAudio}
          setOpenAlert={setOpenAlert}
          setErrorWhisper={setErrorWhisper}
          clearSubmitAudio={() => setSubmitAudio(false)}
        />
      </Suspense>
      {/* Red progress bar */}
      <div
        className={classes.progressBar}
        style={{
          width: `${
            isRecording ? 100 - (timeLeftAnswering * 100) / answeringTime : 0
          }%`,
          borderRadius:
            timeLeftAnswering < answeringTime ? "0 0 0 5px" : "0 0 5px 5px",
          background: isRecording ? "red" : "#ffffff00",
        }}
      />
      {/* Timer functions */}
      <RecorderWaitingTimer waitingTime={waitingTime} />
      <RecorderAnsweringTimer answeringTime={answeringTime} />
      <AlertAudioBeforeSubmit
        openAlert={openAlert}
        openedAlert={props.openedAlert}
        closeAlert={() => setOpenAlert(false)}
        submitAudio={() => setSubmitAudio(true)}
      />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    width: "100%",
    display: "flex",
    maxWidth: "1080px",
    position: "relative",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    backgroundColor: theme.palette.action.hover,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  column: {
    display: "flex",
    padding: "1em 0",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "1em",
    },
  },
  columnBlob: {
    display: "flex",
    padding: "1em 0",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  redText: {
    color: "red",
    height: "1em",
    textAlign: "center",
  },
  progressBar: {
    height: "5px",
    minWidth: "1px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0 !important",
    },
  },
  aiCreditsText: {
    top: 0,
    right: 0,
    cursor: "help",
    width: "2.5rem",
    color: "#DA3025",
    height: "1.5rem",
    fontWeight: "bold",
    fontSize: "0.9rem",
    textAlign: "center",
    position: "absolute",
    lineHeight: "1.5rem",
    background: "#FFD3D1",
    borderTopRightRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  getMoreCredits: {
    color: "white",
    minWidth: "5rem",
    marginTop: "0.5rem",
    fontSize: "0.9em",
    padding: "0.25rem",
    cursor: "pointer",
    fontWeight: "bold",
    borderRadius: "5px",
    textAlign: "center",
    alignItems: "center",
    paddingBottom: "0.2rem",
    justifyContent: "center",
    background: theme.customColors.greenDarker,
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  settings: state.settings,
  recorder: state.recorder,
});

export default connect(mapStateToProps, {
  createCart,
  navigateTo,
  setDidSubmit,
  setClickedNext,
  setToggleRecording,
  clearRecorderReducer,
})(RecorderManager);
