// Return type and title of snackbar notification
const getNotifSnackbar = {
  SNACK_UPDATED_USER: {
    variant: "success",
    message: "User updated!",
  },
  SNACK_EMAIL_SENT: {
    variant: "success",
    message: "Email sent successfully!",
  },
  SNACK_EMAIL_VERIFY_SENT: {
    variant: "success",
    message: "Check your email inbox!",
  },
  SNACK_EMAIL_VERIFIED: {
    variant: "success",
    message: "Account successfully verified!",
  },
  SNACK_ACCOUNT_DELETED: {
    variant: "success",
    message: "Account successfully deleted!",
  },
  SNACK_EMAIL_NOT_VERIFIED: {
    variant: "error",
    message: "Check your inbox and confirm your email first!",
  },
  SNACK_USER_TOKEN_EXPIRED: {
    variant: "error",
    message: "This token is expired!",
  },
  SNACK_EMAIL_NOT_SENT: {
    variant: "error",
    message: "Email not sent!",
  },
  SNACK_RESET_LINK: {
    variant: "success",
    message: "We've sent you a reset link.",
  },
  SNACK_PASSWORD_RESET: {
    variant: "success",
    message: "Password successfully changed!",
  },
  SNACK_LEFT_TESTIMONIAL: {
    variant: "success",
    message: "Thank you for leaving a review!",
  },
  SNACK_EMAIL_LIST_ADDED: {
    variant: "success",
    message: "Your email has been added!",
  },
  SNACK_TEXT_COPIED: {
    variant: "info",
    message: "Link copied.",
  },
  SNACK_WRONG_IMAGE: {
    variant: "error",
    message: "File not valid (must be: jpg - jpeg - png)",
  },
  SNACK_WRONG_PDF: {
    variant: "error",
    message: "File not valid (must be: pdf)",
  },
  SNACK_WRONG_FORMAT: {
    variant: "error",
    message: "File not valid (must be: png, jpg)",
  },
  SNACK_NOT_SCORECARD: {
    variant: "error",
    message: "Scorecard not recognised",
  },
  SNACK_CRITICAL_ERROR: {
    variant: "error",
    message: "Server error!",
  },
  SNACK_DEV_ERROR: {
    variant: "error",
    message: "Server error! [dev only]",
  },
  SNACK_MULTI_ACCOUNT: {
    variant: "error",
    message: "Other session detected!",
  },
  SNACK_REWARD_CLAIM: {
    variant: "success",
    message: "Congrats! Our team will contact you soon.",
  },
  SNACK_PAYMENT_CANCELLED: {
    variant: "error",
    message: "Payment cancelled. Contact team if needed",
  },
  SNACK_USER_NOT_FOUND: {
    variant: "error",
    message: "Account not recognized.",
  },
  SNACK_PROMO_EXPIRED: { variant: "error", message: "This promo has expired!" },
  SNACK_PROMO_ALREADY: { variant: "error", message: "Promo already redeemed!" },
  SNACK_SURVEY_EXPIRED: {
    variant: "error",
    message: "This survey has expired!",
  },
  SNACK_SURVEY_ALREADY: {
    variant: "error",
    message: "Survey already completed!",
  },
  SNACK_ERROR_VERIFY: {
    variant: "error",
    message: "Error 45. Contact team if needed",
    // Email is blacklisted (happens if student delete account and try to get free mock test again)
  },
};

export default getNotifSnackbar;
