import axios from "axios";

import { startLoadingSpinner } from "./loadingActions";

// @desc  Get Landing Data To Create Dynamic Home Page
// @next  Return Landing data
export const getLandingContent = (slug) => (dispatch) => {
  dispatch(startLoadingSpinner());
  return new Promise((resolve, reject) => {
    axios
      .post("/api/landing/getLandingContent", { slug })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
