import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import AudioPlayer from "./components/AudioPlayer";

import {
  setPracticeScore,
  setPracticeScoreData,
} from "../../actions/questionActions";
import { isAuthenticated } from "../../helpers/userCheck";

// Listening incorrect words exercise
function ExerciseListeningIncorrectWords({ question, didSubmit, ...props }) {
  const classes = useStyles();
  const { showAnswer } = props.practice;
  const { track, transcript, uid, correctAnswers, indexToClick } = question;

  const [selectedAnswer, setSelectedAnswer] = useState([]);

  // Trigger save score
  useEffect(() => {
    if (didSubmit) {
      saveScore();
    }
  }, [didSubmit]);

  // Save score on submit
  function saveScore() {
    const correct = [];
    // Check selected answers
    selectedAnswer.map((value) => {
      correct.push(indexToClick.includes(value));
    });

    // Get correct scores
    const correctOnly = correct.filter((correct) => correct);
    const negativeMarking = selectedAnswer.length - correctOnly.length;

    // Deduct wrong answers
    const actualScore = correctOnly.length - negativeMarking;

    // Final score
    const score = `${Math.max(actualScore, 0)}/${indexToClick.length}`;

    props.setPracticeScore({
      uid,
      score,
      successful: actualScore > indexToClick.length / 2,
      isAuthenticated: isAuthenticated(props.user),
    });

    if (negativeMarking) {
      props.setPracticeScoreData({
        positive: correctOnly.length,
        negative: negativeMarking,
      });
    }
  }

  // Add/remove selected index
  function saveSelectedWord(index) {
    if (didSubmit) return;
    if (selectedAnswer.includes(index)) {
      const oldArr = [...selectedAnswer];
      const newArr = oldArr.filter((value) => value !== index);
      setSelectedAnswer(newArr);
    } else {
      setSelectedAnswer((arr) => [...arr, index]);
    }
  }

  // Render transcript
  function renderTopic() {
    // Loop words in transcript
    return transcript.split(" ").map((word, i) => {
      const isSelected = selectedAnswer.includes(i);
      const isCorrect = isSelected && indexToClick.includes(i);
      const isWrong = isSelected && !indexToClick.includes(i);
      const isMissed = indexToClick.includes(i);
      const isReviewMissed =
        didSubmit && !showAnswer && isMissed && !isSelected; // Get correct answer

      const indexOfAnswer = indexToClick.findIndex((index) => index == i);
      const hasAnAnswer = indexOfAnswer > -1;

      return (
        <Tooltip
          key={i}
          title={
            isReviewMissed
              ? `Missed answer. Correct word was: ${correctAnswers[indexOfAnswer]}`
              : didSubmit && hasAnAnswer && isCorrect
              ? "Correct selection"
              : didSubmit && isWrong
              ? "Wrong selection"
              : ""
          }
          enterDelay={200}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            onClick={() => saveSelectedWord(i)}
            className={clsx(classes.target, {
              [classes.targetActive]: !didSubmit, // Default
              [classes.targetSelected]: isSelected && !didSubmit, // Selected
              [classes.targetCorrect]: didSubmit && isCorrect, // Rightly selected
              [classes.targetWrong]: didSubmit && isWrong, // Wrongly selected
              [classes.targetMissed]: showAnswer && isMissed && !isSelected, // Missed word
              [classes.targetMissed2]:
                didSubmit && !showAnswer && isMissed && !isSelected, // Missed word
            })}
          >
            {showAnswer && hasAnAnswer
              ? correctAnswers[indexOfAnswer] // Show real answer
              : word}
          </Typography>
        </Tooltip>
      );
    });
  }

  // Main return
  return (
    <Grid container direction="row" className={classes.screen}>
      <TaskBox uid={uid} />
      <AudioPlayer track={track} />
      <Grid item xs={12} className={classes.column}>
        {renderTopic()}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flexGrow: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    justifyContent: "center",
    alignContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  column: {
    display: "flex",
    flexWrap: "wrap",
  },
  target: {
    padding: "2px",
  },
  targetActive: {
    "&:hover ": {
      cursor: "pointer",
      backgroundColor: theme.palette.action.hover,
    },
  },
  targetSelected: {
    color: "black",
    background: theme.customColors.yellow,
    "&:hover ": {
      cursor: "pointer",
      backgroundColor: theme.customColors.yellow,
    },
  },
  targetCorrect: {
    cursor: "default",
    background: theme.customColors.answerRight,
  },
  targetWrong: {
    color: theme.customColors.red,
  },
  targetMissed: {
    color: "white",
    cursor: "default",
    background: theme.customColors.blue,
  },
  targetMissed2: {
    fontWeight: "bold",
    color: theme.customColors.blue,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  practice: state.practice,
});

export default connect(mapStateToProps, {
  setPracticeScore,
  setPracticeScoreData,
})(ExerciseListeningIncorrectWords);
