// Safe save to local storage
const saveToLocalStorage = (key, data) => {
  try {
    localStorage.setItem(key, data);
  } catch (e) {}
};

// Safe save to session storage
const saveToSessionStorage = (key, data) => {
  try {
    sessionStorage.setItem(key, data);
  } catch (e) {}
};

export { saveToLocalStorage, saveToSessionStorage };
