import React from "react";

import {
  ROUTE_USER,
  ROUTE_REWARD,
  ROUTE_PRACTICE,
  ROUTE_MOCK_TEST,
  NOTIF_SPEAKING_1_4,
  NOTIF_SPEAKING_1_2,
  NOTIF_SPEAKING_3_4,
  NOTIF_SPEAKING_4_4,
  NOTIF_NEW_REFERRAL,
  NOTIF_BOUGHT_ADD_ON,
  NOTIF_DID_SUBSCRIBED,
  NOTIF_COMPLETE_SURVEY,
} from "./types";

import FaceIcon from "@material-ui/icons/Face";
import DashboardIcon from "@material-ui/icons/Dashboard";

// Return notification object based on id
const getNotification = (notificationId) => {
  if (notificationId === NOTIF_COMPLETE_SURVEY) {
    return {
      type: "Success",
      title:
        "Survey completed. You have received 1 full mock test credit as a reward!",
      redirectLink: ROUTE_MOCK_TEST,
      icon: <DashboardIcon />,
      color: "#65ab00",
    };
  } else if (notificationId === NOTIF_DID_SUBSCRIBED) {
    return {
      type: "Success",
      title: "Purchase successful! You can start practicing.",
      redirectLink: ROUTE_PRACTICE,
      icon: <DashboardIcon />,
      color: "#65ab00",
    };
  } else if (notificationId === NOTIF_BOUGHT_ADD_ON) {
    return {
      type: "Success",
      title: "Purchase successful. Thank you!",
      redirectLink: ROUTE_MOCK_TEST,
      icon: <DashboardIcon />,
      color: "#65ab00",
    };
  } else if (notificationId === NOTIF_NEW_REFERRAL) {
    return {
      type: "Success",
      title: "Well done! You got a new referral!",
      redirectLink: ROUTE_REWARD,
      icon: <FaceIcon />,
      color: "#65ab00",
    };
  } else if (notificationId === NOTIF_SPEAKING_1_4) {
    return {
      type: "Success",
      title: "You have reached 25% of your speaking limit!",
      redirectLink: ROUTE_USER,
      icon: <FaceIcon />,
      color: "#65ab00",
    };
  } else if (notificationId === NOTIF_SPEAKING_1_2) {
    return {
      type: "Success",
      title: "You have reached 50% of your speaking limit!",
      redirectLink: ROUTE_USER,
      icon: <FaceIcon />,
      color: "#f2ba2d",
    };
  } else if (notificationId === NOTIF_SPEAKING_3_4) {
    return {
      type: "Success",
      title: "You have reached 75% of your speaking limit!",
      redirectLink: ROUTE_USER,
      icon: <FaceIcon />,
      color: "#f2ba2d",
    };
  } else if (notificationId === NOTIF_SPEAKING_4_4) {
    return {
      type: "Success",
      title: "You have reached your AI speaking limit!",
      redirectLink: ROUTE_USER,
      icon: <FaceIcon />,
      color: "#DA3025",
    };
  } else {
    return {
      type: "",
      title: "",
      redirectLink: null,
      icon: null,
      color: "",
    };
  }
};

export default getNotification;
