import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MockTestIcon from "@material-ui/icons/LaptopMac";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import FaceIcon from "@material-ui/icons/Face";
import MenuIcon from "@material-ui/icons/Menu";
import PracticeIcon from "@material-ui/icons/ListAlt";
import MobileAppIcon from "@material-ui/icons/GetApp";
import PersonAddAltIcon from "@material-ui/icons/PersonAddOutlined";

import { ROUTE_SIGNUP } from "../../helpers/types";
import { getNavbar } from "../../helpers/getNavbar";
import { navigateTo } from "../../actions/navigationActions";

function NavbarCollapsedMenu({ largeDevice, ...props }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function openMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function goTo(toLink) {
    setAnchorEl(null);
    props.navigateTo(toLink);
  }

  return (
    <div className={classes.linkWrapper}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={openMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: 200 } }}
      >
        {getNavbar(largeDevice).map((item) => {
          const { name, toLink } = item;
          const isSelected = window.location.pathname === toLink;
          return (
            <MenuItem
              key={name}
              onClick={() => goTo(toLink)}
              className={clsx(classes.link, {
                [classes.linkSelected]: isSelected,
                [classes.linkHighlight]: toLink === ROUTE_SIGNUP,
              })}
            >
              <ListItemIcon
                style={{ minWidth: "3rem", color: isSelected ? "white" : "" }}
              >
                {name === "Practice" ? (
                  <PracticeIcon />
                ) : name === "Mock Tests" ? (
                  <MockTestIcon />
                ) : name === "PTE Mobile app" ? (
                  <MobileAppIcon />
                ) : name === "Login" ? (
                  <FaceIcon />
                ) : (
                  <PersonAddAltIcon />
                )}
              </ListItemIcon>

              <ListItemText
                disableTypography
                selected={isSelected}
                primary={
                  <Typography type="body2" style={{ fontWeight: "bold" }}>
                    {name}
                  </Typography>
                }
              />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    display: "none",
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    margin: theme.spacing(0.5, 0.5),
    padding: theme.spacing(1, 1),
    minWidth: "4em",
    "&:hover ": {
      backgroundColor: theme.palette.action.hover,
      color: "black",
      borderRadius: "5px",
    },
  },
  linkSelected: {
    background: theme.customColors.blue,
    color: "white",
    borderRadius: "5px",
  },
  linkHighlight: {
    border: `2px solid ${theme.customColors.blue}`,
    borderRadius: "5px",
  },
}));

export default connect(null, {
  navigateTo,
})(NavbarCollapsedMenu);
