import React, { useState } from "react";
import clsx from "clsx";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Highlighter from "react-highlight-words";

import AlertSkillOfficial from "./AlertSkillOfficial";
import PulseComponent from "../common/PulseComponent";
import AudioPlayer from "../screenExercise/components/AudioPlayer";
import AudioPlayback from "../screenExercise/components/AudioPlayback";

import {
  ENABLING_FORM,
  ENABLING_FINAL,
  ENABLING_CONTENT,
  STEPS_SEE_DETAILS,
} from "../../helpers/types";
import {
  getMaxTaskScore,
  getMaxSkillScore,
  getEnablingEnablingComment,
} from "../../helpers/getEnablingSkillData";
import highlightFullWords from "../../helpers/highlightFullWords";
import { getExerciseSettings } from "../../helpers/getExerciseData";

// Modal alert with table analysis
function AlertSkillAnalysis({
  uid,
  score,
  htmlJsx,
  keywords,
  sampleAnswer,
  speechTextArray,
  hideFindChunks = false,
  showHighlightAsSample = false,
  ...props
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("xs")); // Full screen on mobile

  // Check if no word recorded
  const emptyRecording = htmlJsx === "<p></p>";

  // Loop Listening summarize text skills
  const rows = getExerciseSettings(uid).skills.map((skill) =>
    createData(
      skill,
      `${score[skill]}/${getMaxSkillScore(skill, uid)}`,
      getEnablingEnablingComment({ score, skill, uid })
    )
  );

  // Create data rows
  function createData(skill, score, suggestion) {
    return { skill, score, suggestion };
  }

  // See details button (needed for pulse)
  const detailsButton = () => {
    return <Typography className={classes.button}>See details</Typography>;
  };

  return (
    <div className={classes.score}>
      <Typography
        variant="h4"
        style={{ fontSize: "2rem", marginRight: "1rem" }}
      >
        {`Score: ` + `${score[ENABLING_FINAL]}/${getMaxTaskScore(uid)}`}
      </Typography>
      {/* See details button to open modal */}
      <div
        className={classes.pulseContainer}
        onClick={() => {
          setOpen(true);
          props.setOpenedAlert(true);
        }}
      >
        <PulseComponent component={detailsButton} trigger={STEPS_SEE_DETAILS} />
      </div>
      <Dialog
        open={open}
        maxWidth="lg"
        fullScreen={fullScreen}
        onClose={() => {
          setOpen(false);
          props.setOpenedAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ overflow: "none" }}
      >
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
          {"AI Score analysis"}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {/* Table part */}
          <Paper elevation={0} className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.row}>
                  <TableCell
                    className={classes.tableCell}
                    style={{ minWidth: "20%" }}
                  >
                    Enabling skill
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: "10%" }}
                  >
                    Score
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: "70%" }}
                  >
                    Comment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  const isScore0 = score[row.skill] === 0;
                  const isForm0 =
                    (row.skill === ENABLING_FORM && isScore0) ||
                    (row.skill === ENABLING_CONTENT &&
                      isScore0 &&
                      uid.includes("WWM"));
                  return (
                    <TableRow className={classes.row} key={row.skill}>
                      <TableCell
                        className={clsx(classes.cellSkill, {
                          [classes.cellSkillRed]: isForm0,
                        })}
                      >
                        {row.skill}
                        {/* Modal alert for official guide */}
                        <AlertSkillOfficial
                          uid={uid}
                          index={index}
                          skill={row.skill}
                          isScore0={isScore0}
                          score={
                            getMaxTaskScore(uid) === "90" // For speaking skills
                              ? Math.round(
                                  (score[row.skill] *
                                    ((uid.includes("SRS") ||
                                      uid.includes("SRA")) && // Content in SRS/SRA = 3 points max
                                    row.skill === ENABLING_CONTENT
                                      ? 3
                                      : 5)) /
                                    90
                                )
                              : score[row.skill]
                          }
                        />
                      </TableCell>
                      <TableCell className={classes.cellScore}>
                        {row.score}
                      </TableCell>
                      <TableCell className={classes.cellSuggestion}>
                        {row.suggestion}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
          {/* Actual answer */}
          <Typography style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
            Your answer:
          </Typography>
          <AudioPlayback playerId="audio__playback__skill" />
          {keywords && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classes.keywordCircle} />
              <Typography style={{ fontSize: "0.9rem", paddingTop: "0.1rem" }}>
                Relevant keywords
              </Typography>
            </div>
          )}

          {!keywords && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classes.keywordCircle} />
              <Typography style={{ fontSize: "0.9rem", paddingTop: "0.1rem" }}>
                Correct words
              </Typography>
              <div className={classes.keywordCircleRed} />
              <Typography style={{ fontSize: "0.9rem", paddingTop: "0.1rem" }}>
                Incorrect words
              </Typography>
            </div>
          )}

          {!emptyRecording ? (
            <Highlighter
              autoEscape={true}
              className={
                keywords
                  ? classes.highlighterBaseBlack
                  : classes.highlighterBaseRed
              }
              highlightClassName={classes.highlightedText}
              textToHighlight={speechTextArray.join(" ")}
              searchWords={
                keywords
                  ? keywords?.concat(["MULTI_KEYWORD"]) // Highlight multiple keywords (describe image)
                  : sampleAnswer.split(" ")
              }
              findChunks={hideFindChunks ? undefined : highlightFullWords}
            />
          ) : (
            <div style={{ fontStyle: "italic" }}>Nothing recorded</div>
          )}

          {/* Sample answer */}
          <div
            style={{
              display: "flex",
              marginTop: "2rem",
              flexDirection: "column",
            }}
          >
            <Typography style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              {props?.track ? "Real audio:" : "Sample answer:"}
            </Typography>
            {props?.track && (
              <AudioPlayer
                shortMargin={true}
                track={props?.track}
                playerId={"audio__player__skill"}
              />
            )}
          </div>
          {showHighlightAsSample ? (
            <Highlighter
              autoEscape={true}
              searchWords={speechTextArray}
              textToHighlight={sampleAnswer}
              className={classes.highlighterBaseRed}
              highlightClassName={classes.highlightedTextSample}
              findChunks={hideFindChunks ? undefined : highlightFullWords}
            />
          ) : (
            <Typography
              style={{
                paddingTop: "0.25rem",
                fontSize: "1.2rem",
                lineHeight: "1.7rem",
                whiteSpace: "pre-line",
              }}
            >
              {sampleAnswer}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              props.setOpenedAlert(false);
            }}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  score: {
    flex: 0.2,
    display: "flex",
    padding: "0 1rem",
    alignItems: "center",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: "0.5rem",
      width: "calc(100% - 1rem)",
    },
  },
  button: {
    color: "white",
    borderRadius: "5px",
    padding: "0.3em 1.5em",
    background: theme.customColors.blueDark,
    "&:hover ": {
      cursor: "pointer",
      transform: "scale(1.01)",
      opacity: theme.customVars.hoverOpacity,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "8px !important",
    },
  },
  dialogTitle: {
    paddingBottom: "0px !important",
    [theme.breakpoints.down("xs")]: {
      padding: "8px !important",
    },
  },
  root: {
    overflowX: "auto",
    marginBottom: "1rem",
    width: "calc(100% - 2px)",
    border: "1px solid lightgray",
  },
  table: {
    minWidth: 700,
    [theme.breakpoints.down("xs")]: {
      minWidth: 100,
    },
  },
  tableCell: {
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
    },
  },
  row: {
    display: "flex",
    flex: 1,
  },
  cellSkill: {
    display: "flex",
    minWidth: "20%",
    maxWidth: "20%",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      padding: "0.5rem",
      overflow: "hidden",
      whiteSpace: "nowrap",
      flexDirection: "column",
      alignItems: "flex-start",
      textOverflow: "ellipsis",
      justifyContent: "center",
    },
  },
  cellSkillRed: {
    fontWeight: "bold",
    color: theme.customColors.red,
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
    },
  },
  cellScore: {
    width: "10%",
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
    },
  },
  cellSuggestion: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
    },
  },
  pulseContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  jsxParser: {
    padding: "0.75em 0",
    fontSize: "1.2rem",
    lineHeight: "1.7rem",
  },
  highlightedText: {
    fontSize: "1.2rem",
    lineHeight: "1.7rem",
    whiteSpace: "pre-line",
    color: theme.customColors.greenDarker,
    background: theme.palette.background.paper,
  },
  highlightedTextSample: {
    fontSize: "1.2rem",
    lineHeight: "1.7rem",
    whiteSpace: "pre-line",
    color: theme.customColors.blackWhite,
    background: theme.palette.background.paper,
  },
  highlighterBaseRed: {
    color: "red",
    fontSize: "1.2rem",
    lineHeight: "1.7rem",
    whiteSpace: "pre-line",
  },
  highlighterBaseBlack: {
    fontSize: "1.2rem",
    lineHeight: "1.7rem",
    whiteSpace: "pre-line",
    color: theme.customColors.blackWhite,
  },
  highlighterBaseSample: {
    color: "red",
    fontSize: "1.2rem",
    lineHeight: "1.7rem",
    whiteSpace: "pre-line",
  },
  keywordCircle: {
    width: "0.8rem",
    height: "0.8rem",
    marginRight: "0.4rem",
    borderRadius: "0.8rem",
    background: theme.customColors.greenDarker,
  },
  keywordCircleRed: {
    width: "0.8rem",
    height: "0.8rem",
    marginLeft: "2rem",
    marginRight: "0.4rem",
    borderRadius: "0.8rem",
    background: "red",
  },
}));

export default AlertSkillAnalysis;
