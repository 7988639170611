import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Filter from "bad-words";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import AudioPlayer from "./components/AudioPlayer";
import WarningMobile from "./components/WarningMobile";
import RecorderManager from "./components/RecorderManager";
import AlertSkillAnalysis from "../alerts/AlertSkillAnalysis";

import isEmpty from "../../helpers/isEmpty";
import { isDesktop } from "../../helpers/getBrowserData";
import { isAuthenticated } from "../../helpers/userCheck";

import { getImageFile } from "../../actions/awsActions";
import { setPracticeScore } from "../../actions/questionActions";

const filter = new Filter();

// Speaking Retell Lecture
function ExerciseSpeakingRetell({ question, didSubmit, ...props }) {
  const classes = useStyles();

  const { score } = props.analysis;
  const { speechText, forceShowAnswer } = props.recorder;
  const { correctAnswer, transcript, track, keywords, image, uid } = question;

  const [fileUrl, setFileUrl] = useState(null);
  const [openedAlert, setOpenedAlert] = useState(false);

  const speechTextArray = !isEmpty(speechText[0])
    ? filter.clean(speechText[0]).split(" ")
    : [];

  // Fetch image
  useEffect(() => {
    getImageUrl();
  }, [image]);

  const getImageUrl = async () => {
    try {
      const url = await props.getImageFile(image);
      setFileUrl(url);
    } catch (error) {}
  };

  // Save score
  useEffect(() => {
    if (score && !isEmpty(speechText)) {
      saveScore();
    }
  }, [score, speechText]);

  // Save score on submit
  function saveScore() {
    const finalScore = `${score.Final}/90`;
    props.setPracticeScore({
      uid,
      score: finalScore,
      successful: score.Final > 5,
      isAuthenticated: isAuthenticated(props.user),
    });
  }

  return (
    <Grid container direction="row" className={classes.screen}>
      {!isDesktop() && <WarningMobile />}
      <div className={classes.taskMargin}>
        <TaskBox uid={uid} />
      </div>
      <AudioPlayer
        track={track}
        playerId={"audio__player"}
        openedAlert={openedAlert}
      />
      <Grid item xs={12} className={classes.column}>
        <img
          width="400"
          height="400"
          className={classes.image}
          alt="PTE success retell lecture"
          src={fileUrl}
          onError={
            () =>
              setFileUrl(
                `https://pineapplestudio.com.au/wp-content/uploads/pte-platform/${image}.png`
              ) // Backup image
          }
        />
      </Grid>
      {((didSubmit && score) || forceShowAnswer) && (
        <Grid item xs={12}>
          <AlertSkillAnalysis
            uid={uid}
            score={score}
            keywords={keywords}
            setOpenedAlert={setOpenedAlert}
            speechTextArray={speechTextArray}
            sampleAnswer={correctAnswer || transcript}
            htmlJsx={`<p>${speechTextArray.join(" ")}</p>`}
          />
        </Grid>
      )}
      <RecorderManager question={question} openedAlert={openedAlert} />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    width: "100%",
    display: "flex",
    margin: "1rem 0",
    maxWidth: "900px",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
      margin: "0.5rem 0",
    },
  },
  column: {
    justifyContent: "center",
    margin: "1rem 0",
    display: "flex",
  },
  taskMargin: {
    padding: "0 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  image: {
    width: "auto",
    height: "auto",
    maxWidth: "65vw",
    maxHeight: "65vh",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90vw",
      maxHeight: "60vh",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "95vw",
      maxHeight: "50vh",
    },
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
  recorder: state.recorder,
  analysis: state.analysis,
});

export default connect(mapStateToProps, { getImageFile, setPracticeScore })(
  ExerciseSpeakingRetell
);
