import React, { useState } from "react";

import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

// Highlight word in text analysis
function HighlightWord({ word, hint, category, suggestions }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography
        component="span"
        aria-haspopup="true"
        color="textPrimary"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        aria-owns={open ? "mouse-over-popover" : undefined}
        className={
          category == "grammargrammar" ? classes.grammar : classes.spelling
        }
      >
        {/* Actual word cell */}
        {word}
      </Typography>
      {/* Popover to show grammar/spelling issue */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        id="mouse-over-popover"
        className={classes.popover}
        classes={{ paper: classes.paper }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <>
          <Typography style={{ fontWeight: "bold" }}>
            {category == "grammargrammar" ? "Grammar error" : "Spelling error"}
          </Typography>
          <Divider style={{ margin: "0.25em 0 0.5em 0" }} />
          <Typography>{hint}</Typography>
          {suggestions && (
            <>
              <Typography style={{ marginTop: "0.5em", fontWeight: "bold" }}>
                {"Suggestions: "}
              </Typography>
              {suggestions.split(",").map((suggestion) => {
                return <Typography>{suggestion}</Typography>;
              })}
            </>
          )}
        </>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "10em",
    padding: "1em",
  },
  grammar: {
    fontSize: theme.typography.exerciseFontSize,
    color: theme.customColors.red,
    textDecoration: "underline",
    cursor: "default",
  },
  spelling: {
    fontSize: theme.typography.exerciseFontSize,
    textDecoration: "underline",
    color: "orange",
    cursor: "default",
  },
}));

export default HighlightWord;
