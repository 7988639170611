import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import { makeStyles } from "@material-ui/core/styles";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import TextFieldGroup from "../common/TextFieldGroup";

import {
  ROUTE_PRACTICE,
  ROUTE_STATISTICS,
  STEPS_DID_ONBOARDING,
  STEPS_LEFT_TESTIMONIAL,
  STEPS_SHOULD_TESTIMONIAL,
  STEPS_DID_ONBOARDING_PRACTICE,
} from "../../helpers/types";
import { doesUserHave } from "../../helpers/userCheck";
import getRandomFromArray from "../../helpers/getRandomFromArray";

import {
  leaveRating,
  leaveTestimonial,
  refuseTestimonial,
  postponeTestimonial,
} from "../../actions/userActions";
import { clearErrors } from "../../actions/errorActions";
import { sendContactEmail } from "../../actions/emailActions";

// List of URL to trigger alert
const triggerUrl = [ROUTE_STATISTICS, ROUTE_PRACTICE];

// Modal alert to leave testimonial TODO better?
function AlertTestimonial(props) {
  const classes = useStyles();
  const { user, errors } = props;
  const { pathname } = props.location;

  const titleSuggestions = [
    // Will pick random title suggestion
    "Love it!",
    "Very helpful",
    "Easy to use!",
    "Well organized",
    "Great platform!",
    "Great material!",
    "Best PTE website!",
    "Good PTE materials",
  ];

  // Prevent bad reviews from displaying immediately
  const filterWords = [
    "scam",
    "fraud",
    "irrespons",
    "irespons",
    "hate",
    "poor",
    "worst",
    "shit",
    "fuck",
    "complain",
    "horrible",
    "wast",
    ".com",
    "www",
    "apeuni",
    "ape uni",
    "money",
    "refund",
    "pearson",
    "legal",
    "police",
    "not work",
    "not good",
    "bakwas",
  ];

  const [quote, setQuote] = useState("");
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [testimonialId, setTestimonialId] = useState("");
  const [contactMode, setContactMode] = useState(false);
  const [testimonialMode, setTestimonialMode] = useState(false);

  // Clear previous errors when typing
  useEffect(() => {
    props.clearErrors();
  }, [quote, header]);

  // Check if display modal
  useEffect(() => {
    if (
      doesUserHave(user, STEPS_SHOULD_TESTIMONIAL) &&
      !doesUserHave(user, STEPS_LEFT_TESTIMONIAL) &&
      doesUserHave(user, STEPS_DID_ONBOARDING) && // Prevent double modal display
      doesUserHave(user, STEPS_DID_ONBOARDING_PRACTICE) && // Prevent double modal display
      triggerUrl.includes(pathname)
    ) {
      if (user.postponeReview < Date.now()) {
        setOpen(true);
      }
    }
  }, [user, pathname]);

  // Save rating (stars only)
  async function leaveRating() {
    if (rating >= 4) {
      setTestimonialMode(true);
    } else {
      setContactMode(true);
    }

    const res = await props.leaveRating({ rating });
    setTestimonialId(res._id);
  }

  // Save testimonial (when 4 or 5 stars only)
  async function leaveTestimonial() {
    var isApproved = true;

    if (
      filterWords.some((v) => quote?.toLowerCase()?.includes(v)) ||
      filterWords.some((v) => header?.toLowerCase()?.includes(v))
    ) {
      // Prevent bad reviews from displaying immediately
      isApproved = false;
    }

    await props.leaveTestimonial({ quote, header, isApproved, testimonialId });
    setOpen(false);
  }

  // Send contact form message
  async function submitForm() {
    await props.sendContactEmail({
      message,
      firstName: user?.firstName,
      email: user?.email.toLowerCase(),
    });
    setMessage("");
    setOpen(false);
    props.refuseTestimonial();
  }

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={"sm"}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={
          testimonialMode || contactMode
            ? () => {
                setOpen(false);
                props.refuseTestimonial();
              }
            : null
        }
      >
        {/* Logo and title */}
        <div className={classes.logoContainer}>
          <img
            className={classes.logo}
            src="https://pineapplestudio.com.au/pte_success_v2/images/layout/pte_success_logo_v2.svg"
          />
          <div className={classes.logoTitle}> PTE Success</div>
        </div>

        {/* Title */}
        <div className={classes.title}>
          {contactMode
            ? "How can we help you?"
            : !testimonialMode
            ? "How would you rate us?"
            : "Thank you for the positive rating!"}
        </div>

        <DialogContent>
          {contactMode ? (
            <div style={{ textAlign: "center" }}>
              We would love to hear your thoughts or if you have encountered any
              problems.
            </div>
          ) : !testimonialMode ? (
            <DialogContentText id="alert-dialog-description">
              Let other students know what you think about the platform and{" "}
              <b>get 1 extra sectional mock test credit!</b>
            </DialogContentText>
          ) : (
            <div style={{ textAlign: "center" }}>
              You can also write a review if you wish :)
            </div>
          )}

          {/* Stars */}
          <div className={classes.rating}>
            <Rating
              value={rating}
              name="hover-feedback"
              style={{ fontSize: "4.5rem" }}
              disabled={testimonialMode || contactMode}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
          </div>

          {/* Textfields for review */}
          {testimonialMode && (
            <>
              <TextFieldGroup
                label="Header"
                name="header"
                value={header}
                shrinkLabel={true}
                error={errors.header}
                onKeyPress={leaveTestimonial}
                onChange={(e) => setHeader(e.target.value)}
                info="Title must be between 5 and 20 characters"
                placeholder={getRandomFromArray(titleSuggestions)}
              />
              <TextFieldGroup
                rows={8}
                value={quote}
                label="Message"
                multiline={true}
                shrinkLabel={true}
                error={errors.quote}
                info="250 characters max."
                onKeyPress={leaveTestimonial}
                onChange={(e) => setQuote(e.target.value)}
                placeholder="Tell us what you like about the platform..."
              />
            </>
          )}
          {contactMode && (
            <div className={classes.form}>
              <TextFieldGroup
                label="First name"
                name="firstname"
                type="firstname"
                disabled={true}
                shrinkLabel={true}
                value={user?.firstName}
              />
              <TextFieldGroup
                label="Email Address"
                name="email"
                type="email"
                disabled={true}
                shrinkLabel={true}
                value={user?.email}
              />
              <TextFieldGroup
                label="Message"
                name="message"
                type="message"
                value={message}
                multiline={true}
                rows={8}
                shrinkLabel={true}
                error={errors.message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          )}
        </DialogContent>

        {/* Bottom buttons */}
        <DialogActions>
          <div style={{ flexGrow: 1 }}>
            <Button
              style={{ color: testimonialMode ? "red" : "" }}
              variant={testimonialMode ? "primary" : "outline"}
              onClick={() => {
                setOpen(false);
                props.refuseTestimonial();
              }}
            >
              No
            </Button>
          </div>
          {!testimonialMode && !contactMode && (
            <Button
              color="secondary"
              variant="outline"
              onClick={() => {
                setOpen(false);
                props.postponeTestimonial();
              }}
            >
              Later
            </Button>
          )}

          <Button
            color="primary"
            disabled={!rating}
            variant="contained"
            onClick={
              contactMode
                ? submitForm
                : testimonialMode
                ? leaveTestimonial
                : leaveRating
            }
          >
            {contactMode ? "Send message" : "SUBMIT"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: "flex",
    margin: "1rem 0",
    alignSelf: "center",
    alignItems: "center",
  },
  logo: {
    width: "3rem",
    marginRight: "1rem",
  },
  logoTitle: {
    fontWeight: 700,
    fontSize: "2rem",
  },
  title: {
    margin: "1rem 0",
    fontSize: "1.7rem",
    textAlign: "center",
  },
  rating: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    width: "100%",
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  clearErrors,
  leaveRating,
  sendContactEmail,
  leaveTestimonial,
  refuseTestimonial,
  postponeTestimonial,
})(withRouter(AlertTestimonial));
