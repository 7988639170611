import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import McqCell from "./components/McqCell";

import {
  setPracticeScore,
  setPracticeScoreData,
} from "../../actions/questionActions";
import { isAuthenticated } from "../../helpers/userCheck";

// Reading single answer exercise
function ExerciseReadingMultiple({ question, didSubmit, ...props }) {
  const classes = useStyles();
  const { topic, uid, highlight = [], multichoice, correctIndexes } = question;

  const [selectedAnswer, setSelectedAnswer] = useState([]);

  // Trigger save score
  useEffect(() => {
    if (didSubmit) {
      saveScore();
    }
  }, [didSubmit]);

  // Save score on submit
  function saveScore() {
    const correct = [];
    // Get correct scores
    for (var i in selectedAnswer) {
      if (correctIndexes.includes(selectedAnswer[i])) {
        correct.push(selectedAnswer[i]);
      }
    }

    // Get negative scores
    const negativeMarking = selectedAnswer.length - correct.length;

    // Deduct wrong answers
    const actualScore = correct.length - negativeMarking;

    // Final score
    const score = `${Math.max(actualScore, 0)}/${correctIndexes.length}`;

    props.setPracticeScore({
      uid,
      score,
      successful: actualScore > correctIndexes.length / 2,
      isAuthenticated: isAuthenticated(props.user),
    });

    if (negativeMarking) {
      props.setPracticeScoreData({
        positive: correct.length,
        negative: negativeMarking,
      });
    }
  }

  // Add/remove from selected answers
  function onSelectCell(value) {
    if (selectedAnswer.includes(value)) {
      setSelectedAnswer(selectedAnswer.filter((answer) => answer !== value));
    } else {
      setSelectedAnswer([...selectedAnswer, value]);
    }
  }

  return (
    <Grid container direction="row" className={classes.screen}>
      <Grid item xs={12} md={6} className={classes.column}>
        <Highlighter
          highlightClassName={classes.highlight}
          className={classes.text}
          searchWords={didSubmit ? highlight : []}
          autoEscape={true}
          textToHighlight={topic}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.column}>
        <TaskBox uid={uid} />
        <Typography className={classes.question}>
          {question.question}
        </Typography>
        {multichoice.map((suggestion, i) => {
          return (
            <McqCell
              key={i}
              index={i}
              isMultiple={true}
              didSubmit={didSubmit}
              suggestion={suggestion}
              correctIndex={correctIndexes}
              selectedAnswer={selectedAnswer}
              onSelectCell={(value) => onSelectCell(parseInt(value))}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    width: "100%",
    display: "flex",
    marginTop: "1.5rem",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2.5rem",
    },
  },
  column: {
    padding: "0 1rem",
    marginTop: "1em",
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "65vh",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
      maxHeight: "none",
      padding: "0 0.5rem",
    },
  },
  question: {
    margin: "2rem 0",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0",
    },
  },
  text: {
    whiteSpace: "pre-line",
    lineHeight: "1.5rem",
    fontSize: "1rem",
  },
  highlight: {
    background: theme.customColors.answerRight,
    color: theme.customColors.blackWhite,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  setPracticeScore,
  setPracticeScoreData,
})(ExerciseReadingMultiple);
