import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";

import { isAuthenticated } from "../../helpers/userCheck";
import { setPracticeScore } from "../../actions/questionActions";
import { cleanString, occurrences } from "../../helpers/stringHelper";

// Reading fill in blanks exercise
function ExerciseReadingFillBlanks({ question, didSubmit, ...props }) {
  const classes = useStyles();
  const { showAnswer } = props.practice;
  const { topic, uid, multichoice, correctAnswers } = question;

  const [dropText, setDropText] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // Save number of targets to selectedAnswer
  useEffect(() => {
    const numberOfTarget = occurrences(topic, "___", false) / 2;
    setSelectedAnswer(Array(numberOfTarget).fill(""));
  }, []);

  // Trigger save score
  useEffect(() => {
    if (didSubmit) {
      saveScore();
    }
  }, [didSubmit]);

  // Save score on submit
  function saveScore() {
    const correct = [];
    // Get correct scores
    for (var i in selectedAnswer) {
      correct.push(selectedAnswer[i].trim() == correctAnswers[i].trim());
    }

    const actualScore = correct.filter((correct) => correct);

    // Final score
    const score = `${actualScore.length}/${correctAnswers.length}`;

    props.setPracticeScore({
      uid,
      score,
      successful: actualScore.length > correctAnswers.length / 2,
      isAuthenticated: isAuthenticated(props.user),
    });
  }

  // Render topic
  function renderTopic() {
    // Loop words in topic
    return topic.split(" ").map((word, i) => {
      if (word.includes("__")) {
        // Create drop targets
        const id = parseInt(cleanString(word)) - 1;
        return <Target id={id} />;
      } else {
        // Create normal words
        return (
          <Typography
            key={i}
            variant="body1"
            color="textPrimary"
            style={{ padding: "2px" }}
          >
            {word}
          </Typography>
        );
      }
    });
  }

  // Paste suggestion to target and clear dropText
  function pasteToTarget(id) {
    if (didSubmit) return;
    const newArr = [...selectedAnswer];
    newArr[id] = dropText;
    setSelectedAnswer(newArr);
    setDropText("");
  }

  if (!selectedAnswer) return null;

  return (
    <Grid container direction="row" className={classes.screen}>
      <TaskBox uid={uid} />
      <div className={classes.topicContainer}>{renderTopic()}</div>
      <div className={classes.suggestionContainer}>
        {multichoice.map((suggestion, i) => {
          const isSelected = selectedAnswer.includes(suggestion);
          const isCopied = dropText == suggestion;
          return (
            <div
              key={`suggestion_${i}`}
              style={{ cursor: didSubmit ? "default" : "grab" }}
              className={clsx(classes.suggestion, {
                [classes.suggestionSelected]: isSelected,
                [classes.suggestionCopied]: isCopied,
              })}
              onMouseDown={(e) => {
                if (didSubmit) return;
                setDropText(e.currentTarget.textContent);
              }} // Copy word
              onDragEnd={(e) => setDropText("")} // Erase dropText
              onTouchStart={(e) => {
                if (didSubmit) return;
                setDropText(e.currentTarget.textContent);
              }} // Copy word
              draggable={!didSubmit}
            >
              {suggestion}
            </div>
          );
        })}
      </div>
    </Grid>
  );

  // Target component
  function Target({ id }) {
    const hasWord = selectedAnswer[id];
    const isCorrect = selectedAnswer[id].trim() == correctAnswers[id].trim();
    return (
      <div
        key={`target_${id}`}
        className={clsx(classes.target, {
          [classes.targetCorrect]: (didSubmit && isCorrect) || showAnswer,
          [classes.targetWrong]: didSubmit && !isCorrect && !showAnswer,
        })}
        style={{
          cursor: didSubmit
            ? "default"
            : dropText
            ? "copy"
            : hasWord
            ? "grab"
            : "default",
        }}
        onDragOver={(e) => e.preventDefault()} // Trick for onDrop
        onMouseDown={(e) => pasteToTarget(id)}
        onDrop={(e) => pasteToTarget(id)}
      >
        {showAnswer ? correctAnswers[id] : selectedAnswer[id]}
      </div>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    width: "100%",
    padding: "1rem",
    display: "flex",
    maxWidth: "1080px",
    marginTop: "1.5rem",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      padding: "0.5rem",
      marginTop: "2rem",
    },
  },
  topicContainer: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    padding: "1rem 0",
    maxHeight: "70vh",
    overflowY: "auto",
    alignContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem 0",
    },
  },
  suggestionContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    minHeight: "7rem",
    background: "#D8E5F9",
  },
  suggestion: {
    fontWeight: 500,
    display: "flex",
    minWidth: "5rem",
    height: "1.8rem",
    margin: "0.5rem",
    borderRadius: "3px",
    alignItems: "center",
    padding: "0 0.25rem",
    justifyContent: "center",
    border: "solid 1px black",
    background: theme.customColors.whiteDarkBlue,
    [theme.breakpoints.down("xs")]: {
      minWidth: "4rem",
      padding: "0 0.25rem",
    },
  },
  suggestionSelected: {
    color: "grey",
    border: "solid 1px grey",
    background: "lightgray",
  },
  suggestionCopied: {
    color: "black",
    border: "solid 1px grey",
    background: theme.customColors.blue,
  },
  target: {
    color: "black",
    fontWeight: 500,
    display: "flex",
    minWidth: "7rem",
    fontSize: "1rem",
    background: "white",
    borderRadius: "3px",
    alignItems: "center",
    padding: "0 0.25rem",
    margin: "0.1rem 0.5rem",
    justifyContent: "center",
    border: "solid 1px black",
    [theme.breakpoints.down("xs")]: {
      minWidth: "4rem",
      padding: "0 0.25rem",
    },
  },
  targetCorrect: {
    background: theme.customColors.answerRight,
    color: `${theme.customColors.blackWhite}!important`,
    border: `solid 1px ${theme.customColors.greenDarker}`,
  },
  targetWrong: {
    background: theme.customColors.answerWrong,
    border: `solid 1px ${theme.customColors.red}`,
    color: `${theme.customColors.blackWhite}!important`,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
  practice: state.practice,
});

export default connect(mapStateToProps, { setPracticeScore })(
  ExerciseReadingFillBlanks
);
