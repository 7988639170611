import React from "react";
import { createRoot } from "react-dom/client";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import "../src/css/app.css";
import App from "./App";
import isDevMode from "./helpers/isDevMode";

// Hide devtools and redux entries
if (!isDevMode()) {
  disableReactDevTools();
}

// Get root div (index.html) to build App
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
