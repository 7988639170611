import React, { useState } from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import InfoIcon from "@material-ui/icons/Info";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { getEnablingOfficialGuide } from "../../helpers/getEnablingSkillData";

// Modal alert with official guide skill requirement
function AlertSkillOfficial({ uid, skill, isScore0, score }) {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const details = getEnablingOfficialGuide(skill, uid);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  if (!details) return null;

  return (
    <>
      <div className={classes.iconContainer}>
        <InfoIcon
          onClick={() => setOpen(true)}
          className={clsx(classes.infoSkill, {
            [classes.infoSkillRed]: isScore0,
          })}
        />
      </div>
      <Dialog
        open={open}
        maxWidth="sm"
        fullScreen={fullScreen}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
          {"PTE Official score guide"}
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            style={{ color: theme.customColors.blackWhite, fontSize: "1.5em" }}
          >
            {"Enabling skill - " + skill}
          </DialogContentText>
          <DialogContentText style={{ color: theme.customColors.blackWhite }}>
            {details.question}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {details.definition}
          </DialogContentText>
          <DialogContentText style={{ color: theme.customColors.blackWhite }}>
            How are marks awarded for this skill?
          </DialogContentText>
          {details.marks.map((mark, id) => {
            return (
              <DialogContentText
                key={id}
                className={clsx({ [classes.skillScore]: id === score })}
                id="alert-dialog-description"
              >
                {mark}
              </DialogContentText>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  infoSkill: {
    cursor: "pointer",
    fontSize: "1.2rem",
    color: theme.customColors.blue,
  },
  infoSkillRed: {
    color: theme.customColors.red,
  },
  skillScore: {
    fontWeight: "bold",
    color: theme.customColors.blackWhite,
  },
  iconContainer: {
    display: "flex",
    marginLeft: "0.5em",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "8px !important",
    },
  },
  dialogTitle: {
    fontWeight: "bold !important",
    [theme.breakpoints.down("xs")]: {
      padding: "8px !important",
    },
  },
}));

export default AlertSkillOfficial;
