import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Highlighter from "react-highlight-words";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import TaskBox from "./components/TaskBox";
import McqCell from "./components/McqCell";

import { isAuthenticated } from "../../helpers/userCheck";
import { setPracticeScore } from "../../actions/questionActions";

// Reading single answer exercise
function ExerciseReadingSingle({ question, didSubmit, ...props }) {
  const classes = useStyles();
  const { topic, uid, highlight = [], multichoice, correctIndex } = question;

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // Save score on submit
  useEffect(() => {
    if (didSubmit) {
      // Final score
      const score = selectedAnswer == correctIndex ? "1/1" : "0/1";

      props.setPracticeScore({
        uid,
        score,
        successful: selectedAnswer == correctIndex,
        isAuthenticated: isAuthenticated(props.user),
      });
    }
  }, [didSubmit]);

  return (
    <Grid container direction="row" className={classes.screen}>
      <Grid item xs={12} md={6} className={classes.column}>
        <Highlighter
          highlightClassName={classes.highlight}
          className={classes.text}
          searchWords={didSubmit ? highlight : []}
          autoEscape={true}
          textToHighlight={topic}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.column}>
        <TaskBox uid={uid} />
        <Typography className={classes.question}>
          {question.question}
        </Typography>
        {multichoice.map((suggestion, i) => {
          return (
            <McqCell
              key={i}
              index={i}
              didSubmit={didSubmit}
              suggestion={suggestion}
              correctIndex={correctIndex}
              selectedAnswer={selectedAnswer}
              onSelectCell={(value) =>
                setSelectedAnswer(selectedAnswer == value ? null : value)
              }
            />
          );
        })}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    width: "100%",
    display: "flex",
    marginTop: "1.5rem",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2.5rem",
    },
  },
  column: {
    padding: "0 1rem",
    marginTop: "1rem",
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "65vh",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
      maxHeight: "none",
      padding: "0 0.5rem",
    },
  },
  question: {
    margin: "2rem 0",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0",
    },
  },
  text: {
    whiteSpace: "pre-line",
    lineHeight: "1.5rem",
    fontSize: "1rem",
  },
  highlight: {
    background: theme.customColors.answerRight,
    color: theme.customColors.blackWhite,
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setPracticeScore })(
  ExerciseReadingSingle
);
