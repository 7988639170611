import {
  SET_PRACTICE,
  SET_DID_SUBMIT,
  CLEAR_PRACTICE,
  SET_SHOW_ANSWER,
  SET_REVIEW_MODE,
  SET_PRACTICE_MOCK,
  SET_MOCK_TEST_MODE,
  SET_PRACTICE_SCORE,
  SET_MOCK_TEST_IS_UP,
  CLEAR_MOCK_TEST_IS_UP,
  SET_PRACTICE_SCORE_DATA,
  CLEAR_FOR_NEXT_QUESTION,
  GET_PREDICTED_QUESTIONS,
  SET_MOCK_TEST_MODE_ONLY,
} from "../helpers/types";

const initialState = {
  uids: [],
  type: "",
  score: null,
  scoreData: null,
  sessionId: null,
  isTimeUp: false,
  didSubmit: false,
  mockTestIndex: 0,
  isMockTest: false,
  showAnswer: false,
  predictionUids: [],
  isReviewMode: false,
  reviewQuestion: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PRACTICE:
      return {
        ...state,
        isMockTest: false,
        isReviewMode: false,
        uids: action.payload,
      };

    case SET_PRACTICE_MOCK:
      return {
        ...state,
        isMockTest: false,
        isReviewMode: false,
        uids: action.payload.questionList,
        type: action.payload.mockTestCategory,
      };

    case GET_PREDICTED_QUESTIONS:
      return {
        ...state,
        predictionUids: action.payload,
      };

    case SET_DID_SUBMIT:
      return {
        ...state,
        didSubmit: action.payload,
      };

    case SET_PRACTICE_SCORE:
      return {
        ...state,
        score: action.payload,
      };

    case SET_PRACTICE_SCORE_DATA:
      return {
        ...state,
        scoreData: action.payload,
      };

    case SET_SHOW_ANSWER:
      return {
        ...state,
        showAnswer: action.payload,
      };

    case SET_MOCK_TEST_MODE:
      return {
        ...state,
        isMockTest: true,
        sessionId: action.payload.sessionId,
        mockTestIndex: action.payload.mockTestIndex,
      };

    case SET_MOCK_TEST_MODE_ONLY:
      return {
        ...state,
        isMockTest: true,
      };

    case SET_MOCK_TEST_IS_UP:
      return {
        ...state,
        isTimeUp: true,
      };

    case CLEAR_MOCK_TEST_IS_UP:
      return {
        ...state,
        isTimeUp: false,
      };

    case SET_REVIEW_MODE:
      return {
        ...state,
        isReviewMode: true,
        reviewQuestion: action.payload.question,
        sessionId: action.payload.sessionId,
      };

    case CLEAR_FOR_NEXT_QUESTION:
      return {
        ...state,
        score: null,
        scoreData: null,
        didSubmit: false,
        showAnswer: false,
      };

    case CLEAR_PRACTICE:
      return {
        ...state,
        type: "",
        uids: [],
        score: null,
        scoreData: null,
        sessionId: null,
        isTimeUp: false,
        didSubmit: false,
        mockTestIndex: 0,
        showAnswer: false,
        isMockTest: false,
        isReviewMode: false,
        reviewQuestion: null,
      };

    default:
      return state;
  }
}
