import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  setClickedNext,
  setToggleRecording,
  setTimeLeftAnswering,
  setDidFinishRecording,
} from "../../../actions/recorderActions";
import { useInterval } from "../../../helpers/customHook";

// Timer in Speaking while recording
function RecorderAnsweringTimer({ answeringTime, ...props }) {
  const { didSubmit } = props.practice;
  const { isRecording, timeLeftAnswering, didFinishRecording } = props.recorder;
  const [isRunning, setIsRunning] = useState(false);

  // Save answering time for this question
  useEffect(() => {
    props.setTimeLeftAnswering(answeringTime);
  }, [didFinishRecording]);

  // Countdown
  useInterval(
    () => {
      props.setTimeLeftAnswering(timeLeftAnswering - 1);
    },
    isRunning ? 1000 : null
  );

  // Start/stop timer if needed
  useEffect(() => {
    setIsRunning(isRecording && !didFinishRecording);
  }, [isRecording, didFinishRecording]);

  // Stop recording when countdown at 0
  useEffect(() => {
    if (timeLeftAnswering <= 0 || didSubmit) {
      props.setDidFinishRecording(true);
      props.setToggleRecording(false);
      props.setClickedNext(true);
    }
  }, [timeLeftAnswering, didSubmit]);

  return null;
}
const mapStateToProps = (state) => ({
  recorder: state.recorder,
  practice: state.practice,
});

export default connect(mapStateToProps, {
  setClickedNext,
  setToggleRecording,
  setTimeLeftAnswering,
  setDidFinishRecording,
})(React.memo(RecorderAnsweringTimer));
