import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";

import OnBoardingLargeSteps from "../onBoarding/OnBoardingLargeSteps";
import OnBoardingLargeDescription from "../onBoarding/OnBoardingLargeDescription";

import { updateUserArray } from "../../actions/userActions";
import { getOnBoardingLarge } from "../../helpers/getOnBoarding";
import { doesUserHave, isAuthenticated } from "../../helpers/userCheck";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Onboarding explanation (large image version)
function OnBoardingLarge(props) {
  const classes = useStyles();
  const { user } = props;
  const { pathname } = props.location;

  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Get onboarding data for this screen
  useEffect(() => {
    if (isAuthenticated(user)) {
      setConfig(getOnBoardingLarge(pathname));
    }
  }, [pathname, user]);

  useEffect(() => {
    // If user does not have => show onboarding
    if (config && !doesUserHave(user, config.onboardingKey)) {
      setOpen(true);
    }
  }, [config]);

  // Select a step
  function selectIndex(index) {
    setCurrentIndex(index);
  }

  // Show next step or finalise
  function increaseIndex() {
    if (currentIndex === config.steps.length - 1) return finaliseAndClose();
    setCurrentIndex(currentIndex + 1);
  }

  // Close and save on last index
  function finaliseAndClose() {
    setOpen(false);
    setConfig(null);
    setCurrentIndex(0);
    props.updateUserArray({ stepsDone: config.onboardingKey });
  }

  // Prevent empty rendering
  if (!config) return null;

  return (
    <Dialog
      classes={{ root: classes.container }}
      TransitionComponent={Transition}
      open={open}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.componentContainer}>
        <OnBoardingLargeSteps
          currentIndex={currentIndex}
          increaseIndex={increaseIndex}
          selectIndex={(index) => selectIndex(index)}
          config={config}
        />
        <OnBoardingLargeDescription
          currentIndex={currentIndex}
          config={config}
        />
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
  },
  componentContainer: {
    display: "flex",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { updateUserArray })(
  withRouter(OnBoardingLarge)
);
