import { SET_UPSELL, CLEAR_UPSELL } from "../helpers/types";

const initialState = {
  upsellId: "",
  upsellQuantity: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_UPSELL:
      return {
        ...state,
        upsellId: action.payload.upsellId,
        upsellQuantity: action.payload.upsellQuantity,
      };

    case CLEAR_UPSELL:
      return {
        ...state,
        upsellId: "",
        upsellQuantity: 0,
      };

    default:
      return state;
  }
}
