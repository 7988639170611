import React, { useState } from "react";

import InfoIcon from "@material-ui/icons/Info";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import { makeStyles } from "@material-ui/core/styles";
import { isSafari } from "../../helpers/getBrowserData";

// Modal alert to show permission is denied
function AlertSilenceDetected() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <div>
      <InfoIcon className={classes.helpButton} onClick={() => setOpen(true)} />
      <Dialog
        maxWidth={"md"}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Silence detection feature
        </DialogTitle>
        <DialogContent>
          {/* Red notes */}
          {isSafari() && (
            <div
              style={{
                padding: "0.5rem",
                marginBottom: "2rem",
                borderRadius: "5px",
                background: "#fff1f1",
                border: "1px solid #DA3025",
              }}
            >
              <DialogContentText style={{ fontWeight: "bold", color: "black" }}>
                Warning for Safari users
              </DialogContentText>
              <DialogContentText style={{ color: "black" }}>
                Make sure your microphone is not activated in two different tabs
                at the same time. This will affect the silence detection
                feature. Close the other tab or turn off the silence detection
                feature for a better experience.
              </DialogContentText>
            </div>
          )}

          <DialogContentText id="alert-dialog-description">
            Just like in the PTE exam, our recording feature is designed to
            automatically stop after a period of 3 seconds of silence. This
            ensures a more authentic and exam-like experience, helping you
            practice and prepare effectively.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            <b>
              You can enable or disable the silence detection feature in your{" "}
              <a href="/profile?openSettings=true">Profile</a> page.
            </b>
          </DialogContentText>

          <a href="/profile?openSettings=true">
            <img
              width="484"
              height="365"
              alt={`PTE silence detection`}
              style={{ maxWidth: "100%", height: "auto" }}
              src={`https://pineapplestudio.com.au/pte_success_v2/images/layout/silence_guide_1.webp`}
            />
          </a>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            href="/profile?openSettings=true"
          >
            Open Settings
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  helpButton: {
    cursor: "pointer",
    fontSize: "1.2rem",
    marginLeft: "0.3rem",
    color: theme.customColors.red,
  },
}));

export default AlertSilenceDetected;
